import checkoutSession from "@src/api/payment/checkoutSession";
import AddCartButton from "@src/components/elements/AddCartButton";
import Alert from "@src/components/elements/Alert";
import Button from "@src/components/elements/input/Button";
import { useAppSelector } from "@src/state/hooks";
import usePopupModal from "@src/state/modal/usePopupModal";
import { Product } from "@src/utils/product";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

type Props = {
  product: Product;
  key: string;
};

const MarketplaceShopAllItem = ({ product }: Props) => {
  const { t } = useTranslation();
  const popupModal = usePopupModal();
  const { user: userData } = useAppSelector(state => state.user);

  const checkoutSessionMutation = useMutation({
    mutationKey: ["payment-checkout-session"],
    mutationFn: checkoutSession,
    onSuccess: ({ data }) => {
      const checkout_link = data.checkout_link;
      setTimeout(() => window.location.assign(checkout_link), 200);
    },
    onError: () => {
      popupModal.open(
        <Alert
          buttons={Alert.ButtonVariants.OK}
          onClose={popupModal.close}
          message="Try again in a few minutes."
          title="Checkout"
        />,
      );
    },
  });

  return (
    <div className="rounded-xl border border-gray-300 bg-white px-4 py-3">
      <NavLink to={"/marketplace/" + product.id}>
        <div className="relative w-full pb-[100%]">
          <img
            src="https://via.placeholder.com/150"
            alt="Product"
            className=" absolute size-full rounded-t-xl object-cover"
          />
        </div>
        <span className="text-xs uppercase text-gray-400">{product.brand}</span>
        <p className="block  truncate text-base capitalize text-black">{product.title}</p>
        <div className="mb-2 flex items-center">
          <p className="cursor-auto text-lg font-semibold text-black">
            {(parseFloat(`${product.price_gross}`) / 100).toFixed(2)} €
          </p>
          <del>
            <p className="ml-2 cursor-auto pb-1 text-sm text-gray-600">
              {(parseFloat(`${product.price_net}`) / 100).toFixed(2)} €
            </p>
          </del>
        </div>
        <div className="flex justify-between gap-2">
          <Button
            onClick={() =>
              checkoutSessionMutation.mutate({
                data: {
                  product_id: product.id,
                  quantity: 2,
                },
              })
            }
            size={Button.Sizes.Small}
            variant={Button.Variants.Transparent}
          >
            {t("main.shop.buttons.buy")}
          </Button>

          {userData && <AddCartButton me={userData} prod={product} />}
        </div>
      </NavLink>
    </div>
  );
};

export default MarketplaceShopAllItem;
