import { ArrowLeftIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { ArrowDownOnSquareIcon, PaperAirplaneIcon, PhotoIcon } from "@heroicons/react/24/solid";
import chat from "@src/api/chats/chat";
import create, { CreateRequest } from "@src/api/chats/messages/create";
import upload from "@src/api/chats/upload";
import LegacyLinkPreview from "@src/components/elements/LegacyLinkPreview";
import UserWidget from "@src/components/elements/UserWidget";
import Input from "@src/components/elements/input/Input";
import ChatDropdown from "@src/pages/messenger/ChatDropdown";
import EditModal from "@src/pages/messenger/EditGroupModal";
import MessagesContainer from "@src/pages/messenger/MessagesContainer";
import { useAppSelector } from "@src/state/hooks";
import getName from "@src/utils/getName";
import isMobile from "@src/utils/isMobile";
import { MediaType } from "@src/utils/types/MediaType";
import useDebounce from "@src/utils/useDebounce";
import { User } from "@src/utils/user";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import EmojiPicker, { EmojiClickData } from "emoji-picker-react";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { useLocation, useNavigate } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";

type Props = {
  chatId: string;
  exitChat: () => void;
  switchTab: (tab: "chats" | "archive" | "group" | "groupinfo" | "userinfo") => void;
  markedChats: Array<string>;
  forwardMessage: (
    text: string,
    link: string | undefined,
    media: Array<MediaType> | undefined,
  ) => void;
  searchClick?: {
    getFunction: ((visible: boolean) => void) | undefined;
    setFunction: Dispatch<SetStateAction<((visible: boolean) => void) | undefined>>;
  };
};
const ChatContainer = ({
  chatId,
  exitChat,
  switchTab,
  markedChats,
  forwardMessage,
  searchClick,
}: Props) => {
  const { user } = useAppSelector(state => state.user);
  const { t } = useTranslation();
  const [text, setText] = useState("");
  const [link, setLink] = useState<string | undefined>();
  const [linkPreviewId, setLinkPreviewId] = useState<string | undefined>();
  const queryClient = useQueryClient();
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const [showSearch, setShowSearch] = useState(false);
  const [emojiOpen, setEmojiOpen] = useState(false);
  const [uploadingCount, setUploadingCount] = useState(0);
  const [media, setMedia] = useState<Array<MediaType>>([]);
  const [editModal, setEditModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const links = urlRegex.exec(text);
    if (links?.[0]) {
      setLink(links[0]);
      setText(value => value.replace(links[0], ""));
    }
    if (searchClick?.setFunction && !searchClick?.getFunction) {
      searchClick.setFunction((visible: boolean) => {
        return setShowSearch(() => {
          if (visible) setSearchQuery("");
          return visible;
        });
      });
    }
  }, [text, link, searchClick]);

  const chatResult = useQuery({
    queryKey: ["chat", chatId],
    queryFn: () => chat({ id: chatId }),
    enabled: !!user,
  });

  const uploadMutation = useMutation({
    mutationKey: ["upload"],
    mutationFn: async (file: File) => {
      const data = await upload({ file });
      setUploadingCount(count => count - 1);
      return data;
    },
    onSuccess: ({ data }) => {
      setMedia(media => {
        return [...media, data];
      });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const createMutation = useMutation({
    mutationKey: ["chats", "messages", "create"],
    mutationFn: async (request: CreateRequest) => {
      const data = await create({
        ...request,
        data: {
          link_preview_id: request.data.link_preview_id,
          text: request.data.text,
          media: request.data.media,
        },
      });
      for (const markedChat of markedChats) {
        if (markedChat === chatId) continue;
        await create({
          ...request,
          id: markedChat,
          data: {
            link: request.data.link,
            text: request.data.text,
          },
        });
        queryClient.invalidateQueries({ queryKey: ["chats", "messages", markedChat] });
      }
      return data;
    },
    onSuccess: () => {
      setText("");
      setLink(undefined);
      setMedia([]);
      queryClient.invalidateQueries({
        queryKey: ["chats", "messages", chatId],
      });
      queryClient.invalidateQueries({
        queryKey: ["chats"],
      });
      queryClient.invalidateQueries({
        queryKey: ["chats", "messages"],
      });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const handleEmojiSelect = (emoji: EmojiClickData) => {
    setText(data => data + String.fromCodePoint(parseInt(emoji.unified, 16)));
  };

  if (!chatResult.isSuccess) return <></>;
  if (!user) return <></>;

  const getChatPartner = (members: Array<{ user: User; archived: boolean }>, self: User) => {
    for (const member of members) {
      if (self.id !== member.user.id) {
        return member;
      }
    }
    return members[0];
  };

  const getChatMeMember = (
    members: Array<{ user: User; archived: boolean; admin: boolean }>,
    self: User,
  ) => {
    for (const member of members) {
      if (self.id === member.user.id) {
        return member;
      }
    }
    return members[0];
  };

  const partner = getChatPartner(chatResult.data.members, user);
  const meMember = getChatMeMember(chatResult.data.members, user);
  const fileInputRef = React.createRef<HTMLInputElement>();
  if (!partner) return <></>;

  return (
    <div
      className={`no-scrollbar relative flex flex-col overflow-hidden border-gray-300 bg-white md:rounded-xl md:border ${isMobile ? "h-[calc(100dvh-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))]" : "lg:h-[calc(100dvh-78px)]"}`}
    >
      <div className="grid grid-flow-row gap-3 border-b-2 border-gray-300 px-2 pb-2 pt-3 text-sm">
        <div className="flex flex-row items-start gap-2">
          <button className="lg:hidden" onClick={exitChat}>
            <ArrowLeftIcon className="size-8 rounded-full hover:bg-gray-300" />
          </button>
          <button
            className="flex flex-col"
            onClick={() => {
              switchTab(chatResult.data.group ? "groupinfo" : "userinfo");
            }}
          >
            <div className="flex flex-row gap-2">
              <div className="pointer-events-none relative size-[50px] shrink-0 rounded-full border border-gray-300 bg-white">
                {chatResult.data.group ? (
                  <>
                    {chatResult.data.image ? (
                      <img
                        src={`${chatResult.data.image.data_url}`}
                        className="rounded-full"
                        alt={chatResult.data.image.file_name}
                      />
                    ) : (
                      <img
                        src="/images/placeholder/club.png"
                        className="rounded-full"
                        alt="club.pnng"
                      />
                    )}
                  </>
                ) : (
                  <UserWidget user={partner.user} follow={false} online={true} />
                )}
              </div>
              <div className="flex flex-col gap-2">
                <div className="line-clamp-1 break-all pr-6 text-sm font-bold text-gray-600">
                  {chatResult.data.group ? chatResult.data.name : getName(partner.user)}
                </div>
                {chatResult.data.group && (
                  <div className="flex w-full items-center -space-x-2">
                    {chatResult.data.members.slice(0, 5).map(user => (
                      <img
                        key={user.user.id}
                        src={
                          (user.user.avatar !== "" && user.user.avatar) ||
                          `/images/placeholder/${user.user.type}.png`
                        }
                        alt=""
                        className="size-5 rounded-full border"
                      />
                    ))}
                    {chatResult.data.members.length > 5 && (
                      <div className="-m-1 mt-1 flex size-5 items-center justify-center rounded-full border bg-white">
                        <span className="text-xssm text-gray-600">
                          +{chatResult.data.members.length - 5}
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </button>
          <div className="ml-auto">
            <ChatDropdown
              openEditModal={() => setEditModal(true)}
              chatId={chatId}
              isAdmin={meMember.admin}
              archived={meMember.archived}
              userId={partner.user.id}
              isGroup={chatResult.data.group}
              exitChat={exitChat}
              profileLink={() => {
                if (chatResult.data.group) {
                  switchTab("groupinfo");
                } else {
                  navigate("/profile/" + chatResult.data.id, {
                    state: {
                      backlink: location.state?.backlink ?? location.pathname,
                    },
                  });
                }
              }}
              toggleSearch={() =>
                setShowSearch(value => {
                  if (value) setSearchQuery("");
                  return !value;
                })
              }
            />
          </div>
        </div>
        <EditModal
          chatId={chatId}
          name={chatResult.data.name}
          avatar={chatResult.data.image}
          members={chatResult.data.members.map(item => item.user)}
          visible={editModal}
          closeModal={() => setEditModal(false)}
        />
        {showSearch && (
          <div>
            <Input
              name="search"
              placeholder={t("pages.messenger.search")}
              onChange={e => setSearchQuery(e)}
            />
          </div>
        )}
      </div>
      {
        <MessagesContainer
          isGroup={chatResult.data.group}
          chatId={chatId}
          me={user}
          query={debouncedSearchQuery}
          onForward={message => forwardMessage(message.text, message.link, message.media)}
        />
      }

      <div className="flex h-fit w-full flex-col gap-2 rounded-b-xl border-t-2 border-gray-300 bg-white px-2 pt-2">
        <div className="grid max-h-[300px] grid-cols-2 gap-2 overflow-x-auto">
          {media.map(mediaItem => (
            <div key={mediaItem.id} className="relative w-full shrink-0">
              <button
                onClick={() => {
                  setMedia(data => {
                    return data.filter(item => item.id !== mediaItem.id);
                  });
                }}
                className="absolute right-3 top-3 z-10"
              >
                <XMarkIcon className="size-6" />
              </button>
              {mediaItem.type === "image" && (
                <img className="w-full rounded-2xl" src={mediaItem.data_url} alt="" />
              )}
              {mediaItem.type === "video" && (
                <div className="z-0 aspect-square rounded-2xl">
                  <ReactPlayer
                    width="100%"
                    controls={true}
                    height="100%"
                    url={`${mediaItem.data_url}/playlist.m3u8`}
                  />
                </div>
              )}
              {mediaItem.type === "other" && (
                <div className="h-24 w-full rounded-2xl bg-gray-300">
                  <ArrowDownOnSquareIcon className="absolute left-1/2 top-1/2 size-8 -translate-x-1/2 -translate-y-1/2 text-gray-400" />
                  <span className="leading-1 absolute bottom-2 left-1/2 w-4/5 -translate-x-1/2 truncate text-center text-xssm">
                    {mediaItem.file_name}
                  </span>
                </div>
              )}
            </div>
          ))}
          {[...Array(uploadingCount)].map((_, index) => (
            <div key={index} className="animate-pulse rounded-xl bg-gray-400">
              <svg className="mr-3 size-24 animate-spin" viewBox="0 0 24 24"></svg>
            </div>
          ))}
        </div>
        {emojiOpen && <EmojiPicker onEmojiClick={emoji => handleEmojiSelect(emoji)} width="100%" />}
        {link && <LegacyLinkPreview link={link} small onChange={setLinkPreviewId} />}
        <div className="flex flex-row gap-x-2 max-md:pb-2">
          <button
            onClick={() => setEmojiOpen(value => !value)}
            className="mt-auto flex h-[40px] items-center pb-2 max-md:hidden"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              aria-hidden="true"
              className="size-5 cursor-pointer rounded-full text-yellow-600 hover:bg-gray-200"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.182 15.182a4.5 4.5 0 01-6.364 0M21 12a9 9 0 11-18 0 9 9 0 0118 0zM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75zm-.375 0h.008v.015h-.008V9.75zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75zm-.375 0h.008v.015h-.008V9.75z"
              ></path>
            </svg>
          </button>
          <button
            onClick={() => {
              if (fileInputRef.current) fileInputRef.current.click();
            }}
            className="mt-auto flex h-[40px] items-center pb-2"
          >
            <PhotoIcon className="size-8 text-darkblue" />
          </button>
          <TextareaAutosize
            value={text}
            minRows={1}
            maxRows={7}
            maxLength={900}
            onChange={e => setText(e.target.value)}
            className="mb-2 min-h-[30px] w-full rounded-2xl border border-gray-300 bg-white px-3 py-2 pr-9 scrollbar-thin"
            placeholder="Nachricht..."
          />
          <button
            onClick={() => {
              if (!text && !link && !media[0]) return;
              createMutation.mutate({
                id: chatId,
                data: {
                  link_preview_id: linkPreviewId,
                  link,
                  text,
                  media: media.map((m: MediaType) => m.id),
                },
              });
            }}
            className="-ml-10 mr-2 mt-auto flex h-[40px] items-center pb-2"
          >
            <PaperAirplaneIcon className="size-6 rounded-md bg-darkblue p-0.5 text-white" />
          </button>
          <input
            type="file"
            className="ml-auto"
            accept="*"
            onChange={e => {
              if (e.target.files && e.target.files.length + uploadingCount + media.length > 10) {
                alert(t("pages.messenger.maxMedia"));
                e.target.value = "";
                return;
              }
              for (const file of e.target.files || []) {
                setUploadingCount(count => count + 1);
                uploadMutation.mutate(file);
              }
              e.target.value = "";
            }}
            multiple={true}
            hidden
            ref={fileInputRef}
          />
        </div>
      </div>
    </div>
  );
};

export default ChatContainer;
