import { XMarkIcon } from "@heroicons/react/24/outline";
import Button from "@src/components/elements/input/Button";
import useUploadMutation from "@src/utils/useUpload";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTus } from "use-tus";

type Props = {
  media: Array<{
    id: string;
    key: string;
    file_name: string;
    type: string;
    thumbnail: string;
    data_url?: string;
  }>;
  onChange: (media: {
    id: string;
    key: string;
    file_name: string;
    type: string;
    thumbnail: string;
    data_url?: string;
  }) => void;
  addClick?: {
    getFunction: (() => void) | undefined;
    setFunction: Dispatch<SetStateAction<(() => void) | undefined>>;
  };
  onDelete: (id: string) => void;
  tooltip?: string;
};
const PostMedia = ({ onChange, media, onDelete, addClick, tooltip }: Props) => {
  const { t } = useTranslation();
  const [uploadingCount, setUploadingCount] = useState(0);
  const { upload, setUpload, isSuccess, remove } = useTus();

  useEffect(() => {
    if (upload) {
      upload.start();
    }
  }, [upload]);

  useEffect(() => {
    if (isSuccess) {
      remove();
    }
  }, [isSuccess, remove]);

  const uploadMutation = useUploadMutation({
    uploadingCount,
    onChange,
    setUploadingCount,
    setUpload,
    upload,
  });

  const fileInputRef = useRef<HTMLInputElement>(null);
  const addMedia = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  };

  if (addClick?.setFunction && !addClick?.getFunction) {
    addClick.setFunction(() => {
      return addMedia;
    });
  }

  return (
    <>
      <div className="text-sm font-semibold">{t("main.feedTabs.feetCreate.media")}</div>
      {tooltip && <div className="text-sm">{tooltip}</div>}
      <div className="grid grid-cols-4 gap-3 rounded-xl lg:border lg:p-3">
        {media.map(data => (
          <div key={data.id} className="relative">
            <button
              onClick={() => {
                onDelete(data.id);
              }}
              className="absolute right-3 top-3"
            >
              <XMarkIcon className="size-6 rounded-full bg-white opacity-90" />
            </button>
            {data.type === "image" && (
              <img alt="" className="rounded-xl bg-gray-200" src={data.data_url} />
            )}
            {data.type === "video" && (
              <img alt="" className="rounded-xl bg-gray-200" src={data.thumbnail} />
            )}
          </div>
        ))}
        {[...Array(uploadingCount)].map(count => (
          <div key={count} className="rounded-xl bg-gray-200">
            <svg className="mr-3 size-5 animate-spin" viewBox="0 0 24 24"></svg>
          </div>
        ))}
      </div>
      {!addClick && (
        <div className="ml-auto w-fit">
          <Button
            size={Button.Sizes.Small}
            variant={Button.Variants.Transparent}
            onClick={addMedia}
          >
            {t("main.feedTabs.buttons.addMedia")}
          </Button>
        </div>
      )}
      <input
        type="file"
        className="ml-auto"
        accept="image/*,video/*"
        onChange={e => {
          if (e.target.files && e.target.files.length + uploadingCount + media.length > 10) {
            alert("Max 10 Bilder");
            e.target.value = "";
            return;
          }
          for (const file of e.target.files || []) {
            setUploadingCount(count => count + 1);
            uploadMutation.mutate(file);
          }
          e.target.value = "";
        }}
        multiple={true}
        hidden
        ref={fileInputRef}
      />
    </>
  );
};

export default PostMedia;
