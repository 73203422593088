import { ChevronLeftIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import watch, { WatchResponse } from "@src/api/watch/watch";
import Input from "@src/components/elements/input/Input";
import TabBodies from "@src/components/elements/shared/tab/TabBodies";
import TabBody from "@src/components/elements/shared/tab/TabBody";
import TabController from "@src/components/elements/shared/tab/TabController";
import TabHeader from "@src/components/elements/shared/tab/TabHeader";
import TabHeaders from "@src/components/elements/shared/tab/TabHeaders";
import { useInfiniteQueryOnScroll } from "@src/hooks/useInfiniteQueryOnScroll";
import Watch from "@src/pages/watch/Watch";
import { useAppSelector } from "@src/state/hooks";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

type Props = {
  tab?: string;
};

const WatchContainer = ({ tab = "toYou" }: Props) => {
  const { user } = useAppSelector(state => state.user);
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const featured = searchParams.get("featured");
  const [muted, setMuted] = useState(true);
  const [activePost, setActivePost] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState("");
  const queryParams = {
    q: searchQuery,
    featured_media_id: featured,
    user_id: user && tab === "myWatch" ? user.id : undefined,
    following: tab === "following",
  };

  const { result: watchResult, handleScroll } = useInfiniteQueryOnScroll(
    {
      queryKey: ["watch", "posts", searchQuery, tab],
      queryFn: ({ pageParam }) =>
        watch({
          params: {
            ...queryParams,
            page: pageParam,
          },
        }),
      initialPageParam: 0,
      getNextPageParam: lastPage => {
        const nextPage = lastPage.current_page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : null;
      },
    },
    { selector: null, offset: 10000 },
  );

  const processPosts = (posts: WatchResponse[]) => {
    if (!posts) return [];
    let allPosts = posts.flatMap(item => item.data);
    if (featured) {
      const featuredIndex = allPosts.findIndex(post => post.media_id === featured);
      if (featuredIndex > -1) {
        const [featuredPost] = allPosts.splice(featuredIndex, 1);
        allPosts = [featuredPost, ...allPosts];
      }
    }
    return allPosts;
  };

  if (!watchResult.isSuccess) return <></>;

  const container = (
    <div
      className="mx-auto grid h-[calc(100dvh-40px)] w-screen snap-y snap-mandatory grid-cols-1 overflow-y-scroll scrollbar-none md:mt-20 md:h-[calc(100vh-200px)] md:w-[calc((100dvh-300px)/16*9)] lg:mt-0 xl:h-[calc(100vh-180px)]"
      onScroll={e => {
        const currentPost = Math.round(e.currentTarget.scrollTop / e.currentTarget.clientHeight);
        if (currentPost != activePost) {
          setActivePost(currentPost);
          handleScroll(e.nativeEvent);
        }
      }}
    >
      {processPosts(watchResult.data.pages).map((watch, i) => {
        return (
          <Watch
            queryKey={["watch", "posts", searchQuery, tab]}
            key={`watch:-${watch.media_id}`}
            data={watch}
            active={i === activePost}
            preload={activePost + 3 > i && activePost - 3 < i}
            autoplay={true}
            muted={muted}
            setMuted={(state: boolean) => setMuted(state)}
          />
        );
      })}
    </div>
  );

  return (
    <div>
      <TabController defaultTab={tab}>
        <TabHeaders
          headerCSS="max-lg:absolute left-0 max-lg:z-[10] max-lg:bg-opacity-40 max-lg:brightness-125 max-lg:px-4 gap-3"
          tabCSS="max-lg:invert max-lg:grayscale"
        >
          <TabHeader id="back" url="/news/" hideDesktop>
            <ChevronLeftIcon className="size-5 text-gray-600" />
          </TabHeader>
          <TabHeader id="myWatch" url="/watch/mein_watch">
            {t("main.watchTabs.myWatch")}
          </TabHeader>
          <TabHeader id="toYou" url="/watch/für_dich">
            {t("main.watchTabs.toYou")}
          </TabHeader>
          <TabHeader id="create" url="/watch/erstellen" hideMobile>
            {t("main.watchTabs.create.title")}
          </TabHeader>
          <TabHeader id="following" url="/watch/following">
            {t("main.watchTabs.following")}
          </TabHeader>
          <TabHeader id="trending" url="/watch/trending">
            {t("main.watchTabs.trending")}
          </TabHeader>
          <TabHeader id="search" url="/watch/search" hideDesktop>
            <MagnifyingGlassIcon className="flex size-5 items-center text-gray-600" />
          </TabHeader>
        </TabHeaders>
        <div className="pb-3 max-lg:hidden">
          <Input
            name="search-contacts"
            placeholder={t("components.shared.search", {
              tab: t("main.watchTabs." + tab),
            })}
            value={searchQuery}
            onChange={e => setSearchQuery(e)}
            clearable
          />
        </div>
        <TabBodies>
          <TabBody id="myWatch">{container}</TabBody>
          <TabBody id="toYou">{container}</TabBody>
          <TabBody id="following">{container}</TabBody>
          <TabBody id="trending">{container}</TabBody>
        </TabBodies>
      </TabController>
    </div>
  );
};

export default WatchContainer;
