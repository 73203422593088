import { BookmarkIcon, EyeIcon, HeartIcon } from "@heroicons/react/24/outline";
import StarsRating from "@src/components/elements/input/StarsRating";
import { useTranslation } from "react-i18next";

type Props = {
  rating?: number;
  views?: number;
  bookmarks?: number;
  likes?: number;
};

const StatsHeader = ({ rating = 0, bookmarks = 0, likes = 0, views = 0 }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="w-full flex-col justify-between gap-2 text-sm max-xl:px-4 xl:flex-row ">
      <div className="flex flex-col gap-1">
        <div className="inline-flex items-center gap-2 whitespace-nowrap">
          <StarsRating rating={rating} />
          <div className="text-xs text-gray-600">
            {t("main.shop.details.starsRating", { count: rating })}
          </div>
        </div>
        <div className="inline-flex w-full gap-2 text-xs">
          <div className="inline-flex items-center gap-0.5">
            <EyeIcon className="size-3 text-darkblue" />
            <div className="text-gray-500">{views.toString().padStart(6, "0")}</div>
          </div>
          <div className="inline-flex items-center gap-0.5">
            <BookmarkIcon className="size-3 text-darkblue" />
            <div className="text-gray-500">{bookmarks.toString().padStart(6, "0")}</div>
          </div>
          <div className="inline-flex items-center gap-0.5">
            <HeartIcon className="size-3 text-darkred" />
            <div className="text-gray-500">{likes.toString().padStart(6, "0")}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsHeader;
