// src/styles/GlobalStyles.tsx
import { createGlobalStyle } from "styled-components";
import tw, { theme } from "twin.macro";

const CustomStyles = createGlobalStyle({
  body: {
    WebkitTapHighlightColor: theme`colors.purple.500`,
    ...tw`antialiased`,
  },
  ".swiper-button-next::after, .swiper-button-prev::after": {
    color: "gray",
    fontSize: "15px",
  },
  "@media (min-width: 768px)": {
    ".swiper-button-next::after, .swiper-button-prev::after": {
      fontSize: "20px",
    },
  },
});

const GlobalStyles = () => <CustomStyles />;

export default GlobalStyles;
