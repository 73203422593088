import CreatePostComment from "@src/components/elements/posts/comments/CreatePostComment";
import PostCommentSection from "@src/components/elements/posts/comments/PostCommentSection";
import ModalWrapper from "@src/components/elements/shared/ModalWrapper";
import { useAppSelector } from "@src/state/hooks";
import { useTranslation } from "react-i18next";

type Props = {
  onClose: () => void;
  comment_count: number;
  postId: string;
};

const CommentsPopup = ({ onClose, comment_count, postId }: Props) => {
  const { user } = useAppSelector(state => state.user);
  const self = user;
  const { t } = useTranslation();
  return (
    <ModalWrapper
      onCancel={onClose}
      title={`${comment_count} ${t("components.posts.comments")}`}
      open={true}
      hideActions={true}
    >
      <div className={`no-scrollbar h-[85dvh] overflow-y-auto ${self && "pb-[72px]"}`}>
        <PostCommentSection postId={postId} hideCreateComment={true} />
      </div>
      {self && (
        <div className="absolute bottom-0 left-0 w-full bg-white p-4 sm:p-6">
          <CreatePostComment postId={postId} />
        </div>
      )}
    </ModalWrapper>
  );
};

export default CommentsPopup;
