import Button from "@src/components/elements/input/Button";
import Checkbox from "@src/components/elements/input/Checkbox";
import Input from "@src/components/elements/input/Input";
import BackButton from "@src/components/nav/BackButton";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";

const Partner = () => {
  const [privacyCheck, setPrivacyCheck] = useState(false);
  const [email, setEmail] = useState("");
  const [surname, setSurname] = useState("");
  const [lastname, setLastname] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const { t } = useTranslation();

  return (
    <>
      <div className="pl-4 pt-4">
        <BackButton stepback desktopVisible />
      </div>
      <form className="grid grid-flow-row gap-2 text-justify text-xs">
        <img
          src="/images/info/banner-partnerprogramm.jpg"
          className="h-[200px] w-full object-fill"
        />
        <div className="grid grid-flow-row gap-2 pb-4 text-justify text-xs max-xl:px-4">
          <h1 className="text-left text-smbase font-bold">{t("pages.partner.text1")}</h1>
          <div>{t("pages.partner.text2")}</div>
          <div>{t("pages.partner.text3")}</div>
          <h2 className="font-semibold">{t("pages.partner.text4")}</h2>
          <ul className="list-inside list-disc">
            <li>{t("pages.partner.text5")}</li>
            <li>{t("pages.partner.text6")}</li>
            <li>{t("pages.partner.text7")}</li>
            <li>{t("pages.partner.text8")}</li>
            <li>{t("pages.partner.text9")}</li>
          </ul>
          <h2 className="font-semibold">{t("pages.partner.text10")}</h2>
          <div>{t("pages.partner.text11")}</div>

          <Input
            label="Firma"
            type="text"
            name={t("pages.createAccount.label1")}
            value={company}
            onChange={e => {
              setCompany(e);
            }}
            required
          />
          <div className="grid grid-cols-1 gap-2 lg:grid-cols-2">
            <Input
              label="Land"
              type="text"
              name={t("main.navLinks.jobsPublished.country")}
              placeholder={t("pages.partner.placeholder1")}
              value={surname}
              onChange={e => {
                setSurname(e);
              }}
              required
            />
            <Input
              label="Website"
              type="website"
              name="name"
              placeholder="example.com"
              value={lastname}
              onChange={e => {
                setLastname(e);
              }}
              required
            />
            <div className="col-span-2">
              <Input
                label="E-Mail"
                type="text"
                name="email"
                placeholder="E-Mail"
                value={email}
                onChange={e => {
                  setEmail(e);
                }}
                required
              />
            </div>
          </div>
          <div>
            {t("pages.createAccount.text16")}{" "}
            <TextareaAutosize
              minRows={3}
              value={message}
              onChange={e => {
                setMessage(e.target.value);
              }}
              className="block w-full rounded-xl border-0 bg-gray-200 px-4 py-3"
            />
          </div>

          <Checkbox name="agb" required checked={privacyCheck} onChange={e => setPrivacyCheck(e)}>
            {t("pages.createAccount.text17")}{" "}
            <NavLink to="/datenschutz" target="_blank" className="text-darkcyan">
              {t("pages.createAccount.text18")}
            </NavLink>
            {t("pages.createAccount.text19")}
          </Checkbox>
          <Button submit>{t("buttons.send")}</Button>
        </div>
      </form>
    </>
  );
};

export default Partner;
