import MarketplaceCarousels from "@src/pages/marketplace/shop/MarketplaceCarousels";
import { t } from "i18next";
import { NavLink } from "react-router-dom";

const BillsContainer = () => {
  const bills = [
    {
      id: 1,
      billNumber: "INV-001",
      date: "2024-07-17",
      totalAmount: 100,
      products: [
        { id: 1, title: "Product A", price: 50, image: "https://via.placeholder.com/150" },
        { id: 2, title: "Product B", price: 30, image: "https://via.placeholder.com/150" },
        { id: 3, title: "Product C", price: 20, image: "https://via.placeholder.com/150" },
      ],
    },
    {
      id: 2,
      billNumber: "INV-002",
      date: "2024-07-16",
      totalAmount: 150,
      products: [
        { id: 4, title: "Product D", price: 100, image: "https://via.placeholder.com/150" },
        { id: 5, title: "Product E", price: 50, image: "https://via.placeholder.com/150" },
        { id: 2, title: "Product B", price: 30, image: "https://via.placeholder.com/150" },
      ],
    },
    {
      id: 3,
      billNumber: "INV-003",
      date: "2024-07-15",
      totalAmount: 200,
      products: [
        { id: 6, title: "Product F", price: 150, image: "https://via.placeholder.com/150" },
      ],
    },
  ];

  return (
    <div className="mb-2">
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        {bills.map(bill => (
          <div
            key={bill.id}
            className="relative mb-4 rounded-lg border border-gray-300 bg-white p-6"
          >
            <h2 className="mb-1 text-lg font-bold">
              {t("pages.paymentAndOrder.billnumber")}: {bill.billNumber}
            </h2>
            <p className="mb-1 text-sm text-gray-600">
              {t("components.shared.date")}: {bill.date}
            </p>
            <div className="mt-4 border-t border-gray-200 pt-2">
              {bill.products.map(product => (
                <div key={product.id} className="flex items-center py-2">
                  <img
                    src={product.image}
                    alt={product.title}
                    className="size-16 rounded-md object-cover"
                  />
                  <div className="ml-4">
                    <p className="font-semibold">{product.title}</p>
                    <p className="text-gray-600">€{product.price.toFixed(2)}</p>
                  </div>
                </div>
              ))}
            </div>
            <p className="mt-4 text-gray-600">
              {t("main.shop.price.total")}: €{bill.totalAmount.toFixed(2)}
            </p>
            <div className="absolute bottom-0 right-0 mb-3 mr-3">
              <NavLink
                to={"/marketplace/order_detials/" + bill.id}
                className="rounded-md bg-cyan-700 px-4 py-1 text-sm text-white transition duration-300 hover:bg-cyan-600"
              >
                {t("main.profileTabs.club.details")}
              </NavLink>
            </div>
          </div>
        ))}
      </div>
      <MarketplaceCarousels />
    </div>
  );
};

export default BillsContainer;
