import { UserCircleIcon } from "@heroicons/react/24/outline";
import UserWidget from "@src/components/elements/UserWidget";
import { useAppSelector } from "@src/state/hooks";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";

type Props = {
  dark?: boolean;
};

const NavUserElement = ({ dark }: Props) => {
  const { user } = useAppSelector(state => state.user);
  const { t } = useTranslation();
  const location = useLocation();

  if (user) {
    return (
      <NavLink
        to={`/profile/${user ? user.id : ""}`}
        state={{ backlink: location.state?.backlink ?? location.pathname }}
      >
        {({ isActive }) => (
          <div
            className={`relative flex cursor-pointer flex-col items-center py-0 ${isActive && "border-b-2 border-darkblue"}`}
          >
            <div className="size-7 text-white">
              <UserWidget size={28} user={user} showInfoBubble={false} />
            </div>
            <span
              className={`hidden pb-0.5 text-center text-xs font-semibold md:block ${dark ? "text-white/90" : "text-darkblue/60 group-hover:text-darkblue"} ${!dark && isActive && "text-darkblue"}`}
            >
              {t("main.header.links.profileView")}
            </span>
          </div>
        )}
      </NavLink>
    );
  }

  return (
    <NavLink className="group relative flex cursor-pointer flex-col items-center py-0" to="/login">
      <div
        className={`size-7 ${dark ? "text-white/90" : "text-darkblue/60 group-hover:text-darkblue"}`}
      >
        <UserCircleIcon className="heroicon-stroke-w-1.2" />
      </div>
      <span
        className={`hidden text-center text-xs font-semibold md:block ${dark ? "text-white/90" : "text-gray-600/60 group-hover:text-gray-600"}`}
      >
        Login
      </span>
    </NavLink>
  );
};

export default NavUserElement;
