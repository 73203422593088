import { HeartIcon } from "@heroicons/react/24/outline";
import Button from "@src/components/elements/input/Button";
import { formatDateDifference } from "@src/components/elements/PostWidget";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

interface Job {
  company: string;
  state: string;
  created_at: string;
  limitation_date: string;
  title?: string;
  id: string;
  user: {
    avatar?: string;
    header_image?: string;
    type?: string;
    id?: string;
  };
}

interface JobProps {
  job: Job;
}

const TrendingJobItem: React.FC<JobProps> = ({ job }) => {
  const { t } = useTranslation();

  let profilePhoto;
  if (job.user.type === "company" && job.user.avatar === "") {
    profilePhoto = "/images/placeholder/company.png";
  } else if (job.user.type === "club" && job.user.avatar === "") {
    profilePhoto = "/images/placeholder/club.png";
  } else if (job.user.type === "personal" && job.user.avatar === "") {
    profilePhoto = "/images/placeholder/person.png";
  } else {
    profilePhoto = job.user.avatar;
  }

  return (
    <div className="w-full max-w-sm rounded-lg border border-gray-200 bg-white shadow dark:border-gray-700 dark:bg-gray-800">
      <img
        className="max-h-[75px] min-h-[75px] w-full rounded-t-lg object-cover"
        src={job.user.header_image ? job.user.header_image : "/images/placeholder/headerImage.png"}
        alt="Cover Photo"
      />
      <div className="-mt-12 w-full cursor-pointer">
        <div className="flex flex-col items-center p-2">
          <NavLink to={"/profile/" + job.user.id}>
            <img className="mb-3 size-20 rounded-full shadow-lg" src={profilePhoto} alt="Avatar" />
          </NavLink>
          <NavLink to={`/jobs/${job.id}`}>
            <div className="mb-1 h-[50px] w-full overflow-hidden p-2 text-center text-sm font-medium leading-none text-gray-900 dark:text-white">
              {job.title}
            </div>
          </NavLink>
          <span className="rounded-lg bg-gray-200 text-mini text-gray-500 dark:text-gray-400">
            <span className="p-2">
              {t("components.shop.expiry")} - {job.limitation_date}
            </span>
          </span>
        </div>
        <div className="p-3">
          <div className="mb-2 flex flex-col">
            <div className="m-0.5 flex flex-row">
              <img
                src={"/images/icons/building-office-2.svg"}
                className="mr-1 flex size-[12px]"
                alt="icon"
              />
              <span className="truncate text-mini text-gray-700 dark:text-gray-400">
                {job.company}
              </span>
            </div>
            <div className="m-0.5 flex flex-row">
              <img src={"/images/icons/map-pin.svg"} className="mr-1 flex size-[12px]" alt="icon" />
              <span className="text-mini text-gray-700 dark:text-gray-400">{job.state}</span>
            </div>
            <div className="m-0.5 flex flex-row">
              <img src={"/images/icons/home.svg"} className="mr-1 flex size-[12px]" alt="icon" />
              <span className="text-mini text-gray-700 dark:text-gray-400">
                {t("main.jobsTabs.homeOffice")}
              </span>
            </div>
          </div>

          <div className="flex flex-row items-center">
            <span className="text-xs text-gray-500">
              {formatDateDifference(new Date(), new Date(job.created_at))}
            </span>
            <HeartIcon className="ml-auto mt-1 h-5 text-gray-500" />
          </div>
        </div>
        <div className="my-2 flex justify-center">
          <NavLink to={`/jobs/${job.id}`}>
            <Button variant={Button.Variants.Transparent} size={Button.Sizes.Small} fitWidth>
              {t("pages.watch.text59")}
            </Button>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default TrendingJobItem;
