import Panel from "@src/components/box/Panel";
import UserWidget from "@src/components/elements/UserWidget";
import { User } from "@src/utils/user";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

type Props = {
  title: string;
  users?: Array<User>;
  link: string;
  key?: string;
  showBirthday?: boolean;
};

const SideBarUserElement = ({ title, users, link = "", showBirthday }: Props) => {
  const { t } = useTranslation();
  return (
    <Panel title={title}>
      <div className="grid grid-cols-1 gap-2">
        {users?.map(user => (
          <UserWidget
            key={user.id}
            user={user}
            showName={true}
            follow={false}
            sideName={true}
            showBirthday={showBirthday}
          />
        ))}
        <NavLink
          to={link}
          className="mt-3 w-full rounded-full bg-gray-200 py-1 text-center text-xs hover:bg-gray-300"
        >
          {t("buttons.showMore")}
        </NavLink>
      </div>
    </Panel>
  );
};

export default SideBarUserElement;
