import { Capacitor } from "@capacitor/core";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { PropsWithChildren } from "react";

type Props = {
  onClick?: () => void;
  label: string;
  count?: number;
  color?: string;
  textColor?: string;
  hideCount?: boolean;
  small?: boolean;
};

const PostsActionBarItem = (props: PropsWithChildren<Props>) => (
  <div className="group">
    <button
      className={`relative flex h-5 w-fit flex-row items-center justify-start md:h-6 ${!props.small && "md:w-[56px]"}`}
      onClick={() => {
        if (Capacitor.isNativePlatform()) Haptics.impact({ style: ImpactStyle.Light });
        if (props.onClick) props.onClick();
      }}
    >
      <div
        className={`relative size-6 rounded-full p-1 ${props.color} ${props.textColor ?? "text-gray-600"}`}
      >
        <div className="absolute left-1/2 top-1/2 size-5 -translate-x-1/2 -translate-y-1/2">
          {props.children}
        </div>
      </div>
      {!props.hideCount && (
        <div
          className={`ml-1 min-w-[16px] whitespace-nowrap text-center text-sm ${props.textColor}`}
        >
          {props.count ? props.count : 0}
        </div>
      )}
    </button>
  </div>
);

export default PostsActionBarItem;
