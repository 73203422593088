type Props = {
  format: string;
  device?: string;
};

const Preview = ({ format, device = "desktop" }: Props) => {
  const imageTemplates: Record<string, Record<string, string>> = {
    desktop: {
      css: "max-lg:w-screen lg:w-[600px] aspect-auto lg:max-h-[675px] max-lg:max-h-[400px]",
      full: "/images/ads/template_full.png",
      half: "/images/ads/template_half.png",
      carousel: "/images/ads/template_carousel.png",
      long: "/images/ads/template_long.png",
    },
    mobile: {
      css: "lg:max-w-[300px] aspect-auto lg:max-h-[675px] max-lg:max-h-[400px]",
      full: "/images/ads/template_m_full.png",
      half: "/images/ads/template_m_half.png",
      carousel: "/images/ads/template_m_carousel.png",
      long: "/images/ads/template_m_long.png",
    },
  };

  return (
    <div className="relative">
      <img alt="" src={imageTemplates[device][format]} className={imageTemplates[device].css} />
    </div>
  );
};

export default Preview;
