import Button from "@src/components/elements/input/Button";
import Checkbox from "@src/components/elements/input/Checkbox";
import Input from "@src/components/elements/input/Input";
import BackButton from "@src/components/nav/BackButton";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";

const Successor = () => {
  const [privacyCheck, setPrivacyCheck] = useState(false);
  const [offerType, setOfferType] = useState(false);
  const [anonymous, setAnonymous] = useState(false);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [branches, setBranches] = useState("");
  const [employees, setEmployees] = useState("");
  const [lastAnnual, setLastAnnual] = useState("");
  const [firstOffer, setFirstOffer] = useState("");
  const [newsletterCheck, setNewsletterCheck] = useState(false);
  const [email, setEmail] = useState("");
  const [surname, setSurname] = useState("");
  const [lastname, setLastname] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const { t } = useTranslation();

  return (
    <>
      <div className="pl-4 pt-4">
        <BackButton stepback desktopVisible />
      </div>
      <form className="grid grid-flow-row gap-2 text-xs">
        <img
          src="/images/info/firmen_unternehmen_nachfolger_gesucht_tradefoox.jpg"
          className="h-[250px] w-full object-cover"
        />
        <div className="grid grid-flow-row gap-2 px-4 pb-4">
          <h1 className="text-xs font-semibold">{t("pages.successor.text1")}</h1>
          <div>{t("pages.successor.text2")}</div>
          <div>{t("pages.successor.text3")}</div>
          <div>{t("pages.successor.text4")}</div>
          <h2 className="text-xs font-semibold">{t("pages.successor.text5")}</h2>
          <div>{t("pages.successor.text6")}</div>
          <div className="grid grid-cols-1 gap-2 lg:grid-cols-2">
            <Checkbox
              name="offerType"
              required
              checked={offerType}
              onChange={() => setOfferType(true)}
            >
              {t("main.profileViewTabs.editProfile.form.moreInformation.iOffer")}
            </Checkbox>
            <Checkbox
              name="offerType"
              required
              checked={!offerType}
              onChange={() => setOfferType(false)}
            >
              {t("main.profileViewTabs.editProfile.form.moreInformation.search")}
            </Checkbox>
          </div>
          <div className="grid grid-cols-1 gap-2 lg:grid-cols-2">
            <Checkbox
              name="anonymous"
              required
              checked={anonymous}
              onChange={() => setAnonymous(true)}
            >
              {t("pages.successor.text7")}
            </Checkbox>
            <Checkbox
              name="anonymous"
              required
              checked={!anonymous}
              onChange={() => setAnonymous(false)}
            >
              {t("pages.successor.text8")}
            </Checkbox>
          </div>
          <Input
            label="E-Mail"
            type="text"
            name="email"
            value={email}
            onChange={e => {
              setEmail(e);
            }}
            required
          />
          <Input
            label={t("main.navLinks.jobApplicationForm.phone")}
            type="text"
            name="phone"
            value={phone}
            onChange={e => {
              setPhone(e);
            }}
            required
          />
          <Input
            label={t("pages.createAccount.label1")}
            type="text"
            name="company"
            value={company}
            onChange={e => {
              setCompany(e);
            }}
            required
          />
          <div className="grid grid-cols-1 gap-2 lg:grid-cols-2">
            <Input
              label={t("main.profileViewTabs.editProfile.form.firstName")}
              type="text"
              name="surname"
              placeholder="Max"
              value={surname}
              onChange={e => {
                setSurname(e);
              }}
              required
            />
            <Input
              label={t("main.profileViewTabs.editProfile.form.firstName")}
              type="text"
              name="name"
              placeholder="Mustermann"
              value={lastname}
              onChange={e => {
                setLastname(e);
              }}
              required
            />
          </div>
          <div>
            {t("pages.createAccount.text16")}
            <TextareaAutosize
              minRows={3}
              value={message}
              onChange={e => {
                setMessage(e.target.value);
              }}
              className="block w-full rounded-xl border-0 bg-gray-200 px-4 py-3"
              required
            />
          </div>

          <div className="grid grid-cols-1 gap-2 lg:grid-cols-2">
            <Input
              label={t("components.createJob.location")}
              type="text"
              name="city"
              value={city}
              onChange={e => {
                setCity(e);
              }}
            />
            <Input
              label={t("pages.successor.label1")}
              type="text"
              name="state"
              value={state}
              onChange={e => {
                setState(e);
              }}
            />
          </div>
          <div className="grid grid-cols-1 gap-2 lg:grid-cols-2">
            <Input
              label={t("main.profileViewTabs.editProfile.form.moreInformation.industry")}
              type="text"
              name="branches"
              value={branches}
              onChange={e => {
                setBranches(e);
              }}
            />
            <Input
              label={t("pages.successor.label2")}
              type="text"
              name="employees"
              value={employees}
              onChange={e => {
                setEmployees(e);
              }}
            />
          </div>
          <div className="grid grid-cols-1 gap-2 lg:grid-cols-2">
            <Input
              label={t("pages.successor.label3")}
              type="text"
              name="lastAnnual"
              value={lastAnnual}
              onChange={e => {
                setLastAnnual(e);
              }}
            />
            <Input
              label={t("pages.successor.label4")}
              type="text"
              name="firstOffer"
              value={firstOffer}
              onChange={e => {
                setFirstOffer(e);
              }}
            />
          </div>
          <Checkbox name="agb" required checked={privacyCheck} onChange={e => setPrivacyCheck(e)}>
            {t("pages.createAccount.text17")}{" "}
            <NavLink to="/datenschutz" target="_blank" className="text-darkcyan">
              {t("pages.createAccount.text18")}
            </NavLink>{" "}
            {t("pages.createAccount.text19")}
          </Checkbox>
          <Checkbox
            name="newsletter"
            checked={newsletterCheck}
            onChange={e => setNewsletterCheck(e)}
          >
            {t("components.shared.acceptNewsletter")}
          </Checkbox>
          <Button submit>{t("buttons.send")}</Button>
        </div>
      </form>
    </>
  );
};

export default Successor;
