import mentions from "@src/api/users/mentions";
import ExternalLink from "@src/components/elements/shared/ExternalLink";
import LegacyPost from "@src/pages/news/posts/LegacyPost";
import getName from "@src/utils/getName";
import isMobile from "@src/utils/isMobile";
import { useQuery } from "@tanstack/react-query";
import { ReactNode, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";

type Props = {
  text: string;
  legacy?: boolean;
  disableTopMargin?: boolean;
  hashtagType?: "feed" | "watch";
};

const PostText = ({ text, legacy, disableTopMargin, hashtagType }: Props) => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);
  const [largeText, setLargeText] = useState(false);
  const urlRegex = /(https?:\/\/[^\s]+)|#([a-zA-ZäöüÄÖÜß0-9]+)|@([a-zA-Z0-9-]+)/g;
  const body = useRef<any>(null);
  const location = useLocation();

  let match;
  const links: any[] = [];
  while ((match = urlRegex.exec(text)) !== null) {
    links.push(match[0]);
  }

  const [users, setUsers] = useState<string[]>([]);

  const mentionsResult = useQuery({
    queryKey: ["mentions", "users", users],
    queryFn: () => mentions({ params: { ids: users.join(",") } }),
    retry: false,
    enabled: users.length > 0,
  });

  const generateTextWithLinks = () => {
    const content: Array<ReactNode> = [];
    let match;
    const matches = [];
    while ((match = urlRegex.exec(text)) !== null) {
      matches.push({ hashtag: match[0], index: match.index });
    }

    if (matches.length === 0) {
      content.push(text);
      return content;
    }

    let lastIndex = 0;
    for (const match of matches) {
      const prefix = text.slice(lastIndex, match.index);
      if (prefix.length > 0) {
        content.push(<span>{prefix}</span>);
      }
      if (match.hashtag.startsWith("http")) {
        content.push(<ExternalLink to={match.hashtag}>{match.hashtag}</ExternalLink>);
      } else if (match.hashtag.startsWith("@")) {
        if (!users.includes(match.hashtag.replace("@", ""))) {
          setUsers(data => [...data, match.hashtag.replace("@", "")]);
        }

        const mention =
          mentionsResult.isSuccess &&
          mentionsResult.data.data.find(user => user.id === match.hashtag.replace("@", ""));

        if (mention) {
          content.push(
            <NavLink
              to={`/profile/${mention.id}`}
              state={{
                backlink: location.state?.backlink ?? location.pathname,
              }}
              className="text-lightblue"
            >
              @{getName(mention)}
            </NavLink>,
          );
        } else {
          content.push(<span>{match.hashtag}</span>);
        }
      } else {
        content.push(
          <NavLink
            to={`/tags/${match.hashtag.replace("#", "")}${hashtagType ? `/${hashtagType}` : ""}`}
            className="text-lightblue"
          >
            {match.hashtag}
          </NavLink>,
        );
      }
      lastIndex = match.index + match.hashtag.length;
    }

    const suffix = text.slice(lastIndex);
    if (suffix.length > 0) {
      content.push(<span>{suffix}</span>);
    }

    return content;
  };

  const content: Array<ReactNode> = generateTextWithLinks();

  useEffect(() => {
    if (body.current && body.current.clientHeight > 40) setLargeText(true);
  }, [body]);

  return (
    <>
      <div
        aria-hidden
        className={`${!disableTopMargin && "mt-3"}`}
        onClick={() => {
          if (isMobile) setShowMore(value => !value);
        }}
      >
        <div
          className={`overflow-hidden leading-4 ${!showMore && largeText && "line-clamp-2"}`}
          ref={body}
        >
          {legacy ? <LegacyPost content={text} /> : content}
        </div>
      </div>
      {!showMore && largeText && (
        <button className="text-gray-500" onClick={() => setShowMore(value => !value)}>
          {t("buttons.showMore")}
        </button>
      )}
    </>
  );
};

export default PostText;
