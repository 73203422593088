import { useAppSelector } from "@src/state/hooks";
import useLoginModal from "@src/state/modal/useLoginModal";
import { NavLink, useLocation } from "react-router-dom";

type Props = {
  style?: string;
  icon: JSX.Element;
  title: string;
  count?: number;
  href: string;
  key?: string;
  dark?: boolean;
  activeLinks?: string[];
  authRequired?: boolean;
};

const HeaderButton = ({
  style,
  icon,
  title,
  count = 0,
  href,
  dark,
  activeLinks = [],
  authRequired = false,
}: Props) => {
  const { user } = useAppSelector(state => state.user);
  const location = useLocation().pathname;
  const loginModal = useLoginModal();

  const checkAuth = () => {
    if (!user && authRequired) {
      loginModal.open();
      return false;
    }
    return true;
  };

  return (
    <div className={style}>
      <NavLink to={href} onClick={e => checkAuth() || e.preventDefault()}>
        {({ isActive }) => {
          if (activeLinks.length > 0) {
            isActive = activeLinks.some(item => {
              return location.startsWith(item);
            });
          }
          return (
            <div
              className={`group relative flex cursor-pointer flex-col items-center py-0 ${isActive && "border-b-2 border-darkblue"}`}
            >
              <div
                className={`size-7 ${dark ? "text-white" : "text-darkblue/60 group-hover:text-darkblue/100"} ${!dark && isActive && "text-darkblue/100"}`}
              >
                {icon}
              </div>
              {count > 0 && (
                <div
                  className={`absolute flex size-5 -translate-y-2 translate-x-3 items-center justify-center rounded-full bg-red-500 text-white ${count > 99 ? "text-micro font-semibold" : "text-xs"}`}
                >
                  {count > 99 ? "99+" : count}
                </div>
              )}
              <span
                className={`pb-0.5 text-center text-xs font-semibold max-xl:hidden ${dark ? "text-white" : "text-darkblue/60 group-hover:text-darkblue/100"} ${!dark && isActive && "text-darkblue/100"}`}
              >
                {title}
              </span>
            </div>
          );
        }}
      </NavLink>
    </div>
  );
};

export default HeaderButton;
