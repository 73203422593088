import { default as Products } from "@src/mocks/AllProducts.json";
import Product from "@src/pages/marketplace/components/Product";
import MarketplaceCarousels from "@src/pages/marketplace/shop/MarketplaceCarousels";
import {
  inventoryLevelData,
  inventoryLevelOptions,
  revenueGrowthData,
  revenueGrowthOptions,
  salesTrendData,
  topSellingData,
} from "@src/utils/statisticsData";
import { t } from "i18next";
import { Bar, Line } from "react-chartjs-2";
import { Link } from "react-router-dom";

const MyShopContainer = () => {
  return (
    <div className="container mx-auto">
      <div className="mb-4 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
        <div className="rounded-lg border border-blue-300 bg-blue-100 p-4 text-center">
          <h3 className="text-lg font-medium text-blue-700">
            {t("main.marketplace.tabs.totalExpenditure")}
          </h3>
          <p className="mt-2 text-2xl font-semibold text-blue-900">€{"2000"}</p>
        </div>

        <div className="rounded-lg border border-green-300 bg-green-100 p-4 text-center">
          <h3 className="text-lg font-medium text-green-700">
            {t("main.marketplace.tabs.totalOders")}
          </h3>
          <p className="mt-2 text-2xl font-semibold text-green-900">{"3"}</p>
        </div>

        <div className="rounded-lg border border-yellow-300 bg-yellow-100 p-4 text-center">
          <h3 className="text-lg font-medium text-yellow-700 ">
            {t("main.marketplace.tabs.avarageOderValue")}
          </h3>
          <p className="mt-2 text-2xl font-semibold text-yellow-900">€{"200"}</p>
        </div>

        <div className="rounded-lg border border-purple-300 bg-purple-100  p-4 text-center">
          <h3 className="text-lg font-medium text-purple-700">
            {t("main.marketplace.tabs.recentPurchase")}
          </h3>
          <p className="mt-2 text-2xl font-semibold text-purple-900">4</p>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4">
        <div className="rounded-lg border border-gray-300 bg-white p-6">
          <h2 className="mb-4 text-xl font-semibold">{t("main.shop.details.salesTrend")}</h2>
          <Line data={salesTrendData} />
        </div>
        <div className="rounded-lg border border-gray-300 bg-white p-6">
          <h2 className="mb-4 text-xl font-semibold">{t("main.shop.details.inventoryLevel")}</h2>
          <Bar data={inventoryLevelData} options={inventoryLevelOptions} />
        </div>
        <div className="rounded-lg border border-gray-300 bg-white p-6">
          <h2 className="mb-4 text-xl font-semibold">{t("main.shop.details.revenueGrowth")}</h2>
          <Bar data={revenueGrowthData} options={revenueGrowthOptions} />
        </div>
        <div className="rounded-lg border border-gray-300 bg-white p-6">
          <h2 className="mb-4 text-xl font-semibold">{t("main.shop.details.topSellingProduct")}</h2>
          <Bar data={topSellingData} />
        </div>
      </div>

      <div className="my-4 rounded-lg border border-gray-300 bg-white p-6">
        <h2 className="mb-4 text-xl font-semibold">{t("main.shop.details.manageProduct")}</h2>
        <ul className="list-none space-y-2">
          <li>
            <Link to="#" className="text-blue-500 hover:underline">
              {t("main.shop.details.viewOrders")}
            </Link>
          </li>
          <li>
            <Link to="#" className="text-blue-500 hover:underline">
              {t("main.shop.details.manageProduct")}
            </Link>
          </li>
          <li>
            <Link to="#" className="text-blue-500 hover:underline">
              {t("main.shop.details.salesAnalytics")}
            </Link>
          </li>
          <li>
            <Link to="#" className="text-blue-500 hover:underline">
              {t("main.shop.details.storeSettings")}
            </Link>
          </li>
        </ul>
      </div>
      <MarketplaceCarousels />
      <span className="text-xl font-semibold">{t("main.contacts.tabs.trending")}</span>
      <div className="mb-5 grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-5">
        {Products.allProducts.map(product => (
          <div key={product.ID} className="flex justify-center">
            <Product product={product} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyShopContainer;
