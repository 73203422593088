import { ReactNode } from "react";

type Props = {
  key?: string;
  isActive?: boolean;
  stayVisible?: boolean;
  children?: ReactNode;
  onClick?: () => void;
};

const Tab = ({ isActive, children, stayVisible = false, onClick }: Props) => {
  return (
    <div className={`${!stayVisible && !isActive && "hidden"}`} onClick={onClick} aria-hidden>
      {children}
    </div>
  );
};

export default Tab;
