import Button from "@src/components/elements/input/Button";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import TextareaAutosize from "react-textarea-autosize";

type Props = {
  value: string;
  onChange: (text: string) => void;
  videoUrl: string;
};

const WatchTextEdit = ({ value, onChange, videoUrl = "" }: Props) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { t } = useTranslation();
  return (
    <div className="grid h-[300px] grid-cols-[170px,1fr] gap-4">
      <div className="relative w-[170px]">
        <img
          alt=""
          className="pointer-events-none absolute z-20 h-[300px] w-full"
          src="/smartphone-frame-simple.png"
        />
        <div className="size-full rounded-lg">
          <ReactPlayer
            width="100px"
            height="auto"
            controls={false}
            url={`${videoUrl}/playlist.m3u8`}
            playing
            muted
            loop
            className="react_player rounded"
          />
        </div>
      </div>
      <div className="flex grow text-sm">
        <div className="flex h-[300px] w-full flex-col justify-start">
          <div className="h-full">
            <TextareaAutosize
              value={value}
              minRows={6}
              onChange={e => onChange(e.target.value)}
              className="block max-h-[260px] min-h-[260px] w-full rounded-xl border-0 bg-gray-200 px-4 py-3"
              placeholder={t("main.watchTabs.create.watchCreate.placeholder")}
            />
          </div>
          <div className="flex flex-row justify-end gap-2">
            <div>
              <Button
                variant={Button.Variants.Transparent}
                onClick={() => {
                  if (textareaRef.current) {
                    const oldSelectionStart = textareaRef.current.selectionStart;
                    const newText =
                      textareaRef.current.value.slice(0, textareaRef.current.selectionStart) +
                      "@" +
                      textareaRef.current.value.slice(textareaRef.current.selectionStart);
                    textareaRef.current.value = newText;
                    onChange(newText);
                    textareaRef.current.focus();
                    textareaRef.current.setSelectionRange(
                      oldSelectionStart + 1,
                      oldSelectionStart + 1,
                    );
                  }
                }}
              >
                @
              </Button>
            </div>
            <div>
              <Button
                variant={Button.Variants.Transparent}
                onClick={() => {
                  if (textareaRef.current) {
                    const oldSelectionStart = textareaRef.current.selectionStart;
                    const newText =
                      textareaRef.current.value.slice(0, textareaRef.current.selectionStart) +
                      "#" +
                      textareaRef.current.value.slice(textareaRef.current.selectionStart);
                    textareaRef.current.value = newText;
                    onChange(newText);
                    textareaRef.current.focus();
                    textareaRef.current.setSelectionRange(
                      oldSelectionStart + 1,
                      oldSelectionStart + 1,
                    );
                  }
                }}
              >
                #
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WatchTextEdit;
