import {
  ArrowUpOnSquareIcon,
  ArrowUturnRightIcon,
  ChatBubbleOvalLeftIcon,
  EllipsisHorizontalIcon,
  FlagIcon,
  HeartIcon,
  MinusCircleIcon,
  PencilIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";
import {
  HeartIcon as HeartIconSolid,
  MinusCircleIcon as MinusCircleIconSolid,
} from "@heroicons/react/24/solid";
import likeAssignment from "@src/api/assignments/like";
import unlikeAssignment from "@src/api/assignments/unlike";
import createChat from "@src/api/chats/create";
import likeProduct from "@src/api/marketplace/products/like";
import unlikeProduct from "@src/api/marketplace/products/unlike";
import connect from "@src/api/users/connect";
import likeUser from "@src/api/users/like";
import unblock from "@src/api/users/unblock";
import unlikeUser from "@src/api/users/unlike";
import ChatButton from "@src/components/elements/form/buttons/ChatButton";
import LikeButton from "@src/components/elements/form/buttons/LikeButton";
import ShareButton from "@src/components/elements/form/buttons/ShareButton";
import ViewButton from "@src/components/elements/form/buttons/ViewButton";
import ContextMenu from "@src/components/elements/shared/ContextMenu";
import SharePopup from "@src/components/popup/SharePopup";
import { showBlockUserModal } from "@src/state/blockUserModal/actions";
import { useAppSelector } from "@src/state/hooks";
import useLoginModal from "@src/state/modal/useLoginModal";
import usePopupModal from "@src/state/modal/usePopupModal";
import { showReportModal } from "@src/state/reportModal/actions";
import { MediaType } from "@src/utils/types/MediaType";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

type printButtonType = {
  printUrl?: string;
};

type likeButtonType = {
  id: string;
  type: "assignment" | "product" | "user";
  liked: boolean;
  like_count: number;
};

type chatButtonType = {
  id: string;
  backlink?: string;
};

type viewButtonType = {
  view_count: number;
};

type connectButtonType = {
  isVisible: boolean;
};

type shareButtonType = {
  title?: string;
  body?: string;
  media?: Array<MediaType>;
};

type Props = {
  likeButton: likeButtonType;
  viewButton: viewButtonType;
  chatButton: chatButtonType;
  connectButton?: connectButtonType;
  printButton?: printButtonType;
  shareButton?: shareButtonType;
  editButton?: () => void;
  blocked?: boolean;
  itemId: string;
  itemType: string;
  spread?: boolean;
};

const ButtonsBar = ({
  likeButton,
  viewButton,
  chatButton,
  connectButton,
  shareButton,
  printButton,
  itemId,
  itemType,
  blocked,
  editButton,
  spread,
}: Props) => {
  const { user } = useAppSelector(state => state.user);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginModal = useLoginModal();
  const popupModal = usePopupModal();

  const unlikeMutation = useMutation({
    mutationKey: [likeButton.type, "unlike"],
    mutationFn: () => {
      switch (likeButton.type) {
        case "assignment":
          return unlikeAssignment({ id: likeButton.id });
        case "product":
          return unlikeProduct({ id: likeButton.id });
        case "user":
          return unlikeUser({ id: likeButton.id });
      }
    },
    onSuccess: () => {
      switch (likeButton.type) {
        case "assignment":
          queryClient.invalidateQueries({ queryKey: ["assignment"] });
          break;
        case "product":
          queryClient.invalidateQueries({ queryKey: ["product"] });
          break;
        case "user":
          queryClient.invalidateQueries({ queryKey: ["user"] });
          break;
      }
    },
    onError: () => {
      alert("Failed");
    },
  });

  const likeMutation = useMutation({
    mutationKey: [likeButton.type, "like"],
    mutationFn: () => {
      switch (likeButton.type) {
        case "assignment":
          return likeAssignment({ id: likeButton.id });
        case "product":
          return likeProduct({ id: likeButton.id });
        case "user":
          return likeUser({ id: likeButton.id });
      }
    },
    onSuccess: () => {
      switch (likeButton.type) {
        case "assignment":
          queryClient.invalidateQueries({ queryKey: ["assignment"] });
          break;
        case "product":
          queryClient.invalidateQueries({ queryKey: ["product"] });
          break;
        case "user":
          queryClient.invalidateQueries({ queryKey: ["user"] });
          break;
      }
    },
    onError: () => {
      alert("Failed");
    },
  });

  const createChatMutation = useMutation({
    mutationKey: ["chats", "create"],
    mutationFn: createChat,
    onSuccess: ({ data }) => {
      navigate("/messenger", {
        state: { chatId: data.id, backlink: chatButton.backlink },
      });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const connectMutation = useMutation({
    mutationKey: [`contacts-request-${itemId}`],
    mutationFn: () => connect({ id: itemId }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["contacts-requests", itemId],
      });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const unblockMutation = useMutation({
    mutationKey: [`unblock-${itemId}`],
    mutationFn: () => unblock({ id: itemId }),
    onSuccess: () => {
      ["user"].forEach(key => {
        queryClient.invalidateQueries({ queryKey: [key] });
      });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const checkAuth = () => {
    if (!user) {
      loginModal.open();
      return false;
    }
    return true;
  };

  return (
    <div
      className={`flex flex-wrap items-end gap-2 text-xs max-lg:justify-between ${spread && "justify-between"}`}
    >
      <ChatButton onClick={() => checkAuth() && createChatMutation.mutate({ id: chatButton.id })} />
      <ShareButton content={shareButton} />
      <LikeButton
        liked={likeButton.liked}
        likeCount={likeButton.like_count}
        unlike={() => checkAuth() && unlikeMutation.mutate()}
        like={() => checkAuth() && likeMutation.mutate()}
      />
      <ViewButton viewCount={viewButton.view_count} />
      <div className="relative mr-[18px]">
        <ContextMenu
          icon={<EllipsisHorizontalIcon className="size-5 opacity-100" aria-hidden="true" />}
          items={[
            connectButton?.isVisible && {
              label: t("buttons.connect"),
              onClick: () => checkAuth() && connectMutation.mutate(),
              icon: ArrowUturnRightIcon,
            },
            {
              label: t("main.shop.buttons.contact"),
              onClick: () => checkAuth() && createChatMutation.mutate({ id: chatButton.id }),
              icon: ChatBubbleOvalLeftIcon,
            },
            user?.id === itemId && {
              label: t("buttons.edit"),
              onClick: () => !!(checkAuth() && editButton) && editButton(),
              icon: PencilIcon,
            },
            {
              label: t("buttons.share"),
              onClick: () =>
                checkAuth() &&
                popupModal.open(
                  <SharePopup onClose={popupModal.close} content={shareButton ?? {}} />,
                ),
              icon: ArrowUpOnSquareIcon,
            },
            {
              label: t("buttons.like"),
              onClick: () =>
                checkAuth() && (likeButton.liked ? unlikeMutation.mutate() : likeMutation.mutate()),
              icon: likeButton.liked ? HeartIconSolid : HeartIcon,
            },
            printButton && {
              label: t("buttons.print"),
              onClick: () => {
                return;
              },
              icon: PrinterIcon,
            },
            {
              label: t("buttons.report"),
              onClick: () => dispatch(showReportModal(itemId, itemType)),
              icon: FlagIcon,
            },
            user?.id !== itemId && {
              label: blocked ? t("buttons.unblock") : t("buttons.block"),
              onClick: () =>
                blocked ? unblockMutation.mutate() : dispatch(showBlockUserModal(itemId)),
              icon: blocked ? MinusCircleIconSolid : MinusCircleIcon,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default ButtonsBar;
