export const assignments = [
  {
    id: "0",
    creator: {
      id: "0",
      username: "Ada",
      person: { firstname: "Ada", lastname: "Lovelace" },
      follower_count: 0,
      type: "person",
    },
    title: "Kfz-Mechaniker 1111",
    city: "Chemnitz",
    price: 800,
    created_at: "Tue Nov 05 2020",
    start_at: "Fri Nov 08 2020",
    showButtons: false,
  },
  {
    id: "1",
    creator: {
      id: "0",
      username: "Ada",
      person: { firstname: "Ada", lastname: "Lovelace" },
      follower_count: 0,
      type: "person",
    },
    title: "Kfz-Mechaniker 2222",
    city: "Chemnitz",
    price: 200,
    created_at: "Tue Nov 05 2021",
    start_at: "Fri Nov 08 2021",
    showButtons: false,
  },
  {
    id: "2",
    creator: {
      id: "0",
      username: "Ada",
      person: { firstname: "Ada", lastname: "Lovelace" },
      follower_count: 0,
      type: "person",
    },
    title: "Kfz-Mechaniker 3333",
    city: "Chemnitz",
    price: 400,
    created_at: "Tue Nov 05 2022",
    start_at: "Fri Nov 08 2022",
    showButtons: false,
  },
  {
    id: "3",
    creator: {
      id: "0",
      username: "Ada",
      person: { firstname: "Ada", lastname: "Lovelace" },
      follower_count: 0,
      type: "person",
    },
    title: "Kfz-Mechaniker 4444",
    city: "Chemnitz",
    price: 600,
    created_at: "Tue Nov 05 2023",
    start_at: "Fri Nov 08 2023",
    showButtons: false,
  },
];
