import {
  ArrowPathRoundedSquareIcon,
  PauseIcon,
  PlayIcon,
  SpeakerWaveIcon,
  SpeakerXMarkIcon,
} from "@heroicons/react/24/outline";
import {
  ChatBubbleOvalLeftIcon,
  HeartIcon,
  MusicalNoteIcon,
  PlayIcon as PlayIconFull,
  PlusIcon,
} from "@heroicons/react/24/solid";
import like from "@src/api/posts/like";
import unlike from "@src/api/posts/unlike";
import view from "@src/api/posts/view";
import ProfileAvatar from "@src/components/elements/shared/ProfileAvatar";
import TextView from "@src/components/elements/textedit/TextView";
import CommentsPopup from "@src/components/popup/CommentsPopup";
import SharePopup from "@src/components/popup/SharePopup";
import WatchDropdown from "@src/pages/watch/WatchDropdown";
import WatchBookmarkButton from "@src/pages/watch/actions/WatchBookmarkButton";
import { useAppSelector } from "@src/state/hooks";
import useLoginModal from "@src/state/modal/useLoginModal";
import { WatchType } from "@src/utils/types/WatchType";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Hls from "hls.js";
import { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

type Props = {
  data: WatchType;
  active: boolean;
  preload: boolean;
  autoplay?: boolean;
  key?: string;
  muted?: boolean;
  setMuted?: (muted: boolean) => void;
  queryKey?: (string | null)[];
};

const WatchMobileItem = ({
  data,
  active,
  autoplay,
  muted = true,
  setMuted,
  preload,
  queryKey = ["watch", "posts"],
}: Props) => {
  const queryClient = useQueryClient();
  const [viewed, setViewed] = useState(false);

  const navigate = useNavigate();
  const [onCanPlay, setOnCanPlay] = useState(false);
  const [preloadPersist, setPreloadPersist] = useState(preload);
  useEffect(() => {
    if (!preloadPersist && preload) {
      console.log("Preload persist");
      setPreloadPersist(preload);
    }
  }, [preloadPersist, preload]);
  const [playing, setPlaying] = useState(autoplay);
  const preventPause = false;
  const { user: meUser } = useAppSelector(state => state.user);
  const loginModal = useLoginModal();
  const location = useLocation();
  const likeMutation = useMutation({
    mutationKey: [`like-${data.post_id}`],
    mutationFn: async () => {
      const liked = data.liked;

      await queryClient.cancelQueries({ queryKey });
      const previousData = queryClient.getQueryData<{
        pages: Array<{ data: WatchType[] }>;
      }>(queryKey);

      if (previousData) {
        for (const page of previousData.pages) {
          for (const post of page.data) {
            if (post.post_id !== data.post_id) continue;

            if (data.liked) {
              post.like_count -= 1;
            } else {
              post.like_count += 1;
            }
            post.liked = !liked;
          }
        }
        queryClient.setQueryData(queryKey, previousData);
      }

      if (liked) {
        return await unlike({ id: data.post_id });
      }

      return await like({ id: data.post_id });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts", "watch"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  useEffect(() => {
    if (active && !viewed) {
      setViewed(true);
      view({ id: data.post_id });
    }
  }, [active, data.post_id, viewed]);
  const [sharePopupOpen, setSharePopupOpen] = useState(false);
  const [commentsPopupOpen, setCommentsPopupOpen] = useState(false);
  const [sharePopupMobile, setSharePopupMobile] = useState(false);

  const videoRef = useRef<HTMLVideoElement>(null);
  const [likeEffect, setLikeEffect] = useState(false);

  useEffect(() => {
    if (videoRef.current === null) return;
    const video = videoRef.current;
    const src =
      data.media_thumbnail.replace("/thumbnails/thumbnail.jpg", "") + "/manifest/video.m3u8";

    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(src);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        if (active) {
          video.play().catch(e => console.log(e)); // Autoplay based on 'active'
        } else {
          video.pause(); // Pause based on 'active'
        }
      });
    } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
      video.src = src;
      video.addEventListener("loadedmetadata", function () {
        if (active) {
          video.play().catch(e => console.log(e)); // Autoplay based on 'active'
        } else {
          video.pause(); // Pause based on 'active'
        }
      });
    }
  }, [active, data.media_thumbnail, videoRef]);

  const checkAuth = () => {
    if (!meUser) {
      loginModal.open();
      return false;
    }
    return true;
  };

  return (
    <div className="relative h-[calc(calc(100dvh-50px)-env(safe-area-inset-bottom))] w-screen snap-center bg-neutral-900">
      <div className="max-h-full max-w-full cursor-pointer overflow-hidden">
        <video
          poster={data.media_thumbnail}
          ref={videoRef}
          className={`mx-auto h-[calc(calc(100dvh-50px)-env(safe-area-inset-bottom))] ${videoRef.current && videoRef.current?.videoWidth / videoRef.current?.videoHeight < 0.57 && "object-cover"}`}
          loop={true}
          muted={muted}
          playsInline
          preload={"auto"}
          onCanPlayThrough={() => setOnCanPlay(true)}
          onPlaying={() => setPlaying(true)}
          onPlay={() => setPlaying(true)}
          onPause={() => setPlaying(false)}
        />
        {!onCanPlay && (
          <div className="absolute left-0 top-0 size-full">
            <img
              alt=""
              className="mx-auto h-[calc(calc(100dvh-50px)-env(safe-area-inset-bottom))] object-cover"
              src={data.media_thumbnail}
            />
          </div>
        )}
        <div
          className="absolute left-0 top-0 size-full"
          onClick={e => {
            if (!videoRef.current || preventPause) return;
            if (videoRef.current.paused) {
              videoRef.current.play();
            } else {
              videoRef.current.pause();
            }
            e.stopPropagation();
          }}
        ></div>
      </div>
      {sharePopupOpen && (
        <SharePopup
          postId={data.post_id}
          isMobile={sharePopupMobile}
          content={{
            title: "Watch",
            body: new URL(`/watch/${data.post_id}`, window.location.href).toString(),
          }}
          onClose={() => setSharePopupOpen(false)}
        />
      )}
      {commentsPopupOpen && (
        <CommentsPopup
          onClose={() => setCommentsPopupOpen(false)}
          comment_count={data.comment_count}
          postId={data.post_id}
        />
      )}
      <div className="absolute bottom-[20px] z-10 flex w-full flex-row justify-between rounded-xl px-6 pb-4 text-gray-200">
        <button
          className="pointer-events-auto hidden duration-200"
          onClick={() => {
            if (!videoRef.current) return;
            if (videoRef.current.paused) {
              videoRef.current.play();
            } else {
              videoRef.current.pause();
            }
          }}
        >
          {playing ? <PauseIcon className="size-6 " /> : <PlayIcon className="size-6 " />}
        </button>
        <button
          className="pointer-events-auto absolute bottom-0 right-6 duration-200"
          onClick={e => {
            if (setMuted) setMuted(!muted);
            e.stopPropagation();
          }}
        ></button>
      </div>
      {/* Sidebar */}
      <div className="absolute bottom-2 right-3 z-10 w-[50px] rounded-xl py-1 text-xs text-gray-200">
        <div className="flex flex-col items-center gap-3">
          <button
            className="pointer-events-auto flex flex-col items-center"
            onClick={() => checkAuth() && likeMutation.mutate()}
          >
            {!data.liked && (
              <HeartIcon
                className="size-8"
                onClick={() => {
                  setLikeEffect(true);
                }}
                onAnimationEnd={() => {
                  setLikeEffect(false);
                }}
              />
            )}
            {data.liked && (
              <HeartIcon className={`size-8 text-red-600 ${likeEffect && "animate-ping-once"}`} />
            )}
            <div>{data.like_count}</div>
          </button>
          <div className="pointer-events-auto flex flex-col items-center">
            <button
              className="flex flex-col items-center gap-0"
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <ChatBubbleOvalLeftIcon
                className="size-8"
                onClick={() => {
                  setCommentsPopupOpen(!commentsPopupOpen);
                }}
              />
            </button>
            <div>{data.comment_count}</div>
          </div>
          <div className="pointer-events-auto flex flex-col items-center">
            <WatchBookmarkButton postId={data.post_id} data={data} />
            <div>{data.bookmark_count}</div>
          </div>
          <div
            className="pointer-events-auto flex flex-col items-center"
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <PlayIconFull className="size-8" />
            <div>{data.view_count}</div>
          </div>
          <button
            className="pointer-events-auto relative flex cursor-pointer flex-col items-center"
            onClick={e => {
              e.stopPropagation();
              if (checkAuth()) setSharePopupOpen(true);
            }}
          >
            <ArrowPathRoundedSquareIcon className="size-8" />
            <div>0</div>
            <button
              className="absolute left-0 top-0 size-full lg:hidden"
              onClick={() => {
                setSharePopupMobile(true);
              }}
            />
          </button>

          <div className="pointer-events-auto text-black">
            {
              <WatchDropdown
                isRepost={data.is_reposted}
                postId={data.post_id}
                videoUrl={data.media_url}
                authorId={data.author_id}
                bookmarked={data.bookmarked}
              />
            }
          </div>
          <button
            className="pointer-events-auto mr-12 mt-24 flex items-center gap-2.5 rounded-xl border bg-gray-200 px-2.5 py-1 text-sm text-black"
            onClick={() => checkAuth() && navigate("/watch/erstellen")}
          >
            <PlusIcon className="size-4 text-black" /> Create
          </button>
        </div>
      </div>
      <div className="absolute bottom-2 left-0 block w-full">
        <div className="flex w-[calc(100%-70px)] flex-row gap-4 rounded-xl px-2 py-1 text-sm text-white">
          <div className="flex flex-col items-center">
            <NavLink
              to={"/profile/" + data.author_id + "/watch"}
              state={{
                backlink: location.state?.backlink ?? location.pathname,
              }}
              className="pointer-events-auto"
            >
              <div className="pointer-events-none">
                <ProfileAvatar
                  user={{
                    id: data.author_id,
                    name: data.author_name,
                    type: data.author_type,
                    avatar: data.author_avatar,
                  }}
                  nameStyle="hidden"
                />
              </div>
            </NavLink>
            <button
              onClick={e => {
                if (setMuted) setMuted(!muted);
                e.stopPropagation();
              }}
              className="rounded-lg bg-white bg-opacity-30 px-3 text-black max-md:text-white"
            >
              {muted ? (
                <SpeakerXMarkIcon className="size-6" />
              ) : (
                <SpeakerWaveIcon className="size-6" />
              )}
            </button>
          </div>
          <div className="flex w-full flex-col">
            <NavLink to={`/watch/c/${data.author_id}`} className="text-clip hover:font-semibold">
              @{data.author_name}
            </NavLink>
            <div className="text-clip leading-4">
              <TextView value={data.post_text} hashtagType="watch" />
            </div>
            <div className="flex flex-row">
              <MusicalNoteIcon className="size-5 shrink-0" />
              <div className="w-full overflow-hidden">
                <div className="animate-marquee w-full"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WatchMobileItem;
