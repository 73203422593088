import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import Input from "@src/components/elements/input/Input";
import Select from "@src/components/elements/input/Select";
import { useTranslation } from "react-i18next";

type selectType = {
  name: string;
  value: string;
  id?: number;
};

type Props = {
  label?: string;
  values?: Array<selectType>;
  types?: Array<selectType>;
  name: string;
  placeholder?: string;
  onChange: (array: Array<selectType>) => void;
};

const HashMapList = ({
  label,
  onChange,
  name,
  values = [],
  types = [],
  placeholder = "",
}: Props) => {
  /**
   * Remove item
   * @param index
   */
  const removeItem = (index: number) => {
    const newValues: Array<selectType> = JSON.parse(JSON.stringify(values));
    newValues.splice(index, 1);
    onChange(newValues);
  };
  const { t } = useTranslation();

  /**
   * Adds item
   * @param item
   */
  const addItem = (item: selectType) => {
    const newValues: Array<selectType> = JSON.parse(JSON.stringify(values));
    newValues.push(item);
    onChange(newValues);
  };

  /**
   * Changes item key
   * @param k
   * @param index
   */
  const changeItemKey = (k: string, index: number) => {
    const newValues: Array<selectType> = JSON.parse(JSON.stringify(values));
    newValues[index].name = k;
    onChange(newValues);
  };

  /**
   * Changes item value
   * @param v
   * @param index
   */
  const changeItemValue = (v: string, index: number) => {
    const newValues: Array<selectType> = JSON.parse(JSON.stringify(values));
    newValues[index].value = v;
    onChange(newValues);
  };

  return (
    <div className="mt-1">
      {label && <label className="mb-1 block text-sm font-medium text-gray-700">{label}</label>}
      <div className="grid grid-cols-[200px,auto,20px] items-center gap-2 rounded-2xl border border-gray-300 py-2 pl-2 pr-4 shadow-sm sm:text-sm">
        {values.map((item: selectType, index: number) => {
          return (
            <div key={item.id}>
              <Select
                items={types}
                name="type"
                onChange={e => changeItemKey(e.target.value, index)}
                value={item.name}
              />
              <Input placeholder={placeholder} name="" onChange={e => changeItemValue(e, index)} />
              <button
                onClick={() => {
                  removeItem(index);
                }}
              >
                <MinusCircleIcon className="size-6 text-red-600" />
              </button>
            </div>
          );
        })}
        <div className="col-span-2 text-center">
          {t("main.profileViewTabs.editProfile.form.addNewLink")}
        </div>
        <button
          onClick={() => {
            addItem({
              name: types[0].name,
              value: types[0].value,
              id: Date.now(),
            });
          }}
        >
          <PlusCircleIcon className="size-6 text-green-600" />
        </button>
      </div>
      <Input name={name} type="hidden" value={JSON.stringify(values)} />
    </div>
  );
};

export default HashMapList;
