import api, { requestParams, RequestWithParams } from "@src/api/api";
import { ProfileVisitor } from "@src/utils/user";

export type MyVisitorsParams = {
  page?: number;
  q?: string;
};

export type MyVisitorsRequest = RequestWithParams<MyVisitorsParams>;

export type MyVisitorsResponse = {
  data: ProfileVisitor[];
  current_page: number;
  total_pages: number;
};

export default async function myVisitors(request: MyVisitorsRequest): Promise<MyVisitorsResponse> {
  const { data } = await api.get(
    `/api/v1/users/profile/visits${requestParams({ page: 0, ...request.params })}`,
  );
  return data;
}
