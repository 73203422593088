import jobs from "@src/api/jobs/jobs";
import { useInfiniteQueryOnScroll } from "@src/hooks/useInfiniteQueryOnScroll";
import TrendingJobItem from "@src/pages/jobs/components/TrendingJobItem";
import { useAppSelector } from "@src/state/hooks";
import { JobType } from "@src/utils/types/JobType";

type Props = {
  searchQuery?: string;
  tab?: string;
};

const TrendingJobsHomepage = ({ searchQuery, tab }: Props) => {
  const { user } = useAppSelector(state => state.user);
  const queryParams = {
    filter: tab,
    user_id: tab === "my_jobs" ? user?.id : undefined,
    q: searchQuery,
  };

  const { result: jobsResult } = useInfiniteQueryOnScroll({
    queryKey: ["jobs", searchQuery, tab],
    initialPageParam: 0,
    queryFn: ({ pageParam }) => jobs({ params: { ...queryParams, page: pageParam } }),
    getNextPageParam: lastPage => {
      const nextPage = lastPage.current_page + 1;
      return nextPage <= lastPage.total_pages ? nextPage : null;
    },
  });

  if (!jobsResult.isSuccess) return <></>;

  return (
    <div className="mb-10 grid gap-4 md:grid-cols-2 lg:grid-cols-5">
      {jobsResult.data.pages.map(page =>
        page.data.map((job: JobType) => {
          return (
            <div key={job.id}>
              <TrendingJobItem job={job} />
            </div>
          );
        }),
      )}
    </div>
  );
};

export default TrendingJobsHomepage;
