import { Combobox } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { XMarkIcon } from "@heroicons/react/24/outline";
import forYou from "@src/api/contacts/forYou";
import getName from "@src/utils/getName";
import useDebounce from "@src/utils/useDebounce";
import { User } from "@src/utils/user";
import { useQuery } from "@tanstack/react-query";
import _ from "lodash";
import { useState } from "react";

type Props = {
  onSelect: (user: User) => void;
  onChange?: (text: string) => void;
  label?: string;
  userType?: string | Array<string>;
  value?: string;
  placeholder?: string;
};

const UserComboBox = ({
  value,
  onSelect,
  onChange,
  label,
  userType = "",
  placeholder = "",
}: Props) => {
  const [query, setQuery] = useState("");
  const debouncedSearchTerm = useDebounce(query, 500);

  const contactsResult = useQuery({
    queryKey: ["contacts", "for-you", debouncedSearchTerm],
    queryFn: async (): Promise<{ data: User[] }> => {
      let data: { data: User[] } = { data: [] };
      if (Array.isArray(userType)) {
        for (const item of userType) {
          data = _.merge(data, await forYou({ params: { q: debouncedSearchTerm, type: item } }));
        }
      } else {
        data = await forYou({ params: { q: debouncedSearchTerm, type: userType } });
      }
      return data;
    },
    enabled: !!debouncedSearchTerm,
  });

  return (
    <Combobox
      as="div"
      onChange={(user: { id: string; name: string }) => {
        const selection = contactsResult.data?.data.find(item => {
          return item.id === user.id;
        });
        if (selection) onSelect(selection);
      }}
      className="relative"
    >
      <div className="relative mt-2">
        <Combobox.Label className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">
          {label}
        </Combobox.Label>
        <Combobox.Input
          className="w-full rounded-2xl border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={event => {
            if (onChange) onChange(event.target.value);
            setQuery(event.target.value);
          }}
          value={value ?? query}
          tabIndex={-1}
        />
        {!query && (
          <Combobox.Label className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400">
            {placeholder}
          </Combobox.Label>
        )}

        {!!query && (
          <Combobox.Button
            onClick={() => {
              if (onChange) onChange("");
              setQuery("");
            }}
            className="absolute right-0 top-1/2 size-5 -translate-x-1/2 -translate-y-1/2"
          >
            <XMarkIcon />
          </Combobox.Button>
        )}

        {contactsResult.isSuccess && contactsResult.data.data.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-2xl bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
            {contactsResult.isSuccess &&
              contactsResult.data.data.map(contact => (
                <Combobox.Option
                  key={contact.id}
                  value={contact}
                  className="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900"
                >
                  {({ active, selected }) => (
                    <>
                      <div className="flex items-center">
                        <img
                          src={
                            (contact.avatar !== "" && contact.avatar) ||
                            `/images/placeholder/${contact.type}.png`
                          }
                          alt=""
                          className="size-6 shrink-0 rounded-full"
                        />
                        <span className={`ml-3 truncate ${selected && "font-semibold"}`}>
                          {getName(contact)}
                        </span>
                      </div>

                      {selected && (
                        <span
                          className={`absolute inset-y-0 right-0 flex items-center pr-4 ${active ? "text-white" : "text-indigo-600"}`}
                        >
                          <CheckIcon className="size-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};

export default UserComboBox;
