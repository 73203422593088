type Props = {
  type: string;
  h?: string;
  w?: string;
  customStyle?: string;
};

const VerifyBadge = ({ type, h = "h-3", w = "w-3", customStyle }: Props) => {
  if (type === "person")
    return (
      <img
        src="/images/person_verify.svg"
        className={`${h} ${w} ${customStyle}`}
        alt="PersonBadge"
      />
    );
  if (type === "company")
    return (
      <img
        src="/images/company_verify.svg"
        className={`${h} ${w} ${customStyle}`}
        alt="CompanyBadge"
      />
    );
  if (type === "club")
    return (
      <img src="/images/club_verify.svg" className={`${h} ${w} ${customStyle}`} alt="ClubBadge" />
    );

  return <></>;
};

export default VerifyBadge;
