import {
  AtSymbolIcon,
  CalendarDaysIcon,
  HashtagIcon,
  PhotoIcon,
  ScissorsIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { PaperAirplaneIcon } from "@heroicons/react/24/solid";
import create from "@src/api/posts/create";
import Panel from "@src/components/box/Panel";
import Button from "@src/components/elements/input/Button";
import Checkbox from "@src/components/elements/input/Checkbox";
import CheckboxGroup from "@src/components/elements/input/CheckboxGroup";
import Select from "@src/components/elements/input/Select";
import PostTextEdit from "@src/components/elements/posts/form/shared/PostTextEdit";
import PostUserSelect from "@src/components/elements/posts/form/shared/PostUserSelect";
import Schedule from "@src/components/elements/shared/Schedule";
import EmojiButton from "@src/components/elements/shared/buttons/EmojiButton";
import ScheduleButton from "@src/components/elements/shared/buttons/ScheduleButton";
import Tab from "@src/components/menu/Tab";
import PostMediaWatch from "@src/pages/watch/PostMediaWatch";
import EditContainer from "@src/pages/watch/create/EditContainer";
import Preview from "@src/pages/watch/preview/Preview";
import { formatDate } from "@src/utils/formatDate";
import isMobile from "@src/utils/isMobile";
import { MediaType } from "@src/utils/types/MediaType";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import EmojiPicker from "emoji-picker-react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";

type Props = {
  defaultTab?: string;
};

const CreateWatchContainer = ({ defaultTab = "basics" }: Props) => {
  const navigate = useNavigate();

  const onSuccess = () => navigate("/watch/für_dich");
  const onClose = () => navigate("/watch/für_dich");

  const [text, setText] = useState("");
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [adult, setAdult] = useState(false);
  const [tab, setTab] = useState(defaultTab);
  const [scheduleDate, setScheduleDate] = useState<number | undefined>();
  const queryClient = useQueryClient();
  const [emojiOpen, setEmojiOpen] = useState(false);
  const [media, setMedia] = useState<Array<MediaType>>([]);
  const videoRef = useRef<ReactPlayer>(null);
  const { t } = useTranslation();

  const [atClick, setAtClick] = useState<() => void>();
  const [hashtagClick, setHashtagClick] = useState<() => void>();
  const [addMediaClick, setAddMediaClick] = useState<() => void>();
  const [coverTime, setCoverTime] = useState(0);

  const createMutation = useMutation({
    mutationKey: ["post"],
    mutationFn: create,
    onSuccess: () => {
      setText("");
      setAdult(false);
      queryClient.invalidateQueries({ queryKey: ["posts"] });
      onSuccess();
    },
    onError: () => {
      alert(t("components.shared.cantCreatePost"));
    },
  });

  return (
    <>
      {tab !== "edit" && (
        <div className="size-full max-w-5xl lg:relative xl:mx-auto xl:translate-x-[100px]">
          <Tab isActive={tab === "basics"}>
            <Panel noBorder={isMobile}>
              <div className="grid grid-flow-row gap-2">
                <div className="flex items-center gap-5 font-semibold">
                  <button onClick={onClose}>
                    <XMarkIcon className="size-8 rounded-full p-1.5 hover:bg-gray-200" />
                  </button>
                  <PostUserSelect />
                </div>
                {scheduleDate && (
                  <button
                    className="flex flex-row items-center gap-2 text-xs hover:underline"
                    onClick={() => {
                      setTab("schedule");
                    }}
                  >
                    <CalendarDaysIcon className="size-4" />
                    {t("main.watch.create.publishAt")}{" "}
                    {formatDate(new Date(scheduleDate).toISOString())}.
                  </button>
                )}
                <div>
                  <div className="text-sm">{t("main.watch.create.upload")}</div>
                  <div className="text-sm text-gray-400">{t("main.watch.create.account")}</div>
                </div>
                <div className="grid gap-x-4 max-lg:grid-flow-row lg:grid-cols-[1fr,285px]">
                  <div className="max-lg:order-2">
                    {media.length > 0 && (
                      <div>
                        <div>{t("main.watch.create.cover")}</div>
                        <div className="relative h-[80px] w-full overflow-hidden rounded-xl border border-gray-300 pb-12 lg:h-[145px]">
                          <div
                            style={{
                              marginLeft:
                                "calc(" +
                                coverTime +
                                "% - " +
                                (82 * (coverTime / 100)).toFixed(0) +
                                "px)",
                            }}
                            className="video-cover pointer-events-none absolute bg-gray-200"
                          >
                            <ReactPlayer
                              url={`${media[0].data_url}/playlist.m3u8`}
                              height="145px"
                              width="82px"
                              controls={false}
                              muted={true}
                              playing={false}
                              autoPlay={false}
                              ref={videoRef}
                            />
                          </div>
                          <input
                            type="range"
                            className="preview h-[145px] w-[82px] cursor-pointer opacity-0"
                            onWheel={e => e.currentTarget.blur()}
                            onChange={e => {
                              setCoverTime(e.target.valueAsNumber);
                              if (!videoRef.current) return;
                              videoRef.current.seekTo(
                                (videoRef.current?.getDuration() * e.target.valueAsNumber) / 100,
                              );
                            }}
                          />
                        </div>
                      </div>
                    )}
                    <PostMediaWatch
                      onChange={data => setMedia(value => [...value, data])}
                      media={media}
                      addClick={{
                        getFunction: addMediaClick,
                        setFunction: setAddMediaClick,
                      }}
                    />
                    <Select
                      label={t("components.shared.whoCanSeeVideo")}
                      name=""
                      items={[
                        {
                          name: t("components.assignments.publish.public"),
                          value: "public",
                        },
                        {
                          name: t("components.shared.private"),
                          value: "private",
                        },
                        {
                          name: t("components.shared.friends"),
                          value: "friends",
                        },
                      ]}
                    />
                    <div className="mt-3">
                      <CheckboxGroup
                        name="permissions"
                        label={t("main.watch.create.userInteraction")}
                        items={[
                          {
                            label: t("components.posts.answer"),
                            checked: true,
                          },
                          { label: t("main.watch.create.duet"), checked: true },
                        ]}
                        onChange={() => {
                          return null;
                        }}
                      />
                    </div>
                  </div>
                  {/* video preview */}
                  <div className="max-lg:order-1 max-lg:mx-auto max-lg:w-[65vw]">
                    <Preview media={media} />
                    <div className="max-w-[280px]">
                      <Button
                        variant={Button.Variants.Red}
                        onClick={() => {
                          setTab("edit");
                        }}
                      >
                        <ScissorsIcon className="size-4" /> {t("main.watch.create.edit")}
                      </Button>
                    </div>
                  </div>
                </div>
                <PostTextEdit
                  value={text}
                  onChange={setText}
                  small
                  textRef={textareaRef}
                  atClick={{ getFunction: atClick, setFunction: setAtClick }}
                  hashtagClick={{
                    getFunction: hashtagClick,
                    setFunction: setHashtagClick,
                  }}
                />
                <div className="flex flex-row items-baseline justify-between">
                  <Checkbox name="18+" checked={adult} onChange={checked => setAdult(checked)}>
                    {t("main.watch.create.adult")}
                  </Checkbox>
                  <div className="max-sm:hidden">
                    <EmojiButton onClick={() => setEmojiOpen(value => !value)} />
                  </div>
                  <ScheduleButton
                    onClick={() => setTab(tab === "schedule" ? "basics" : "schedule")}
                  />
                  <button onClick={atClick}>
                    <AtSymbolIcon
                      className={`size-6 text-darkblue ${isMobile && "text-darkblue"}`}
                    />
                  </button>
                  <button onClick={hashtagClick}>
                    <HashtagIcon
                      className={`size-6 text-darkblue ${isMobile && "text-darkblue"}`}
                    />
                  </button>
                  <button onClick={addMediaClick}>
                    <PhotoIcon className={`size-6 text-darkblue ${isMobile && "text-darkblue"}`} />
                  </button>
                  <div className="max-sm:hidden"></div>

                  <div className="w-fit">
                    <Button
                      onClick={() =>
                        createMutation.mutate({
                          data: {
                            text,
                            adult,
                            watch: true,
                            media: media.map(m => m.id),
                            set_active_at: scheduleDate,
                          },
                        })
                      }
                    >
                      <PaperAirplaneIcon className="size-5" />
                    </Button>
                  </div>
                </div>
                {emojiOpen && (
                  <EmojiPicker
                    onEmojiClick={emoji =>
                      setText(data => data + String.fromCodePoint(parseInt(emoji.unified, 16)))
                    }
                  />
                )}
              </div>
            </Panel>
          </Tab>

          <Tab isActive={tab === "schedule"}>
            <Schedule
              onClose={() => setTab("basics")}
              onChange={date => setScheduleDate(date)}
              selectedDate={scheduleDate}
            />
          </Tab>
        </div>
      )}
      <Tab isActive={tab === "edit"}>
        <EditContainer
          media={media}
          dark={isMobile}
          onClose={() => setTab("basics")}
          onChange={() => {
            return;
          }}
        />
      </Tab>
    </>
  );
};

export default CreateWatchContainer;
