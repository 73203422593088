import products from "@src/api/marketplace/products/products";
import { useInfiniteQueryOnScroll } from "@src/hooks/useInfiniteQueryOnScroll";
import MarketplaceShopAllItem from "@src/pages/marketplace/shop/MarketplaceShopAllItem";

type Props = {
  searchQuery: string;
  showPlaceholder?: boolean;
};

const MarketplaceShopAllContainer = ({ searchQuery, showPlaceholder }: Props) => {
  const { result: productsResult } = useInfiniteQueryOnScroll({
    queryKey: ["marketplace-shop_all", searchQuery],
    queryFn: ({ pageParam }) => products({ params: { page: pageParam, q: searchQuery } }),
    initialPageParam: 0,
    getNextPageParam: lastPage => {
      const nextPage = lastPage.current_page + 1;
      return nextPage <= lastPage.total_pages ? nextPage : null;
    },
  });

  return (
    <>
      {showPlaceholder && <div className="h-[35px]" />}
      <div className="grid grid-cols-2 gap-1 md:grid-cols-3 xl:grid-cols-5 xl:gap-3">
        {productsResult.data?.pages.map(page =>
          page?.data
            ?.slice(0, 5)
            .map(item => <MarketplaceShopAllItem key={item.id} product={item} />),
        )}
      </div>
    </>
  );
};

export default MarketplaceShopAllContainer;
