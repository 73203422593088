import { useAppSelector } from "@src/state/hooks";
import { ReactNode } from "react";

type Props = {
  value: string;
  options: Array<
    | {
        name: string;
        key: string;
        auth?: boolean;
        mobileHidden?: boolean;
        icon?: ReactNode;
        selectIcon?: ReactNode;
      }
    | undefined
    | false
  >;
  subTab?: boolean;
  slideable?: boolean;
  scrollbar?: string;
  onChange: (value: string) => void;
  centered?: boolean;
};

const TabFilter = ({ value, options, onChange, subTab, slideable, scrollbar, centered }: Props) => {
  const { user } = useAppSelector(state => state.user);
  return (
    <div
      className={`mb-3 ${subTab ? "flex justify-start gap-2 text-xs" : "flex justify-between text-sm"} ${slideable && "gap-8 overflow-x-auto"} ${centered && "justify-center gap-4"} ${scrollbar}`}
    >
      {options.map(option =>
        option && (!option.auth || !!user) ? (
          <button
            key={option.key}
            className={`relative flex items-center gap-1 whitespace-nowrap ${option.key === value ? "border-b-2 text-gray-900" : "text-gray-500"} ${subTab ? "border-gray-400" : "border-darkblue"} ${option?.mobileHidden && "max-lg:hidden"}`}
            onClick={() => onChange(option.key)}
          >
            {option.key === value ? (option.selectIcon ?? option.icon) : option.icon}
            {option.name}
          </button>
        ) : null,
      )}
    </div>
  );
};

export default TabFilter;
