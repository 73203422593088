import {
  ArrowUturnRightIcon,
  BookmarkIcon,
  DocumentIcon,
  FlagIcon,
  MinusCircleIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { BookmarkIcon as BookmarkIconSolid } from "@heroicons/react/24/solid";
import bookmark from "@src/api/jobs/bookmark";
import removeBookmark from "@src/api/jobs/bookmark/remove";
import remove from "@src/api/jobs/remove";
import block from "@src/api/users/block";
import ContextMenu from "@src/components/elements/shared/ContextMenu";
import { useAppSelector } from "@src/state/hooks";
import { showReportModal } from "@src/state/reportModal/actions";
import { JobType } from "@src/utils/types/JobType";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

type Props = {
  job: JobType;
};

const JobsDropDown = ({ job }: Props) => {
  const { user } = useAppSelector(state => state.user);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const removeMutation = useMutation({
    mutationKey: ["delete-job"],
    mutationFn: remove,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["jobs"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const bookmarkMutation = useMutation({
    mutationKey: ["bookmark"],
    mutationFn: bookmark,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["jobs"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const removeBookmarkMutation = useMutation({
    mutationKey: ["bookmark"],
    mutationFn: removeBookmark,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["jobs"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const blockMutation = useMutation({
    mutationKey: [`block-${job.user_id}`],
    mutationFn: block,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["jobs"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  return (
    <ContextMenu
      showCircle
      items={[
        user &&
          user.id === job.user_id && {
            label: t("main.profileViewTabs.aboutMe.content.sidebarButtons.edit"),
            onClick: () => {
              return null;
            },
            icon: PencilIcon,
          },
        user &&
          (user.id === job.user_id || user.admin) && {
            label: t("components.shared.remove"),
            onClick: () => removeMutation.mutate({ id: job.id }),
            icon: TrashIcon,
          },
        {
          label: t("buttons.report"),
          onClick: () => dispatch(showReportModal(job.id, "job")),
          icon: FlagIcon,
        },
        {
          label: t("buttons.block"),
          onClick: () => blockMutation.mutate({ id: job.user_id }),
          icon: MinusCircleIcon,
        },
        {
          label: t("buttons.save"),
          onClick: () =>
            job.bookmarked
              ? removeBookmarkMutation.mutate({ id: job.id })
              : bookmarkMutation.mutate({ id: job.id }),
          icon: job.bookmarked ? BookmarkIconSolid : BookmarkIcon,
        },

        user?.type === "person" && {
          label: t("buttons.apply"),
          onClick: () => {
            return null;
          },
          icon: DocumentIcon,
        },
        user?.type === "person" && {
          label: t("main.shop.buttons.contact"),
          onClick: () => {
            return null;
          },
          icon: ArrowUturnRightIcon,
        },
      ]}
    />
  );
};

export default JobsDropDown;
