import Button from "@src/components/elements/input/Button";
import Checkbox from "@src/components/elements/input/Checkbox";
import Input from "@src/components/elements/input/Input";
import BackButton from "@src/components/nav/BackButton";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";

const CreateAccount = () => {
  const [contactEmail, setContactEmail] = useState("");
  const [contactSurname, setContactSurname] = useState("");
  const [contactLastname, setContactLastname] = useState("");
  const [contactCompany, setContactCompany] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactMessage, setContactMessage] = useState("");
  const [privacyCheck, setPrivacyCheck] = useState(false);
  const [newsletterCheck, setNewsletterCheck] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <div className="pl-4 pt-4">
        <BackButton stepback desktopVisible />
      </div>
      <form>
        <div className="grid grid-flow-row gap-6 text-gray-700">
          <section className="relative">
            <img
              src="/images/info/jobangebote_job_angebote_2_tradefoox.jpg"
              className="h-[300px] w-full object-cover"
            />
            <div className="absolute left-6 top-1/2 flex -translate-y-1/2 flex-col font-semibold italic">
              <div className="ml-4 w-fit bg-darkblue/80 px-1 text-white">
                {t("pages.createAccount.text1")}
              </div>
            </div>
          </section>

          <article className="grid grid-flow-row gap-3 px-4 text-justify text-xs">
            <h1 className="text-left text-smbase font-bold">{t("pages.createAccount.text2")}</h1>
            <div>{t("pages.createAccount.text3")}</div>
            <div>{t("pages.createAccount.text4")}</div>
            <div>{t("pages.createAccount.text5")}</div>
            <div>{t("pages.createAccount.text6")}</div>
            <div>{t("pages.createAccount.text7")}</div>
            <div>{t("pages.createAccount.text8")}</div>
            <h1 className="text-xs font-semibold">{t("pages.createAccount.text9")}</h1>
            <div>{t("pages.createAccount.text10")}</div>
            <div>{t("pages.createAccount.text11")}</div>
            <div>{t("pages.createAccount.text12")}</div>
            <div>{t("pages.createAccount.text13")}</div>
            <div>{t("pages.createAccount.text14")}</div>
            <div>{t("pages.createAccount.text15")}</div>
          </article>
          <div className="grid grid-flow-row gap-3 px-4 pb-4 text-justify">
            <Input
              label={t("pages.createAccount.label1")}
              type="text"
              name="company"
              placeholder={t("pages.createAccount.placeholder1")}
              value={contactCompany}
              onChange={e => {
                setContactCompany(e);
              }}
              required
            />
            <div className="grid grid-cols-1 gap-2 lg:grid-cols-2">
              <Input
                label={t("main.profileViewTabs.editProfile.form.firstName")}
                type="text"
                name="surname"
                placeholder="Max"
                value={contactSurname}
                onChange={e => {
                  setContactSurname(e);
                }}
                required
              />
              <Input
                label={t("main.profileViewTabs.editProfile.form.lastName")}
                type="text"
                name="name"
                placeholder="Mustermann"
                value={contactLastname}
                onChange={e => {
                  setContactLastname(e);
                }}
                required
              />
              <Input
                label={t("main.profileViewTabs.editProfile.form.email")}
                type="text"
                name="email"
                placeholder="E-Mail"
                value={contactEmail}
                onChange={e => {
                  setContactEmail(e);
                }}
                required
              />
              <Input
                label={t("main.profileViewTabs.editProfile.form.phone")}
                type="text"
                name="phone"
                placeholder="+49 1234 123456"
                value={contactPhone}
                onChange={e => {
                  setContactPhone(e);
                }}
                required
              />
            </div>
            <div>
              {t("pages.createAccount.text16")}
              <TextareaAutosize
                minRows={3}
                value={contactMessage}
                onChange={e => {
                  setContactMessage(e.target.value);
                }}
                className="block w-full rounded-xl border-0 bg-gray-200 px-4 py-3"
                required
              />
            </div>
            <Checkbox name="agb" required checked={privacyCheck} onChange={e => setPrivacyCheck(e)}>
              {t("pages.createAccount.text17")}{" "}
              <NavLink to="/datenschutz" target="_blank" className="text-darkcyan">
                {t("pages.createAccount.text18")}
              </NavLink>
              {t("pages.createAccount.text19")}
            </Checkbox>
            <Checkbox
              name="newsletter"
              checked={newsletterCheck}
              onChange={e => setNewsletterCheck(e)}
            >
              {t("components.shared.acceptNewsletter")}
            </Checkbox>
            <Button submit>{t("buttons.send")}</Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default CreateAccount;
