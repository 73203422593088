import BackButton from "@src/components/nav/BackButton";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

type PlaceType = {
  title: string;
  link: string;
  thumbnail: string;
};

const LeisureMaps = () => {
  const { t } = useTranslation();
  const places: Array<PlaceType> = [
    {
      title: "Chemnitzer Vereine",
      link: "https://www.google.com/maps/d/viewer?hl=de&mid=1OFHcnel2Ezl2Kjn5vVLKVOJRcjkYxxIb&ll=50.83351219182122%2C12.92018098075773&z=11",
      thumbnail: "/images/info/karte_vereine.jpg",
    },
    {
      title: "Chemnitzer Sehenswürdigkeiten und Museen",
      link: "https://www.google.com/maps/d/viewer?hl=de&mid=1OFHcnel2Ezl2Kjn5vVLKVOJRcjkYxxIb&ll=50.83351219182122%2C12.92018098075773&z=11",
      thumbnail: "/images/info/karte_vereine.jpg",
    },
    {
      title: "Galerien, Kunst und Ausstellungen",
      link: "https://www.google.com/maps/d/viewer?hl=de&mid=1OFHcnel2Ezl2Kjn5vVLKVOJRcjkYxxIb&ll=50.83351219182122%2C12.92018098075773&z=11",
      thumbnail: "/images/info/karte_vereine.jpg",
    },
  ];

  return (
    <>
      <div className="pl-4 pt-4">
        <BackButton stepback desktopVisible />
      </div>
      <div className="grid grid-flow-row gap-6 pb-4 text-gray-700">
        <section className="relative">
          <img
            src="/images/info/projekt-auftragsvergabe-header-3.jpg"
            className="h-[300px] w-full object-cover"
          />
          <div className="absolute left-6 top-1/2 flex -translate-y-1/2 flex-col font-semibold italic">
            <div className="w-fit bg-white/80 px-1 text-2xl text-darkcyan">
              {t("pages.events.text1")}
            </div>
            <div className="ml-4 w-fit bg-darkblue/80 px-1 text-white">
              {t("pages.events.text2")}
            </div>
          </div>
        </section>

        <div className="grid grid-cols-2 gap-2 lg:grid-cols-3">
          {places.map((place: PlaceType) => {
            return (
              <NavLink to={place.link} key={place.title} target="_blank">
                <div className="group relative h-[225px] overflow-hidden shadow-lg">
                  <img
                    src={place.thumbnail}
                    className="h-[225px] w-full object-cover group-hover:scale-150 group-hover:brightness-50"
                  />
                  <h2 className="absolute top-2 w-full break-words px-2 text-center text-xs font-semibold group-hover:text-base group-hover:font-semibold group-hover:text-gray-100">
                    {place.title}
                  </h2>
                </div>
              </NavLink>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default LeisureMaps;
