import { PlusCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import UserWidget from "@src/components/elements/UserWidget";
import UserComboBox from "@src/components/elements/form/UserComboBox";
import getName from "@src/utils/getName";
import { User } from "@src/utils/user";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  contacts?: Array<User>;
  onChange: (array: Array<User>) => void;
  allowCustom?: boolean;
};

const ContactsEditor = ({ contacts = [], onChange, allowCustom }: Props) => {
  /**
   * Removes user from list
   * @param index
   */
  const removeUser = (index: number) => {
    const newContacts = JSON.parse(JSON.stringify(contacts));
    newContacts.splice(index, 1);
    onChange(newContacts);
  };
  const { t } = useTranslation();

  /**
   * Adds user to list
   * @param user
   */
  const addUser = (user: User) => {
    const newContacts = JSON.parse(JSON.stringify(contacts));
    newContacts.push(user);
    onChange(newContacts);
  };

  const emptyUser = (): User => {
    return {
      admin: false,
      follower_count: 0,
      following_count: 0,
      id: "",
      job_count: 0,
      like_count: 0,
      liked: false,
      post_count: 0,
      type: "person",
      username: "",
      person: {
        firstname: "Vorname",
        lastname: "Nachname",
        job_position: "",
        department: "",
        birthday: "",
        education: [],
        cv: [],
        country: "",
        zip_code: "",
        email: "",
        phone_number: "",
        street: "",
        website: "",
        gender: "",
        relationship: "",
        current_employer: "",
        looking_for: "",
        offer: "",
        languages: "",
        interests: "",
        personal: "",
        socials: [],

        publish_career: false,
      },
      watch_count: 0,
      normalised_account_name: "",
      website: "",
      username_change_count: 0,
    };
  };

  const [selectedUser, setSelectedUser] = useState<User>(emptyUser());

  return (
    <div>
      <div className="relative mb-2 flex flex-row items-center justify-between gap-2">
        <div className="w-full">
          <UserComboBox
            onSelect={e => {
              addUser(e);
            }}
            onChange={e => {
              const newUser = emptyUser();
              if (newUser.person) newUser.person.firstname = e;
              setSelectedUser(newUser);
            }}
            placeholder={t("main.header.links.messenger.searchBar")}
            userType="person"
          />
        </div>
        {!!(selectedUser && allowCustom) && (
          <button
            onClick={() => {
              addUser(selectedUser);
            }}
            className="flex flex-col items-center"
          >
            <PlusCircleIcon className="mt-2 size-8 text-green-600" />
          </button>
        )}
      </div>
      <div className="grid grid-flow-row gap-y-2 divide-y divide-gray-400 text-sm">
        <div className="grid grid-cols-[calc(50%-25px),calc(50%-25px),25px] items-center justify-between gap-x-4">
          <div className="rounded-full bg-darkblue text-center text-white">
            {t("main.profileViewTabs.editProfile.form.name")}
          </div>
          <div className="rounded-full bg-darkblue text-center text-white">
            {t("main.profileViewTabs.editProfile.form.department")}
          </div>
          <div></div>
        </div>
        {contacts.map((user, index) => {
          return (
            <div
              className="relative grid grid-cols-[calc(50%-25px),calc(50%-25px),25px] items-center gap-x-4 gap-y-2 divide-black pt-1"
              key={user.id}
            >
              <div className="flex flex-row items-center gap-2 overflow-x-hidden truncate px-2">
                <UserWidget user={user} online={true} showName={false} follow={false} />
                <div>{getName(user)}</div>
              </div>
              <div className="flex flex-row items-center gap-2 px-2">{user.person?.department}</div>
              <button
                className="absolute right-0 top-1/2 -translate-y-1/2"
                onClick={() => {
                  removeUser(index);
                }}
              >
                <XCircleIcon className="size-8 text-red-600" />
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ContactsEditor;
