import { CheckIcon, PencilIcon, ShareIcon, TrashIcon } from "@heroicons/react/24/outline";
import Panel from "@src/components/box/Panel";
import JobsDropDown from "@src/pages/jobs/JobsDropDown";
import { JobType } from "@src/utils/types/JobType";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

type Props = {
  job: JobType;
  showButtons?: boolean;
  minimized?: boolean;
  showStats?: boolean;
  key?: string;
};

const JobsListItem = ({ job, showButtons = false, minimized = false, showStats = true }: Props) => {
  const { t } = useTranslation();
  return (
    <Panel>
      <div className="text-sm transition delay-150 ease-in-out">
        <NavLink to={"/jobs/" + job.id}>
          <div className="group/contextmenu relative flex aspect-square items-center justify-center">
            <img alt="" src={job.user.avatar} className="rounded-t-xl" />
            <div className="absolute right-2 top-2">
              <JobsDropDown job={job} />
            </div>
          </div>
          {!minimized && (
            <Fragment>
              <div className="line-clamp-1 h-[20px] overflow-x-hidden text-ellipsis text-center text-sm">
                {job.title}
              </div>
              {showStats && (
                <div className="flex w-full flex-col gap-1">
                  <div className="flex flex-row justify-between text-xs text-gray-600">
                    <div className="flex flex-col items-center gap-x-1">
                      <div>{t("main.likes.title")}</div>
                      <div>0</div>
                    </div>
                    <div className="flex flex-col items-center gap-x-1">
                      <div>{t("main.seen.title")}</div>
                      <div>0</div>
                    </div>
                    <div className="flex flex-col items-center gap-x-1">
                      <div>{t("main.bookmarksJobs.title")}</div>
                      <div>0</div>
                    </div>
                  </div>
                </div>
              )}
            </Fragment>
          )}
        </NavLink>
        {showButtons && (
          <div className="flex justify-between">
            <PencilIcon className="heroicon-stroke-w-2.4 size-4 rounded-full bg-green-600 p-0.5 text-white" />
            <ShareIcon className="heroicon-stroke-w-2.4 size-4 rounded-full bg-green-600 p-0.5 text-white" />
            <CheckIcon className="heroicon-stroke-w-2.4 size-4 rounded-full bg-green-600 p-0.5 text-white" />
            <TrashIcon className="heroicon-stroke-w-2.4 size-4 rounded-full bg-red-600 p-0.5 text-white" />
          </div>
        )}
      </div>
    </Panel>
  );
};

export default JobsListItem;
