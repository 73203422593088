import Assignment from "@src/components/elements/Assignment";
import Input from "@src/components/elements/input/Input";
import TabBodies from "@src/components/elements/shared/tab/TabBodies";
import TabBody from "@src/components/elements/shared/tab/TabBody";
import TabController from "@src/components/elements/shared/tab/TabController";
import TabHeader from "@src/components/elements/shared/tab/TabHeader";
import TabHeaders from "@src/components/elements/shared/tab/TabHeaders";
import AssignmentsCreateContainer from "@src/pages/assignments/AssignmentsCreateContainer";
import FullPageContainer from "@src/pages/assignments/fullpage/FullPageContainer";
import { assignments } from "@src/utils/assignmentsData";
import { useTranslation } from "react-i18next";

type Props = {
  topTab?: string;
  subTab?: string;
};

const AssignmentsContainer = ({ topTab = "shop", subTab = "all" }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-2">
      <TabController defaultTab={topTab}>
        <TabHeaders>
          <TabHeader id="for_you" url="/inserate/für_dich">
            {t("main.navLinks.createAd.tabs.toYou")}
          </TabHeader>
          <TabHeader id="myAdvertisements" url="/inserate/meine_inserate">
            {t("main.navLinks.createAd.tabs.myAdvertisements")}
          </TabHeader>
          <TabHeader id="create" url="/inserate/inserate_erstellen">
            {t("main.navLinks.createAd.tabs.create")}
          </TabHeader>
          <TabHeader id="follower" url="/inserate/follower">
            {t("main.navLinks.createAd.tabs.follower")}
          </TabHeader>
          <TabHeader id="trending" url="/inserate/trending">
            {t("main.navLinks.createAd.tabs.trending")}
          </TabHeader>
        </TabHeaders>
        {topTab !== "create" ? (
          <Input
            name="inserate-keywords"
            placeholder={t("components.shared.search", {
              tab: t("main.navLinks.createAd.tabs." + (subTab || topTab)),
            })}
            clearable
          />
        ) : (
          <></>
        )}
        <TabBodies>
          <TabBody id="for_you">
            <TabController defaultTab={subTab} isSubTab>
              <TabHeaders>
                <TabHeader id="all" url="/inserate/für_dich">
                  {t("main.navLinks.createAd.tabs.all")}
                </TabHeader>
                <TabHeader id="requested" url="/inserate/angefragt">
                  {t("main.navLinks.createAd.tabs.requested")}
                </TabHeader>
                <TabHeader id="manage" url="/inserate/verwalten">
                  {t("main.navLinks.createAd.tabs.manage")}
                </TabHeader>
                <TabHeader id="bills" url="/inserate/rechnungen">
                  {t("main.navLinks.createAd.tabs.bills")}
                </TabHeader>
                <TabHeader id="statistics" url="/inserate/statistik">
                  {t("main.navLinks.createAd.tabs.statistics")}
                </TabHeader>
              </TabHeaders>
              <div className="grid grid-cols-2 gap-4 md:grid-cols-3 xl:grid-cols-5">
                {assignments.map(assignment => (
                  <Assignment
                    id={assignment.id}
                    key={assignment.id}
                    title={assignment.title}
                    city={assignment.city}
                    start_at={assignment.start_at}
                  />
                ))}
              </div>

              <TabBodies>
                <TabBody id="all">
                  <FullPageContainer />
                </TabBody>
                <TabBody id="requested">
                  <></>
                </TabBody>
                <TabBody id="manage">
                  <></>
                </TabBody>
                <TabBody id="bills">
                  <></>
                </TabBody>
                <TabBody id="stats">
                  <></>
                </TabBody>
              </TabBodies>
            </TabController>
          </TabBody>
          <TabBody id="myAdvertisements">
            <></>
          </TabBody>
          <TabBody id="create">
            <AssignmentsCreateContainer />
          </TabBody>
          <TabBody id="follower">
            <></>
          </TabBody>
          <TabBody id="trending">
            <></>
          </TabBody>
        </TabBodies>
      </TabController>
    </div>
  );
};

export default AssignmentsContainer;
