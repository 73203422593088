import { Menu } from "@headlessui/react";
import { MinusCircleIcon } from "@heroicons/react/24/outline";
import unblock from "@src/api/users/unblock";
import { showBlockUserModal } from "@src/state/blockUserModal/actions";
import { useAppSelector } from "@src/state/hooks";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

type Props = {
  userId: string;
  blocked?: boolean;
  keys?: string[];
};

const BlockUserDropdownButton = (props: Props) => {
  const { user } = useAppSelector(state => state.user);
  const { t } = useTranslation();
  const { userId, blocked, keys = [] } = props;
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const unblockMutation = useMutation({
    mutationKey: [`unblock-${userId}`],
    mutationFn: unblock,
    onSuccess: () => {
      keys.forEach(key => {
        queryClient.invalidateQueries({ queryKey: [key] });
      });
    },
    onError: () => {
      alert("Failed");
    },
  });

  if (user?.id === userId) return <></>;

  return (
    <Menu.Item>
      {({ active }) => (
        <button
          onClick={e => {
            if (blocked) {
              unblockMutation.mutate({ id: userId });
            } else {
              dispatch(showBlockUserModal(userId));
            }
            e.stopPropagation();
          }}
          type="submit"
          className={`inline-flex w-full items-center justify-between px-4 py-2 text-left text-sm ${active ? "bg-gray-100 text-gray-900" : "text-gray-700"}`}
        >
          {blocked ? `${t("buttons.unblock")}` : `${t("buttons.block")}`}
          <MinusCircleIcon className="size-4" />
        </button>
      )}
    </Menu.Item>
  );
};

export default BlockUserDropdownButton;
