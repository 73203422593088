import Assignment from "@src/components/elements/Assignment";
import { assignments } from "@src/utils/assignmentsData";

type Props = {
  showPlaceholder?: boolean;
};

const Assignments = ({ showPlaceholder }: Props) => {
  return (
    <>
      {showPlaceholder && <div className="h-[35px]" />}
      <div className="grid grid-cols-2 gap-1 md:grid-cols-3 xl:grid-cols-5 xl:gap-3">
        {assignments.map(assignment => (
          <Assignment
            id={assignment.id}
            key={assignment.id}
            title={assignment.title}
            city={assignment.city}
            start_at={assignment.start_at}
          />
        ))}
      </div>
    </>
  );
};

export default Assignments;
