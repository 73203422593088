import UserDetailsWidget from "@src/components/elements/UserDetailsWidget";
import useUserBubbleModal from "@src/state/modal/useUserBubbleModal";

const UserDetailsWidgetWrapper = () => {
  const userBubbleModal = useUserBubbleModal();

  if (userBubbleModal.userBubble.isOpen && userBubbleModal.userBubble.user != undefined)
    return (
      <div
        className="pointer-events-none fixed z-[100] mt-[45px] w-[300px] pt-[5px]"
        style={{
          left:
            userBubbleModal.userBubble.position.x + (userBubbleModal.userBubble?.offset?.x ?? 0),
          top: userBubbleModal.userBubble.position.y + (userBubbleModal.userBubble?.offset?.y ?? 0),
        }}
        onMouseLeave={() => {
          userBubbleModal.close();
        }}
        aria-hidden
      >
        <div
          className="pointer-events-auto h-fit rounded-full shadow-[0_8px_30px_rgb(0,0,0,0.12)]"
          onClick={e => {
            e.stopPropagation();
          }}
          onMouseEnter={() => {
            userBubbleModal.setFocused();
          }}
          onMouseLeave={() => {
            userBubbleModal.close(true);
          }}
          aria-hidden
        >
          <UserDetailsWidget user={userBubbleModal.userBubble.user} />
        </div>
      </div>
    );
  return <></>;
};

export default UserDetailsWidgetWrapper;
