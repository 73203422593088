import jobs from "@src/api/jobs/jobs";
import { useInfiniteQueryOnScroll } from "@src/hooks/useInfiniteQueryOnScroll";
import JobsListItem from "@src/pages/jobs/JobsListItem";
import { JobType } from "@src/utils/types/JobType";

type Props = {
  searchQuery?: string;
  showPlaceholder?: boolean;
};

const Jobs = ({ searchQuery, showPlaceholder }: Props) => {
  const { result: jobsResult } = useInfiniteQueryOnScroll({
    queryKey: ["jobs", searchQuery],
    queryFn: ({ pageParam }) => jobs({ params: { page: pageParam, q: searchQuery } }),
    initialPageParam: 0,
    getNextPageParam: lastPage => {
      const nextPage = lastPage.current_page + 1;
      return nextPage <= lastPage.total_pages ? nextPage : null;
    },
  });

  return (
    <>
      {showPlaceholder && <div className="h-[35px]" />}
      <div className="grid grid-cols-2 gap-1 md:grid-cols-3 xl:grid-cols-5 xl:gap-3">
        {jobsResult.data?.pages.map(page =>
          page?.data?.map((job: JobType) => (
            <JobsListItem key={job.id} job={job} showButtons={false} />
          )),
        )}
      </div>
    </>
  );
};

export default Jobs;
