import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  height?: string;
  showBackground?: boolean;
};

const ScrollListH = ({ children, height, showBackground = true }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  return (
    <div
      className={`group relative w-full border border-gray-300 ${showBackground && "rounded-lg bg-white p-3 lg:border lg:border-gray-300"} ${height ?? "h-[260px] md:h-[240px]"}`}
    >
      <div className="mb-3">
        <div className="flex flex-col md:flex-row md:justify-between">
          <span className="truncate text-base font-semibold">
            {t("main.marketplace.tabs.titleBestSellerOffer")}
          </span>
          <a className="" href="">
            <span className="text-xs">{t("main.marketplace.tabs.allOffer")}</span>
          </a>
        </div>
      </div>
      <div className="flex w-full flex-row justify-between gap-1 overflow-x-hidden scrollbar-thin group-hover:overflow-x-auto">
        {children}
      </div>
    </div>
  );
};

export default ScrollListH;
