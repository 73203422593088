import { User } from "@src/utils/user";
import { TFunction } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = { user: User; formType?: "basic" | "additional" | "offers" };

const UserInfoField: React.FC<{
  icon: string;
  label: string;
  value?: string | null;
}> = ({ icon, label, value }) => (
  <div className="grid max-w-full grid-cols-[20px,1fr] items-center">
    <img alt="" src={icon} className="size-[15px]" />
    <div className="font-semibold">{label}:</div>
    <div />
    <div>{value}</div>
  </div>
);

const getGenderDisplay = (gender: string | undefined, t: TFunction) => {
  switch (gender) {
    case "female":
      return t("main.profileViewTabs.editProfile.form.genders.female");
    case "male":
      return t("main.profileViewTabs.editProfile.form.genders.male");
    case "diverse":
      return t("main.profileViewTabs.editProfile.form.genders.diverse");
    default:
      return gender;
  }
};

const getRelationshipDisplay = (relationship: string | undefined, t: TFunction) => {
  switch (relationship) {
    case "single":
      return t("main.profileViewTabs.editProfile.form.relationshipstatus.single");
    case "married":
      return t("main.profileViewTabs.editProfile.form.relationshipstatus.married");
    case "engaged":
      return t("main.profileViewTabs.editProfile.form.relationshipstatus.engaged");
    case "widow":
      return t("main.profileViewTabs.editProfile.form.relationshipstatus.widow");
    case "custom":
      return t("main.profileViewTabs.editProfile.form.relationshipstatus.custom");
    default:
      return null;
  }
};

const UserDataForm: React.FC<Props> = ({ user, formType = "basic" }: Props) => {
  const { t } = useTranslation();

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const fields = [
    {
      icon: "/images/icons/user.svg",
      label: t("main.profileViewTabs.editProfile.form.username"),
      value: user.normalised_account_name,
    },
    {
      icon: "/images/icons/pencil-square.svg",
      label: t("main.profileViewTabs.editProfile.form.firstName"),
      value: user.person?.firstname,
    },
    {
      icon: "/images/icons/pencil-square.svg",
      label: t("main.profileViewTabs.editProfile.form.lastName"),
      value: user.person?.lastname,
    },
    {
      icon: "/images/icons/calender-days.svg",
      label: t("main.profileViewTabs.editProfile.form.birthday"),
      value: formatDate(user.person?.birthday ?? ""),
    },
    {
      icon: "/images/icons/user-circle.svg",
      label: t("main.profileViewTabs.editProfile.form.gender"),
      value: getGenderDisplay(user.person?.gender, t),
    },
    {
      icon: "/images/icons/flag.svg",
      label: t("main.profileViewTabs.editProfile.form.country"),
      value: user.person?.country,
    },
    {
      icon: "/images/icons/user-group.svg",
      label: t("main.profileViewTabs.editProfile.form.relationship"),
      value: getRelationshipDisplay(user.person?.relationship, t),
    },
  ];

  const additionalFields = [
    {
      icon: "/images/icons/globe-alt.svg",
      label: t("main.profileViewTabs.editProfile.form.website"),
      value: user.website,
    },
    {
      icon: "/images/icons/user-circle.svg",
      label: t("main.profileViewTabs.editProfile.form.currentEmployer"),
      value: user.person?.current_employer,
    },
    {
      icon: "/images/icons/briefcase.svg",
      label: t("main.profileViewTabs.editProfile.form.position"),
      value: user.person?.job_position,
    },
  ];

  const offersFields = [
    {
      icon: "/images/icons/adjustments-horizontal.svg",
      label: t("main.navLinks.jobsPublished.description"),
      value: user.description,
    },
    {
      icon: "/images/icons/adjustments-horizontal.svg",
      label: t("main.profileViewTabs.editProfile.form.moreInformation.search"),
      value: user.person?.looking_for,
    },
    {
      icon: "/images/icons/adjustments-horizontal.svg",
      label: t("main.profileViewTabs.editProfile.form.moreInformation.iOffer"),
      value: user.person?.offer,
    },
    {
      icon: "/images/icons/adjustments-horizontal.svg",
      label: t("main.profileViewTabs.editProfile.form.moreInformation.languages"),
      value: user.person?.languages,
    },
    {
      icon: "/images/icons/adjustments-horizontal.svg",
      label: t("main.profileViewTabs.editProfile.form.moreInformation.interests"),
      value: user.person?.interests,
    },
    {
      icon: "/images/icons/adjustments-horizontal.svg",
      label: t("main.profileViewTabs.editProfile.form.moreInformation.personal"),
      value: user.person?.personal,
    },
  ];

  const hasAdditionalFields = additionalFields.some(field => field.value);
  const hasoffersFields = offersFields.some(field => field.value);
  return (
    <div className="w-full lg:w-auto xl:rounded-xl xl:border xl:border-gray-300 xl:bg-gray-100 xl:p-4">
      {formType === "basic" && (
        <div className="flex flex-col gap-2">
          <div className="text-base font-semibold">
            {t("main.navLinks.jobsPublished.contactInformation")}
          </div>
          <hr />
          {fields.map(field => (
            <>
              <UserInfoField icon={field.icon} label={field.label} value={field.value} />
            </>
          ))}
        </div>
      )}

      {formType === "additional" && hasAdditionalFields && (
        <div className="flex flex-col gap-2">
          <div className="text-base font-semibold">
            {t("main.profileViewTabs.aboutMe.content.furtherinformation")}
          </div>
          <hr />
          {additionalFields.map(field => (
            <>
              <UserInfoField icon={field.icon} label={field.label} value={field.value} />
            </>
          ))}
        </div>
      )}

      {formType === "offers" && hasoffersFields && (
        <div className="flex flex-col gap-2 xl:max-w-[600px]">
          <div className="text-base font-semibold">
            {t("main.profileViewTabs.aboutMe.content.general")}
          </div>
          <hr />
          {offersFields.map(field => (
            <>
              <UserInfoField icon={field.icon} label={field.label} value={field.value} />
            </>
          ))}
        </div>
      )}
    </div>
  );
};
export default UserDataForm;
