import { Capacitor } from "@capacitor/core";
import { StatusBar, Style } from "@capacitor/status-bar";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/solid";
import like from "@src/api/posts/like";
import unlike from "@src/api/posts/unlike";
import LinkPreview from "@src/components/elements/LinkPreview";
import { formatDateDifference } from "@src/components/elements/PostWidget";
import UserWidget from "@src/components/elements/UserWidget";
import VerifyBadge from "@src/components/elements/VerifyBadge";
import PostCommentSection from "@src/components/elements/posts/comments/PostCommentSection";
import ProfileAvatar from "@src/components/elements/shared/ProfileAvatar";
import ProgressiveImage from "@src/components/elements/shared/ProgressiveImage";
import TextView from "@src/components/elements/textedit/TextView";
import CommentsPopup from "@src/components/popup/CommentsPopup";
import PostsActionBar from "@src/pages/news/posts/PostsActionBar";
import PostsDropdown from "@src/pages/news/posts/PostsDropdown";
import { useAppSelector } from "@src/state/hooks";
import getName from "@src/utils/getName";
import { checkHideLinkText, Post, removeLinkFromLexicalObject } from "@src/utils/post";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TouchEvent, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";

type Props = {
  onClose: () => void;
  onSwitch?: (id: string) => void;
  post: Post;
  next?: string;
  prev?: string;
};

const Lightbox = (props: Props) => {
  const { user } = useAppSelector(state => state.user);
  const postMedia = props.post.reposted ? props.post.reposted.media : props.post.media;

  const [initialTouchPosition, setInitialTouchPosition] = useState<number | null>(null);
  const [selectedMedia, setSelectedMedia] = useState(0);
  const galleryRef = useRef<HTMLDivElement>(null);
  const queryClient = useQueryClient();
  const [openComments, setOpenComments] = useState(false);
  const [tab, setTab] = useState("");
  const [_downloadRunning, setDownloadRunning] = useState(false);
  const [_downloadProgress, setDownloadProgress] = useState(0);
  const linkPreview = props.post.reposted
    ? props.post.reposted.link_preview
    : props.post.link_preview;

  const text = removeLinkFromLexicalObject(
    props.post.reposted ? props.post.reposted.text : props.post.text,
    props.post.link,
  );
  const hideLinkText = linkPreview && (linkPreview.url === text || checkHideLinkText(text));

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      StatusBar.setStyle({ style: Style.Dark });
    }
    return () => {
      if (Capacitor.isNativePlatform()) {
        StatusBar.setStyle({ style: Style.Light });
      }
    };
  }, []);

  const handleTouchStart = (e: TouchEvent<HTMLDivElement>) => {
    setInitialTouchPosition(e.touches[0].clientX);
  };
  const handleTouchMove = (e: TouchEvent<HTMLDivElement>) => {
    if (!initialTouchPosition) return;

    const touchPosition = e.touches[0].clientX;
    const difference = initialTouchPosition - touchPosition;

    if (difference > 50 && selectedMedia < postMedia.length - 1) {
      // Swipe left
      setSelectedMedia(selectedMedia + 1);
      setInitialTouchPosition(null);
    } else if (difference < -50 && selectedMedia !== 0) {
      // Swipe right
      setSelectedMedia(selectedMedia - 1);
      setInitialTouchPosition(null);
    }
  };

  const likeMutation = useMutation({
    mutationKey: [`like-${props.post.id}-${user?.id}`],
    mutationFn: like,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const unlikeMutation = useMutation({
    mutationKey: [`unlike-${props.post.id}-${user?.id}`],
    mutationFn: unlike,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  return (
    <div
      className="fixed left-0 top-0 z-40 size-full max-md:h-screen max-md:w-screen"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      <div className="absolute left-0 top-0 size-full bg-black" onClick={props.onClose} />
      <button
        onClick={props.onClose}
        className="absolute left-5 top-2 z-[1] p-0.5 max-md:rounded-full max-md:bg-gray-800"
      >
        <XMarkIcon className="size-8 text-white" />
      </button>
      {tab === "" && (
        <div className="absolute left-0 top-0 w-full">
          <div className="flex flex-col rounded-xl">
            <div className="flex w-full flex-row">
              {!!(linkPreview || postMedia.length) && (
                <div className="max-h-[env(safe-area-inset-top] relative h-screen overflow-x-hidden overflow-y-scroll bg-black object-cover scrollbar-none max-md:w-screen md:w-[80vw]">
                  <div
                    ref={galleryRef}
                    className="relative flex w-screen snap-x snap-mandatory overflow-x-auto overflow-y-hidden scroll-smooth scrollbar-none md:w-[80vw]"
                    onScroll={e => {
                      const index = Math.round(
                        e.currentTarget.scrollLeft / e.currentTarget.clientWidth,
                      );
                      setSelectedMedia(index);
                    }}
                  >
                    {postMedia.map(item => (
                      <div
                        className="relative mx-auto w-screen shrink-0 cursor-pointer snap-start px-8 md:w-[80vw] md:px-40"
                        key={item.key}
                      >
                        <div className="relative flex items-center justify-center">
                          {item.type === "image" && (
                            <ProgressiveImage
                              height="100%"
                              width="100%"
                              className="h-screen max-h-[calc(100dvh-60px-env(safe-area-inset-top))] w-full object-cover"
                              highResSrc={item.data_url}
                              lowResSrc={item.data_url}
                            />
                          )}
                          {item.type === "video" && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                width: "100%",
                              }}
                              className="video-cover"
                            >
                              <ReactPlayer
                                url={`${item.data_url}/playlist.m3u8`}
                                controls={true}
                                responsive
                                style={{
                                  border: 0,
                                  width: "80%",
                                  height: "80%",
                                }}
                                muted={true}
                                playing={true}
                                autoPlay={true}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* User name and hashtags */}
                  <div className="absolute bottom-[60px] left-4">
                    <div className="flex flex-row gap-2">
                      <div className="scale-[66%]">
                        <ProfileAvatar
                          user={{
                            id: props.post.author.id,
                            name: getName(props.post.author) ?? "",
                            type: props.post.author.type,
                            avatar: props.post.author.avatar ?? "",
                          }}
                          nameStyle="hidden"
                        />
                      </div>
                      <div className="overflow-hidden truncate text-sm text-white max-md:w-[calc(100dvw-75px)] md:w-[425px]">
                        <b>@{getName(props.post.author)}</b>
                        <TextView value={text} singleLine disableTopMargin />
                      </div>
                    </div>
                  </div>
                  {postMedia.length > 1 && (
                    <div className="absolute bottom-[70px] left-1/2 flex -translate-x-1/2 justify-center gap-2">
                      {postMedia.map((_media, index) => (
                        <div
                          className={`size-[7px] cursor-pointer rounded-full border border-white bg-black ${index === selectedMedia && "border-black bg-white"}`}
                          onClick={() => {
                            if (galleryRef.current)
                              galleryRef.current.scrollTo(
                                index * galleryRef.current.clientWidth,
                                0,
                              );
                          }}
                          key={index}
                        ></div>
                      ))}
                    </div>
                  )}
                  {/* Switch between media */}
                  {selectedMedia !== 0 && (
                    <div
                      className="pointer-events-none absolute left-0 top-1/2 flex h-full w-20 -translate-y-1/2 cursor-pointer items-center sm:pointer-events-auto"
                      onClick={() => {
                        if (galleryRef.current)
                          galleryRef.current.scrollTo(
                            (selectedMedia - 1) * galleryRef.current.clientWidth,
                            0,
                          );
                      }}
                    >
                      <div className="group absolute left-0 top-0 z-0 flex size-full items-center justify-center opacity-0 hover:opacity-100">
                        <div className="rounded-full bg-white p-2 group-hover:bg-white/50">
                          <ChevronLeftIcon className="z-50 mx-auto size-[25px]" />
                        </div>
                      </div>
                    </div>
                  )}
                  {selectedMedia < postMedia.length - 1 && (
                    <div
                      className="pointer-events-none absolute right-0 top-1/2 flex h-full w-20 -translate-y-1/2 cursor-pointer items-center sm:pointer-events-auto"
                      onClick={() => {
                        if (galleryRef.current)
                          galleryRef.current.scrollTo(
                            (selectedMedia + 1) * galleryRef.current.clientWidth,
                            0,
                          );
                      }}
                    >
                      <div className="group absolute right-0 top-0 z-0 flex size-full items-center justify-center opacity-0 hover:opacity-100">
                        <div className="rounded-full bg-white p-2 group-hover:bg-white/50">
                          <ChevronRightIcon className="z-50 mx-auto size-[25px]" />
                        </div>
                      </div>
                    </div>
                  )}

                  {linkPreview && <LinkPreview link={linkPreview} hideBorder hideEdge />}

                  {/* Switch between posts */}
                  {props.prev && (
                    <div className="absolute left-1/2 top-0 flex h-20 w-full -translate-x-1/2 cursor-pointer items-center">
                      <div className="group absolute left-0 top-0 flex size-full items-center justify-center hover:opacity-100 max-md:opacity-50 md:opacity-0">
                        <div className="rounded-full bg-white p-2">
                          <ChevronUpIcon
                            className="z-40 mx-auto size-[25px]"
                            onClick={() => props.onSwitch && props.onSwitch(props.prev ?? "")}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {props.next && (
                    <div className="absolute bottom-[70px] right-1/2 flex h-20 w-full translate-x-1/2 cursor-pointer items-center">
                      <div className="group absolute bottom-0 right-0 flex size-full items-center justify-center hover:opacity-100 max-md:opacity-50 md:opacity-0">
                        <div className="rounded-full bg-white p-2">
                          <ChevronDownIcon
                            className="z-40 mx-auto size-[25px]"
                            onClick={() => props.onSwitch && props.onSwitch(props.next ?? "")}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="relative h-screen w-[20vw] overflow-x-hidden overflow-y-scroll bg-white p-2 pt-7 scrollbar-thin max-md:hidden">
                <div className="flex flex-row items-center gap-1 pb-1.5">
                  <UserWidget user={props.post.author} size={35} />
                  <div className="truncate">{getName(props.post.author)}</div>
                  <VerifyBadge type={props.post.author.type} />
                  <div className="ml-auto">
                    <PostsDropdown
                      post={props.post}
                      onDownloadStart={() => setDownloadRunning(true)}
                      onDownloadEnd={() => setDownloadRunning(false)}
                      onDownloadProgress={progress => setDownloadProgress(progress)}
                    />
                  </div>
                </div>

                <div className="text-[0.7rem] leading-4 text-gray-600">
                  {formatDateDifference(new Date(), new Date(props.post.created_at))}
                </div>
                {!!(!hideLinkText && text) && <TextView value={text} hashtagType="feed" />}
                <PostsActionBar
                  toggleComments={() => setOpenComments(value => !value)}
                  toggleLike={() => {
                    if (user) {
                      if (props.post && props.post.liked) {
                        unlikeMutation.mutate({ id: props.post.id });
                      } else {
                        likeMutation.mutate({ id: props.post.id });
                      }
                    }
                  }}
                  isLiked={props.post.liked}
                  isReposted={props.post.is_reposted}
                  likeCount={props.post.like_count}
                  viewCount={props.post.view_count}
                  bookmarkCount={props.post.bookmark_count}
                  repostCount={props.post.repost_count}
                  bookmarked={props.post.bookmarked}
                  postId={props.post.id}
                  commentCount={props.post.comment_count}
                />
                <hr className="my-1" />
                {openComments && (
                  <PostCommentSection postId={props.post.id} viewCount={props.post.view_count} />
                )}
              </div>
            </div>
            <div className="absolute bottom-[10px] min-w-[80vw] max-w-[80vw]">
              <PostsActionBar
                fullscreen
                toggleComments={() =>
                  window.innerWidth < 1280 ? setTab("comments") : setOpenComments(value => !value)
                }
                toggleLike={() => {
                  if (user) {
                    if (props.post && props.post.liked) {
                      unlikeMutation.mutate({ id: props.post.id });
                    } else {
                      likeMutation.mutate({ id: props.post.id });
                    }
                  }
                }}
                isLiked={props.post.liked}
                isReposted={props.post.is_reposted}
                likeCount={props.post.like_count}
                viewCount={props.post.view_count}
                bookmarkCount={props.post.bookmark_count}
                repostCount={props.post.repost_count}
                bookmarked={props.post.bookmarked}
                postId={props.post.id}
                commentCount={props.post.comment_count}
                dark
              />
            </div>
          </div>
        </div>
      )}
      {tab === "comments" && (
        <CommentsPopup
          onClose={() => setTab("")}
          comment_count={props.post.comment_count}
          postId={props.post.id}
        />
      )}
    </div>
  );
};

export default Lightbox;
