import AllProducts from "@src/mocks/AllProducts.json";
import Product from "@src/pages/marketplace/components/Product";
import MarketplaceCarousels from "@src/pages/marketplace/shop/MarketplaceCarousels";

const LastSeenContainer = () => {
  const allProductsFromFile = AllProducts.allProducts;
  return (
    <div className="bg-white">
      <div className="mb-5 grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-5">
        {allProductsFromFile.map(product => (
          <div key={product.ID} className="flex justify-center">
            <Product product={product} />
          </div>
        ))}
      </div>
      <MarketplaceCarousels />
    </div>
  );
};

export default LastSeenContainer;
