import { PropsWithChildren } from "react";

type Props = {
  height?: string;
  showBackground?: boolean;
};

const ScrollListH = ({ children, height, showBackground = true }: PropsWithChildren<Props>) => {
  return (
    <div
      className={`group relative w-full ${showBackground && "rounded-2xl bg-white p-3 lg:border lg:border-gray-300"} ${height ?? "h-[240px]"}`}
    >
      <div className="flex w-full flex-row justify-between gap-3 overflow-x-hidden pb-2 scrollbar-thin group-hover:overflow-x-auto">
        {children}
      </div>
    </div>
  );
};

export default ScrollListH;
