import { MinusCircleIcon } from "@heroicons/react/24/solid";
import Input from "@src/components/elements/input/Input";
import Select from "@src/components/elements/input/Select";
import { useTranslation } from "react-i18next";

const types = [
  { name: "Twitter", value: "twitter", url: "https://twitter.com/" },
  { name: "Facebook", value: "facebook", url: "https://facebook.com/" },
  { name: "Instagram", value: "instagram", url: "https://instagram.com/" },
  { name: "Tiktok", value: "tiktok", url: "https://tiktok.com/" },
  { name: "Youtube", value: "youtube", url: "https://youtube.com/" },
  { name: "Linkedin", value: "linkedin", url: "https://linkedin.com/" },
  { name: "Xing", value: "xing", url: "https://xing.com/" },
];

type Social = {
  type: string;
  username: string;
};

type Props = {
  value: Array<Social>;
  onChange: (data: Array<Social>) => void;
};

const ProfileEditSocialsContainer = ({
  onChange,
  value = [{ type: "twitter", username: "" }],
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className="col-span-2 mt-1">
      {value.map((item, i: number) => (
        <div
          key={`${item.type}-${item.username}`}
          className="grid grid-cols-[1fr,auto] items-center gap-2"
        >
          <div className="grid grid-cols-[100px,1fr] items-end gap-2">
            <Select
              items={types}
              name="type"
              onChange={e => {
                const newValue = JSON.parse(JSON.stringify(value));
                newValue[i] = {
                  type: e.target.value,
                  username: item.username,
                };
                onChange(newValue);
              }}
              value={item.type}
              textSize="sm:text-xs"
            />

            <Input
              name="company-website"
              placeholder={t("main.profileViewTabs.editProfile.form.userName")}
              value={item.username}
              onChange={e => {
                const newValue = JSON.parse(JSON.stringify(value));
                newValue[i] = {
                  type: item.type,
                  username: e,
                };
                onChange(newValue);
              }}
            />
          </div>
          <button
            onClick={() => {
              const newValue = JSON.parse(JSON.stringify(value));
              newValue.splice(i, 1);
              onChange(newValue);
            }}
            className="flex items-center pr-2 pt-2"
          >
            <MinusCircleIcon className="size-6 text-red-600" />
          </button>
        </div>
      ))}
      <button
        className="mt-2 grid w-full grid-cols-[auto,20px] items-center rounded-2xl border border-gray-300 px-3 py-1.5 shadow-sm hover:bg-zinc-100"
        onClick={() => {
          const newValue = JSON.parse(JSON.stringify(value));
          newValue.push({ type: types[0].value, username: "" });
          onChange(newValue);
        }}
      >
        <div className="text-center text-sm">Neuen Link hinzufügen</div>
      </button>
    </div>
  );
};

export default ProfileEditSocialsContainer;
