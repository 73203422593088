import { Capacitor } from "@capacitor/core";
import { AppContext } from "@src/App";
import sidebar from "@src/api/me/sidebar";
import Panel from "@src/components/box/Panel";
import MyProfileWidget from "@src/components/elements/MyProfileWidget";
import UserDetailsWidgetWrapper from "@src/components/elements/UserDetailsWidgetWrapper";
import WatchThumbnailList from "@src/components/elements/posts/watch/WatchThumbnailList";
import AppLink from "@src/components/elements/shared/AppLink";
import AppQRCode from "@src/components/elements/shared/QRCode";
import SideBarUserElement from "@src/components/elements/sidebar/SideBarUserElement";
import Suggestions from "@src/components/elements/sidebar/suggestions/Suggestions";
import Footer from "@src/components/elements/sidebar/widgets/Footer";
import Trends from "@src/components/elements/sidebar/widgets/Trends";
import CreateFormButton from "@src/components/nav/CreateFormButton";
import NavBar from "@src/components/nav/NavBar";
import NavBarBottom from "@src/components/nav/NavBarBottom";
import News from "@src/pages/bookmarks/tabcontent/News";
import { useAppSelector } from "@src/state/hooks";
import usePopupModal from "@src/state/modal/usePopupModal";
import useUserBubbleModal from "@src/state/modal/useUserBubbleModal";
import isMobile from "@src/utils/isMobile";
import { SidebarType } from "@src/utils/types/SidebarType";
import { User } from "@src/utils/user";
import { useQuery } from "@tanstack/react-query";
import { TFunction } from "i18next";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Outlet } from "react-router-dom";

type Props = {
  topNavHidden?: boolean;
  darkHeader?: boolean;
  darkFooter?: boolean;
  borderless?: boolean;
  fullscreen?: boolean;
};

const MainWrapper = ({ ...props }: Props) => {
  const { user } = useAppSelector(state => state.user);
  const { t } = useTranslation();
  const appContext = useContext(AppContext);
  const [isScrolledDown, setIsScrolledDown] = useState(false);
  const popupModal = usePopupModal();
  const userBubbleModal = useUserBubbleModal();

  const sidebarResult = useQuery({
    queryKey: ["sidebar"],
    queryFn: () => sidebar(),
    enabled: !!user,
  });
  const sidebarConfig = (t: TFunction, user: User | null) => {
    if (user !== null && user !== undefined) {
      return {
        left: [
          {
            key: "person_suggestions",
            title: t("main.userSuggestions.title"),
            link: "/kontakte/für_dich/personen",
          },
          {
            key: "company_suggestions",
            title: t("main.companySuggestions.title"),
            link: "/kontakte/für_dich/firmen",
          },
          {
            key: "group_suggestions",
            title: t("main.groupSuggestions.title"),
            link: "/gruppen/für_dich",
          },
          user.type === "person" && {
            key: "birthdays",
            title: t("main.birthdays.title"),
            link: "/kontakte/meine_kontakte/geburtstage",
            login: true,
            showBirthday: true,
          },
          {
            key: "managed_pages",
            title: t("main.managedPages.title"),
            link: "",
            login: true,
          },
        ].filter(Boolean) as {
          key: keyof SidebarType;
          title: string;
          link: string;
          login?: boolean;
          showBirthday?: boolean;
        }[],
        right: [
          {
            key: "new_users",
            title: t("main.new.title"),
            link: "/kontakte/für_dich/alle",
          },
          {
            key: "visitors",
            title: t("main.visitors.title"),
            link: "/kontakte/meine_kontakte/statistik/besucher",
            login: true,
          },
          {
            key: "my_followers",
            title: t("main.myFollowers.title"),
            link: "/kontakte/follower",
            login: true,
          },
          user.type === "person" && {
            key: "my_friends",
            title: t("main.myFriends.title"),
            link: "/kontakte/meine_kontakte/übersicht",
            login: true,
          },
          {
            key: "last_visited",
            title: t("main.lastSeen.title"),
            link: "",
            login: true,
          },
        ].filter(Boolean) as {
          key: keyof SidebarType;
          title: string;
          link: string;
          login?: boolean;
          showBirthday?: boolean;
        }[],
      };
    } else {
      return {
        left: [],
        right: [],
      };
    }
  };

  function scrollMain({ e, delta = 0 }: { e: React.UIEvent<HTMLElement>; delta?: number }) {
    if (!e.currentTarget) return;
    if (delta === 0) return;
    setIsScrolledDown(delta > 0);
    userBubbleModal.close();
  }

  if (Capacitor.isNativePlatform()) {
    return (
      <div className={"mt-[calc(20px+env(safe-area-inset-top))]"}>
        <img src="/logo.gif" alt="TRADEFOOX" className="mb-8" />
        <div className="px-5 text-center">
          <h1>{t("main.maintained")}</h1>
          <div className="flex flex-col items-center space-y-5 pt-10">
            <a href="https://apps.apple.com/de/app/tradefoox/id6477870055">
              <img src="/images/iOS.png" alt="iOS" className="mb-2" />
              <p>iOS</p>
            </a>
            <a href="https://play.google.com/store/apps/details?id=app.tradefoox.mobile&gl=DE">
              <img src="/images/android.png" alt="android" className="mb-2" />
              <p>Android</p>
            </a>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`mb-[env(safe-area-inset-bottom)] mt-[env(safe-area-inset-top)] grid grid-rows-[50px,calc(calc(100dvh-90px)-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom))),40px] xl:grid-rows-[50px,calc(calc(100dvh-50px)-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))] ${(appContext?.fullscreen || appContext?.topNavHidden || props?.topNavHidden || (isMobile && isScrolledDown) || props.fullscreen) && "grid grid-rows-[calc(100dvh-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))] md:grid-rows-[50px,calc(calc(100dvh-50px)-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))]"} ${appContext?.darkTheme ? "max-md:bg-neutral-900 max-md:text-white" : "bg-white text-black"}`}
    >
      <div
        className={`opacity-100 duration-500 ease-in-out ${(appContext?.fullscreen || appContext?.topNavHidden || props?.topNavHidden || props.fullscreen) && "max-md:hidden"} ${isMobile && isScrolledDown && "pointer-events-none absolute z-30 w-screen opacity-0 duration-500 ease-in-out"}`}
      >
        <NavBar />
      </div>

      <div className="relative">
        {!appContext?.sidebarHidden && (
          <aside className="absolute left-1/2 top-0 max-h-[calc(calc(100dvh-90px)-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))] -translate-x-[calc(50%+475px)] overflow-y-scroll scrollbar-none max-xl:hidden xl:max-h-[calc(calc(100dvh-50px)-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))]">
            <div className="mx-auto hidden w-[300px] grid-flow-row auto-rows-max gap-4 p-4 lg:grid">
              <Panel key="sidebar-my-profile-panel" title={t("main.panel.profile")}>
                <MyProfileWidget />
              </Panel>
              <CreateFormButton key="create-form-button" />
              <Suggestions key="suggestions" />
              {sidebarConfig(t, user).left.map(
                item =>
                  (user || !item.login) && (
                    <SideBarUserElement
                      title={item.title}
                      key={item.key}
                      users={sidebarResult.data?.data[item.key] as User[] | undefined}
                      link={item.link}
                      showBirthday={item.showBirthday}
                    />
                  ),
              )}
            </div>
          </aside>
        )}

        <main
          className={`flex w-full justify-center ${props.fullscreen || appContext?.fullscreen || (isMobile && isScrolledDown) ? "h-screen max-h-[calc(100dvh-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))] overflow-y-scroll scrollbar-thin" : "h-full max-h-[calc(calc(100dvh-40px)-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))] overflow-y-scroll scrollbar-thin xl:max-h-[calc(calc(100dvh-50px)-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))]"}`}
          onScroll={e => {
            scrollMain({ e: e });
          }}
          onWheel={e => {
            scrollMain({ e: e, delta: e.deltaY });
          }}
        >
          <div
            className={`grid w-full grid-flow-row auto-rows-max grid-cols-1 gap-3 md:max-w-[600px] xl:w-[600px] ${props.borderless ? "p-0" : "max-md:p-4 md:py-4"}`}
          >
            <Outlet />
          </div>
        </main>

        {!appContext?.sidebarHidden && (
          <aside className="absolute right-1/2 top-0 max-h-[calc(calc(100dvh-90px)-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))] translate-x-[calc(50%+475px-8px)] overflow-y-scroll scrollbar-none max-xl:hidden xl:max-h-[calc(calc(100dvh-50px)-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))]">
            <div className="mx-auto hidden w-[300px] grid-flow-row auto-rows-max gap-4 p-4 xl:grid">
              <Trends key="trends" />
              {sidebarConfig(t, user).right.map(
                item =>
                  (user || !item.login) && (
                    <SideBarUserElement
                      title={item.title}
                      key={item.key}
                      users={sidebarResult.data?.data[item.key] as User[] | undefined}
                      link={item.link}
                    />
                  ),
              )}
              {user && (
                <Panel title={t("main.bookmarks.title")}>
                  <div className="grid grid-cols-1 gap-2">
                    <News limit={6} columnLimit={3} />
                    <NavLink
                      to={"bookmarks/feed"}
                      className="mt-3 w-full rounded-full bg-gray-200 py-1 text-center text-xs hover:bg-gray-300"
                    >
                      {t("buttons.showMore")}
                    </NavLink>

                    <WatchThumbnailList bookmarked={true} limit={6} columnLimit={3} />
                    <NavLink
                      to={"bookmarks/watch"}
                      className="mt-3 w-full rounded-full bg-gray-200 py-1 text-center text-xs hover:bg-gray-300"
                    >
                      {t("buttons.showMore")}
                    </NavLink>
                  </div>
                </Panel>
              )}
              <AppQRCode />
              <AppLink />
              <Footer />
            </div>
          </aside>
        )}
      </div>
      <div
        className={`opacity-100 duration-500 ease-in-out ${(appContext?.fullscreen || appContext?.bottomNavHidden || props.fullscreen) && "max-md:hidden"} ${isMobile && isScrolledDown && "pointer-events-none absolute bottom-0 z-30 w-screen opacity-0 duration-500 ease-in-out"}`}
      >
        <NavBarBottom dark={props.darkFooter} />
      </div>
      {popupModal.stack.length > 0 && popupModal.stack[0]}
      <UserDetailsWidgetWrapper />
    </div>
  );
};

export default MainWrapper;
