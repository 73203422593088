import { HeartIcon } from "@heroicons/react/24/outline";
import jobs from "@src/api/jobs/jobs";
import { formatDateDifference } from "@src/components/elements/PostWidget";
import { useInfiniteQueryOnScroll } from "@src/hooks/useInfiniteQueryOnScroll";
import { useAppSelector } from "@src/state/hooks";
import { JobType } from "@src/utils/types/JobType";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

type Props = {
  searchQuery?: string;
  tab?: string;
};

const JobsHomepage = ({ searchQuery, tab }: Props) => {
  const { t } = useTranslation();
  const { user } = useAppSelector(state => state.user);
  const queryParams = {
    user_id: tab === "my_jobs" ? user?.id : undefined,
    q: searchQuery,
    filter: tab,
  };

  const { result: jobsResult } = useInfiniteQueryOnScroll({
    initialPageParam: 0,
    queryKey: ["jobs", searchQuery, tab],
    queryFn: ({ pageParam }) => jobs({ params: { ...queryParams, page: pageParam } }),
    getNextPageParam: lastPage => {
      const nextPage = lastPage.current_page + 1;
      return nextPage <= lastPage.total_pages ? nextPage : null;
    },
  });

  if (!jobsResult.isSuccess) return <></>;

  return (
    <>
      <div className="mb-5 flex flex-row">
        <img
          src={"/images/icons/briefcase.svg"}
          className="mb-1 mr-1 flex size-[35px]"
          alt="icon"
        />
        <div className="mt-1 flex flex-col leading-none">
          <div className="font-semibold tracking-tight text-blue-950 dark:text-white">
            {t("main.jobsTabs.jobRecommendationTitle")}
          </div>
          <div className="text-xs">{t("main.jobsTabs.jobRecommendationDescription")}</div>
        </div>
      </div>

      <div className="mb-10 grid gap-4 md:grid-cols-2 lg:grid-cols-5">
        {jobsResult.data.pages.map(page =>
          page.data.map((job: JobType) => {
            let demoProfilePhoto;
            if (job.user.type === "company") {
              demoProfilePhoto = "/images/placeholder/company.png";
            } else if (job.user.type === "club") {
              demoProfilePhoto = "/images/placeholder/club.png";
            } else {
              demoProfilePhoto = "/images/placeholder/person.png";
            }
            return (
              <NavLink to={"/jobs/" + job.id} key={job.id}>
                <div className="rounded-lg border border-gray-300 bg-white p-2 shadow transition-transform duration-300 md:hover:scale-105 dark:border-gray-700 dark:bg-gray-800">
                  <div className="mb-3 flex items-center justify-between">
                    <div className="mr-1 h-[50px] flex-1 overflow-hidden">
                      <div className="text-xs font-medium leading-tight text-gray-900">
                        {job.title}
                      </div>
                    </div>
                    <div className="flex size-[50px] shrink-0 items-center justify-center rounded-lg border border-gray-300">
                      {job.user.avatar ? (
                        <img
                          className="size-full rounded-lg object-cover"
                          src={job.user.avatar}
                          alt=""
                        />
                      ) : (
                        <img
                          className="size-full rounded-lg object-cover"
                          src={demoProfilePhoto}
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                  <div className="mb-2 flex flex-col">
                    <div className="m-0.5 flex flex-row">
                      <img
                        src={"/images/icons/building-office-2.svg"}
                        className="mr-1 flex size-[12px]"
                        alt="icon"
                      />
                      <span className="truncate text-mini text-gray-700 dark:text-gray-400">
                        {job.company}
                      </span>
                    </div>
                    <div className="m-0.5 flex flex-row">
                      <img
                        src={"/images/icons/map-pin.svg"}
                        className="mr-1 flex size-[12px]"
                        alt="icon"
                      />
                      <span className="text-mini text-gray-700 dark:text-gray-400">
                        {job.state}
                      </span>
                    </div>
                    <div className="m-0.5 flex flex-row">
                      <img
                        src={"/images/icons/home.svg"}
                        className="mr-1 flex size-[12px]"
                        alt="icon"
                      />
                      <span className="text-mini text-gray-700 dark:text-gray-400">
                        {t("main.jobsTabs.homeOffice")}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-row items-center">
                    <span className="text-xs text-gray-500">
                      {formatDateDifference(new Date(), new Date(job.created_at))}
                    </span>
                    <HeartIcon className="ml-auto mt-1 h-5 text-gray-500" />
                  </div>
                </div>
              </NavLink>
            );
          }),
        )}
      </div>
    </>
  );
};

export default JobsHomepage;
