import { ReactNode } from "react";

type Props = {
  title?: string;
  children: ReactNode;
  padding?: boolean;
  key?: string;
  mobileBorder?: boolean;
  noBorder?: boolean;
  transparent?: boolean;
  dark?: boolean;
};

const Panel: React.FC<Props> = ({
  title,
  children,
  padding = true,
  mobileBorder = true,
  dark = false,
  noBorder,
  transparent,
}: Props): JSX.Element => (
  <div
    className={`relative rounded-2xl lg:border lg:border-gray-100 ${padding && "p-3"} ${mobileBorder && "border border-gray-100"} ${dark && "border border-black bg-black"} ${noBorder && "border-hidden"} ${transparent ? "bg-transparent" : "bg-white"}`}
  >
    {title && (
      <div className="mb-4 flex w-full flex-row items-center gap-2 border-b pb-1 text-base font-semibold">
        {title}
      </div>
    )}
    {children}
  </div>
);

export default Panel;
