import { Cog6ToothIcon, PencilIcon, UserIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  Cog6ToothIcon as Cog6ToothIconSolid,
  PencilIcon as PencilIconSolid,
  UserIcon as UserIconSolid,
} from "@heroicons/react/24/solid";
import me from "@src/api/auth/me";
import UserWidget from "@src/components/elements/UserWidget";
import UserQRCode from "@src/components/elements/shared/UserQRCode";
import Footer from "@src/components/elements/sidebar/widgets/Footer";
import NavSideLink from "@src/components/nav/NavSideLink";
import NavSideUser from "@src/components/nav/NavSideUser";
import { useAppSelector } from "@src/state/hooks";
import getName from "@src/utils/getName";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";

type Props = {
  isMobile?: boolean;
  onClose?: () => void;
};

export const MyProfileWidget = ({ isMobile = false, onClose }: Props) => {
  const { user } = useAppSelector(state => state.user);
  const [linkIndex, setLinkIndex] = useState("");
  const { t } = useTranslation();
  const location = useLocation();
  const bottomlinks = [
    {
      href: "",
      label: t("main.panel.settings"),
      icon: <Cog6ToothIcon className="size-4" />,
      solidIcon: <Cog6ToothIconSolid className="size-4" />,
      style: "xl:hidden w-full bg-white",
      reverse: true,
      children: [
        {
          href: "/verwaltung/profile",
          label: t("main.panel.profileWidgets.editProfile"),
          icon: <PencilIcon className="size-4" />,
          solidIcon: <PencilIconSolid className="size-4" />,
        },
        {
          href: "/verwaltung/account",
          label: t("main.panel.profileWidgets.account"),
          icon: <UserIcon className="size-4" />,
          solidIcon: <UserIconSolid className="size-4" />,
        },
      ],
    },
  ];

  const userResult = useQuery({
    queryKey: ["me"],
    queryFn: () => me(),
    enabled: !!user,
  });

  const userData = userResult.data?.data;

  return (
    <div className="flex h-full flex-col gap-2">
      {isMobile && (
        <button
          onClick={() => {
            setLinkIndex("");
            if (onClose) onClose();
          }}
          className="absolute right-2"
        >
          <XMarkIcon className="h-[25px]" />
        </button>
      )}
      <div className="grid grid-flow-row gap-2 text-center">
        <div className="line-clamp-2 flex-wrap">
          {t("components.shared.hello")}
          {userData && getName(userData) && ", " + getName(userData)}
        </div>
        {user && (
          <>
            <button
              onClick={() => {
                setLinkIndex("");
                if (onClose) onClose();
              }}
            >
              <UserWidget user={user} follow={false} online={true} />
            </button>
            <div className="mt-2 flex flex-row justify-center gap-4 text-xs">
              <NavLink
                to={"/profile/" + user.id + "/likes"}
                state={{
                  backlink: location.state?.backlink ?? location.pathname,
                }}
              >
                {t("main.panel.likes", { likes: user.like_count })}
              </NavLink>
              <NavLink
                to={"/profile/" + user.id + "/follows"}
                state={{
                  backlink: location.state?.backlink ?? location.pathname,
                }}
              >
                {t("main.panel.followers", {
                  followers: user.follower_count,
                })}
              </NavLink>
              <NavLink
                to={"/profile/" + user.id + "/following"}
                state={{
                  backlink: location.state?.backlink ?? location.pathname,
                }}
              >
                {t("main.panel.following", {
                  following: user.following_count,
                })}
              </NavLink>
            </div>
          </>
        )}
        <div
          className={`scrollbar-none max-xl:overflow-y-scroll ${user && isMobile && linkIndex ? "max-xl:max-h-[calc(calc(100dvh-300px)-env(safe-area-inset-bottom))]" : "max-xl:max-h-[calc(calc(100dvh-400px)-env(safe-area-inset-bottom))]"}`}
        >
          <NavSideUser
            isMobile={isMobile}
            onClose={onClose}
            linkIndex={linkIndex}
            setLinkIndex={newIndex => {
              setLinkIndex(newIndex);
            }}
          />
        </div>
      </div>
      {user && isMobile && linkIndex === "" && (
        <div>
          <div className="mx-auto flex flex-col items-center">
            <UserQRCode user={user} />
          </div>
          <div className="xl:hidden">
            <Footer fontStyle="font-thin" />
          </div>
        </div>
      )}

      <div className="mt-auto w-full bg-neutral-900">
        {bottomlinks.map(link => {
          return (
            <NavSideLink
              link={link}
              key={link.href}
              isMobile={isMobile}
              onClose={onClose}
              linkIndex={linkIndex}
              setLinkIndex={newIndex => {
                setLinkIndex(newIndex);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};
export default MyProfileWidget;
