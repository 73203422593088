import { ChatBubbleOvalLeftIcon, HeartIcon } from "@heroicons/react/24/outline";
import { HeartIcon as HeartIconSolid } from "@heroicons/react/24/solid";
import like from "@src/api/comments/like";
import unlike from "@src/api/comments/unlike";
import { formatDateDifference } from "@src/components/elements/PostWidget";
import UserWidget from "@src/components/elements/UserWidget";
import CreatePostComment from "@src/components/elements/posts/comments/CreatePostComment";
import TextView from "@src/components/elements/textedit/TextView";
import SharePopup from "@src/components/popup/SharePopup";
import CommentsDropdown from "@src/pages/news/CommentsDropdown";
import PostsActionBarItem from "@src/pages/news/posts/PostsActionBarItem";
import { useAppSelector } from "@src/state/hooks";
import useLoginModal from "@src/state/modal/useLoginModal";
import getName from "@src/utils/getName";
import { CommentType } from "@src/utils/types/CommentType";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  level?: number;
  comment: CommentType;
  comments: CommentType[];
  viewCount?: number;
  postId: string;
  key?: string;
  parentId?: string;
};
const PostComment = ({ comment, ...props }: Props) => {
  const { user } = useAppSelector(state => state.user);
  const { t } = useTranslation();
  const [answer, setAnswer] = useState<boolean>(false);
  const [sharePopupOpen, setSharePopupOpen] = useState<boolean>(false);
  const [showReplies, setShowReplies] = useState<boolean>(false);
  const loginModal = useLoginModal();
  const [likeEffect, setLikeEffect] = useState(false);
  const queryClient = useQueryClient();

  const likeMutation = useMutation({
    mutationKey: ["comments", "like", comment.id, user?.id ?? ""],
    mutationFn: like,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["comments"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const unlikeMutation = useMutation({
    mutationKey: ["comments", "unlike", comment.id, user?.id ?? ""],
    mutationFn: unlike,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["comments"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const checkAuth = () => {
    if (!user) {
      loginModal.open();
      return false;
    }
    return true;
  };

  return (
    <>
      <div className="group/comment relative flex gap-3">
        <UserWidget user={comment.author} size={30} />
        <div className="w-[calc(100%-42px)]">
          <div className={"rounded-xl border-2 border-lightgray bg-lightgray px-1.5 py-1"}>
            <div className="flex w-full gap-1">
              <div
                className="truncate text-sm font-semibold text-lightblue"
                style={{ maxWidth: "calc(100% - 150px)" }}
              >
                {getName(comment.author)}
              </div>
              <div className="shrink-0 text-xs leading-5 text-gray-600">
                - {formatDateDifference(new Date(), new Date(comment.created_at))}
              </div>
              <div className="ml-auto">
                <CommentsDropdown comment={comment} />
              </div>
            </div>
            <div className="whitespace-pre-wrap break-all text-xs">
              <TextView value={comment.text} disableTopMargin hashtagType="feed" />
            </div>

            <div className="relative mt-1 flex flex-row justify-between gap-4 text-darkblue">
              <PostsActionBarItem
                onClick={() => checkAuth() && setAnswer(value => !value)}
                label={t("components.posts.answer")}
                count={comment.comment_count}
              >
                <ChatBubbleOvalLeftIcon />
              </PostsActionBarItem>
              <PostsActionBarItem
                label={t("main.contacts.tabs.likes")}
                count={comment.like_count}
                onClick={() =>
                  comment.liked
                    ? unlikeMutation.mutate({ id: comment.id })
                    : likeMutation.mutate({ id: comment.id })
                }
                textColor={
                  comment.liked
                    ? "text-red-600"
                    : "text-gray-600 transition-all ease-out duration-200 group-hover:text-red-600"
                }
                color={comment.liked ? "bg-pink-100" : "group-hover:bg-pink-100"}
              >
                {comment.liked ? (
                  <HeartIconSolid className={`${likeEffect && "animate-ping-once"}`} />
                ) : (
                  <HeartIcon
                    onClick={() => {
                      setLikeEffect(true);
                    }}
                    onAnimationEnd={() => {
                      setLikeEffect(false);
                    }}
                  />
                )}
              </PostsActionBarItem>

              {sharePopupOpen && (
                <SharePopup
                  postId={props.postId}
                  content={{
                    title: "News",
                    body: new URL(`/news/${props.postId}`, window.location.href).toString(),
                  }}
                  onClose={() => setSharePopupOpen(false)}
                />
              )}
            </div>
          </div>
          {!showReplies && comment.comment_count !== 0 && (
            <div
              aria-hidden
              className="mt-1 flex cursor-pointer text-sm"
              onClick={() => setShowReplies(true)}
            >
              <ChatBubbleOvalLeftIcon className="size-5 px-1" />
              <span>
                {comment.comment_count} {t("components.posts.answer")}
              </span>
            </div>
          )}
        </div>
      </div>
      {answer && (
        <CreatePostComment
          parentId={props.level && props.level > 1 ? props.parentId : comment.id}
          answer={comment}
          postId={props.postId}
          onSuccess={() => setAnswer(false)}
        />
      )}
      {showReplies && (
        <div className="grid grid-cols-1 gap-3 pl-10">
          {props.comments.map(
            child =>
              child.parent_id === comment.id && (
                <PostComment
                  viewCount={props.viewCount}
                  comments={props.comments}
                  level={props.level != undefined ? props.level + 1 : undefined}
                  postId={props.postId}
                  key={child.id}
                  parentId={comment.id}
                  comment={child}
                />
              ),
          )}
        </div>
      )}
    </>
  );
};

export default PostComment;
