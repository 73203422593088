import { HeartIcon, ShoppingCartIcon } from "@heroicons/react/24/outline";

interface Product {
  title: string;
  imgUrl: string;
  price: string;
  MRP: string;
  ID: string;
  category: string;
  subCategory: string;
  description?: string;
}

interface ProductProps {
  product: Product;
}

const CartSimilarProducts: React.FC<ProductProps> = ({ product }: ProductProps) => {
  return (
    <div className="mb-1 space-y-3 overflow-hidden rounded-lg border border-gray-300 bg-white p-4 shadow-sm dark:border-gray-700 dark:bg-gray-800">
      <a href="#" className="overflow-hidden rounded-lg">
        <img className="mx-auto max-h-32" src={product.imgUrl} alt={product.title} />
      </a>
      <div className="h-12 overflow-hidden">
        <a href="/marketplace/01fc5aed-d880-460b-bd87-a1cecec3502f" className="hover:underline">
          <h3 className="mb-2 h-12 overflow-hidden text-xs font-medium">{product.title}</h3>
        </a>
      </div>
      <div className="h-12 overflow-hidden">
        <p className="text-mini font-normal text-gray-500 dark:text-gray-400">
          {product.description}
        </p>
      </div>

      <div className="flex flex-row">
        <p className="mr-1.5 text-lg font-bold leading-tight text-red-600 dark:text-red-500">
          {product.price}
        </p>
        <p className="text-xs font-bold text-gray-900 dark:text-white">
          <span className="line-through">€{product.MRP} </span>
        </p>
      </div>
      <div className="mt-6 flex justify-between">
        <button
          data-tooltip-target="favourites-tooltip-3"
          type="button"
          className="inline-flex items-center justify-center gap-2 rounded-lg border border-gray-300 bg-white p-2.5 text-sm font-medium text-gray-900 hover:bg-gray-100 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700"
        >
          <HeartIcon className="size-5" />
        </button>
        <button
          data-tooltip-target="favourites-tooltip-3"
          type="button"
          className="inline-flex items-center justify-center gap-2 rounded-lg border border-gray-300 bg-white p-2.5 text-sm font-medium text-gray-900 hover:bg-gray-100 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700"
        >
          <ShoppingCartIcon className="size-5" />
        </button>
      </div>
    </div>
  );
};

export default CartSimilarProducts;
