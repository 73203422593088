type Props = {
  src: string;
  className?: string;
  height?: string;
  width?: string;
  allow?: string;
  key?: string;
};

const YoutubeFrame = ({
  allow = "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
  width = "100%",
  height = "300px",
  ...props
}: Props) => {
  return (
    <iframe allow={allow} width={width} height={height} {...props} title={props.src} aria-hidden />
  );
};

export default YoutubeFrame;
