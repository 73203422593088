import { NavLink, useLocation } from "react-router-dom";

type Props = {
  user: { id: string; name: string; avatar: string; type: string };
  nameStyle?: string;
};

const UserWidget = ({ user, nameStyle }: Props) => {
  const placeholder = `/images/placeholder/${user.type}.png`;
  const location = useLocation();

  return (
    <NavLink
      to={`/profile/${user.id}`}
      state={{ backlink: location.state?.backlink ?? location.pathname }}
    >
      <div className="relative w-full cursor-pointer">
        <div
          className="relative shrink-0 rounded-full border border-gray-300 bg-white"
          style={{
            width: "50px",
            height: "50px",
          }}
        >
          <div className="flex size-full items-center justify-center overflow-hidden rounded-full">
            <img className="w-full" src={user.avatar || placeholder} alt={user.name} />
          </div>
        </div>
        <div className="mt-2 flex w-full items-center justify-center gap-1 truncate whitespace-nowrap text-left text-xs text-gray-600">
          <div className={`truncate ${nameStyle}`}>{user.name}</div>
        </div>
      </div>
    </NavLink>
  );
};

export default UserWidget;
