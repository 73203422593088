import { CalendarDaysIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Panel from "@src/components/box/Panel";
import Button from "@src/components/elements/input/Button";
import { formatDate } from "@src/utils/formatDate";
import moment from "moment";
import "moment/locale/de";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  onClose: () => void;
  onChange: (date: number | undefined) => void;
  selectedDate?: number;
  dark?: boolean;
};
const Schedule = ({ onClose, onChange, selectedDate, dark }: Props) => {
  const [date, setDate] = useState(selectedDate ?? Date.now() + 900000);
  const year = new Date(date).getFullYear();
  const month = new Date(date).getMonth();
  const day = new Date(date).getDate();
  const hours = new Date(date).getHours();
  const minutes = new Date(date).getMinutes();
  const maxDays = new Date(year, month, 0).getDate();
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const { t } = useTranslation();

  return (
    <Panel mobileBorder={false} dark={dark}>
      <div className={`grid grid-flow-row gap-2 text-sm ${dark && "bg-black text-white"}`}>
        <div className="flex flex-row items-center gap-8">
          <button onClick={onClose}>
            <XMarkIcon className="size-8 rounded-full p-1.5 hover:bg-gray-200" />
          </button>
          <div className="text-base font-semibold">{t("components.shared.plan")}</div>
          <div className="ml-auto flex flex-row items-center gap-4">
            {selectedDate && (
              <button
                className="border-b-2 border-b-gray-800 text-sm"
                onClick={() => {
                  onChange(undefined);
                  onClose();
                }}
              >
                {t("components.shared.remove")}
              </button>
            )}
            <div className="w-fit">
              <Button
                variant={Button.Variants.Primary}
                size={Button.Sizes.Small}
                onClick={() => {
                  onChange(date);
                  onClose();
                }}
              >
                {selectedDate
                  ? `${t("components.shared.update")}`
                  : `${t("components.shared.confirm")}`}
              </Button>
            </div>
          </div>
        </div>
        {selectedDate && (
          <div className="flex flex-row items-center gap-2 text-xs">
            <CalendarDaysIcon className="size-4" />
            {t("main.watch.create.publishAt")} {formatDate(new Date(selectedDate).toISOString())}.
          </div>
        )}
        <div>{t("components.shared.date")}</div>
        <div className="flex flex-row gap-3">
          <select
            name="day"
            onChange={e => {
              setDate(new Date(year, month, Number(e.target.value), hours, minutes).getTime());
            }}
            value={day}
            className={`${dark && "text-black"}`}
          >
            {Array(maxDays)
              .fill(0)
              .map((_e, i) => {
                return <option key={i + 1}>{i + 1}</option>;
              })}
          </select>
          <select
            name="month"
            onChange={e => {
              setDate(new Date(year, Number(e.target.value), day, hours, minutes).getTime());
            }}
            value={month}
            className={`${dark && "text-black"}`}
          >
            {moment.months().map((e, i) => {
              return (
                <option key={e} value={i}>
                  {e}
                </option>
              );
            })}
          </select>
          <select
            name="year"
            onChange={e => {
              setDate(new Date(Number(e.target.value), month, day, hours, minutes).getTime());
            }}
            value={year}
            className={`${dark && "text-black"}`}
          >
            {Array(3)
              .fill(0)
              .map((e, i) => {
                return (
                  <option key={e} value={Number(new Date().getFullYear()) + i}>
                    {Number(new Date().getFullYear()) + i}
                  </option>
                );
              })}
          </select>
        </div>
        <div>{t("components.shared.time")}</div>
        <div className="flex flex-row gap-3">
          <input
            type="time"
            name="releasetime"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const newDate = new Date(date);
              const [hours, minutes] = e.target.value.split(":");
              newDate.setHours(parseInt(hours));
              newDate.setMinutes(parseInt(minutes));
              setDate(newDate.getTime());
            }}
            className={`p-2 ${dark && "text-black"}`}
            value={new Date(date).toLocaleTimeString("de-DE")}
          />
        </div>
        <div>
          <div>{t("components.shared.timezone")}</div>
          <div className="font-semibold">{timeZone}</div>
        </div>
      </div>
    </Panel>
  );
};

export default Schedule;
