import { ChevronLeftIcon, ChevronRightIcon, PlayIcon } from "@heroicons/react/24/solid";
import { MediaType } from "@src/utils/types/MediaType";
import { ReactNode, useRef, useState } from "react";

const Video = ({ media }: { media: MediaType }) => (
  <div className="aspect-w-4 aspect-h-5 relative size-full">
    <img
      src={`${media.data_url}/thumbnail.jpg?height=600&width=600&fit=crop`}
      className="absolute left-0 top-0 size-full object-cover"
      alt="video thumbnail"
    />
    <PlayIcon className="absolute inset-0 m-auto size-16 text-white" />
  </div>
);

const Image = ({ media }: { media: MediaType }) => {
  return (
    <div className="aspect-w-4 aspect-h-5 relative size-full">
      <img
        src={`${media.data_url}?height=600&width=600&fit=crop`}
        className="absolute left-0 top-0 size-full object-cover"
        alt="image"
      />
    </div>
  );
};

type Props = {
  onClick: () => void;
  media: MediaType[];
  hideBorder?: boolean;
  hideMargin?: boolean;
  children?: ReactNode;
};

const PostGallery = ({ onClick, media, hideBorder, children, hideMargin }: Props) => {
  const [selectedMedia, setSelectedMedia] = useState(0);
  const galleryRef = useRef<HTMLDivElement>(null); // To hold the reference to the scrollable div

  return (
    <div className="relative">
      <div
        ref={galleryRef}
        className={`relative flex max-w-full snap-x snap-mandatory flex-nowrap overflow-x-auto scrollbar-none ${hideBorder ? "border-none" : "rounded-2xl border border-gray-400"} ${!hideMargin && "mt-3"}`}
        onScroll={e => {
          const index = Math.round(e.currentTarget.scrollLeft / e.currentTarget.clientWidth);
          setSelectedMedia(index);
        }}
      >
        {media.map(item => (
          <div
            aria-hidden
            onClick={onClick}
            className="relative h-4/5vw max-h-[500px] w-full flex-none cursor-pointer snap-start"
            key={item.key}
          >
            {item.type === "image" && <Image media={item} />}
            {item.type === "video" && <Video media={item} />}
          </div>
        ))}
      </div>
      {children}
      {selectedMedia !== 0 && (
        <div
          aria-hidden
          className="absolute left-0 top-1/2 flex h-full w-20 -translate-y-1/2 cursor-pointer items-center"
          onClick={() => {
            if (galleryRef.current)
              galleryRef.current.scrollTo((selectedMedia - 1) * galleryRef.current.clientWidth, 0);
          }}
        >
          <div className="group absolute left-0 top-0 z-0 flex size-full items-center justify-center opacity-0 hover:opacity-100">
            <div className="rounded-full bg-white p-2 group-hover:bg-opacity-50">
              <ChevronLeftIcon className="z-50 mx-auto size-[25px]" />
            </div>
          </div>
        </div>
      )}
      {selectedMedia < media.length - 1 && (
        <div
          aria-hidden
          className="absolute right-0 top-1/2 flex h-full w-20 -translate-y-1/2 cursor-pointer items-center"
          onClick={() => {
            if (galleryRef.current)
              galleryRef.current.scrollTo((selectedMedia + 1) * galleryRef.current.clientWidth, 0);
          }}
        >
          <div className="group absolute right-0 top-0 z-0 flex size-full items-center justify-center opacity-0 hover:opacity-100">
            <div className="rounded-full bg-white p-2 group-hover:bg-opacity-50">
              <ChevronRightIcon className="z-50 mx-auto size-[25px]" />
            </div>
          </div>
        </div>
      )}
      {media.length > 1 && (
        <div className="absolute bottom-0 mx-auto my-2 flex w-full justify-center gap-2">
          {media.map((_media, index) => (
            <div
              aria-hidden
              className={`size-[7px] cursor-pointer rounded-full bg-black ${index === selectedMedia && "border border-black bg-white"}`}
              onClick={() => {
                if (galleryRef.current)
                  galleryRef.current.scrollTo(index * galleryRef.current.clientWidth, 0);
              }}
              key={_media.id}
            ></div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PostGallery;
