import Button from "@src/components/elements/input/Button";
import Checkbox from "@src/components/elements/input/Checkbox";
import Input from "@src/components/elements/input/Input";
import AppLink from "@src/components/elements/shared/AppLink";
import BackButton from "@src/components/nav/BackButton";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const Newsletter = () => {
  const [privacyCheck, setPrivacyCheck] = useState(false);
  const [email, setEmail] = useState("");
  const { t } = useTranslation();

  return (
    <>
      <div className="pl-4 pt-4">
        <BackButton stepback desktopVisible />
      </div>
      <section className="relative">
        <img
          src="/images/info/newsletter_2_tradefoox.jpg"
          className="h-[300px] w-full object-cover"
        />
        <div className="absolute left-6 top-1/2 flex -translate-y-1/2 flex-col font-semibold italic">
          <div className="w-fit bg-white/80 px-1 text-2xl text-darkcyan">
            {t("pages.newsletter.text1")}
          </div>
          <div className="ml-4 w-fit bg-darkblue/80 px-1 text-white">
            {t("pages.companyFAQ.tradefoox")}
          </div>
        </div>
      </section>

      <div className="grid grid-flow-row gap-6 pb-4 text-gray-700 max-xl:px-4">
        <article className="grid grid-flow-row gap-3 text-xs">
          <h1 className="text-left text-smbase font-bold">{t("pages.newsletter.text2")}</h1>
          <div className="text-justify">{t("pages.newsletter.text3")}</div>
        </article>
        <form className="text-xs">
          {t("pages.newsletter.text4")}
          <Input
            type="email"
            name="email"
            placeholder="E-Mail*"
            value={email}
            required
            onChange={e => {
              setEmail(e);
            }}
          />
          <Checkbox name="agb" required checked={privacyCheck} onChange={e => setPrivacyCheck(e)}>
            {t("pages.createAccount.text17")}{" "}
            <NavLink to="/datenschutz" target="noopener _blank" className="text-darkcyan">
              {t("pages.createAccount.text18")}
            </NavLink>{" "}
            {t("pages.createAccount.text19")}
          </Checkbox>
          <Button submit>{t("pages.newsletter.signIn")}</Button>
        </form>

        <AppLink />
        <div className="flex justify-center gap-2">
          <NavLink to="/impressum" className="text-sm">
            {t("main.signIn.imprint")}
          </NavLink>
          <span className="text-sm">|</span>
          <NavLink to="/datenschutz" className="text-sm">
            {t("main.signIn.dataProtection")}
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default Newsletter;
