import { XMarkIcon } from "@heroicons/react/24/outline";
import ExternalLink from "@src/components/elements/shared/ExternalLink";
import { LinkPreviewType } from "@src/utils/types/LinkPreviewType";

type Props = {
  link: LinkPreviewType;
  small?: boolean;
  onDelete?: () => void;
  hideBorder?: boolean;
  hideEdge?: boolean;
};

const LinkPreview = ({ link, small, onDelete, hideBorder, hideEdge }: Props) => {
  const domainFromUrl = (url: string) => {
    // eslint-disable-next-line no-useless-escape
    let match = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im);
    let result;
    if (match) {
      result = match[1];
      // eslint-disable-next-line no-useless-escape
      match = result.match(/^[^\.]+\.(.+\..+)$/);
      if (match) {
        result = match[1];
      }
    }
    return result;
  };

  return (
    <div
      className={`relative w-full overflow-hidden rounded-2xl border border-gray-400 ${hideBorder && "border-none"} ${hideEdge && "rounded-none"}`}
      style={{ wordBreak: "break-word" }}
    >
      {onDelete && (
        <button onClick={onDelete} className="absolute right-3 top-3 z-50">
          <XMarkIcon className="size-6" />
        </button>
      )}
      <ExternalLink to={link.url}>
        {link.image && (
          <div className="relative aspect-video">
            <img
              className="absolute left-0 top-0 size-full object-cover"
              src={link.image}
              alt={link.title}
            />
          </div>
        )}
        <div className={`p-3 text-black ${small && "text-xssm leading-3"}`}>
          {<div className="text-sm uppercase">{domainFromUrl(link.url)}</div>}
          {<div className="line-clamp-1 font-bold">{link.title}</div>}
          {link.description && (
            <span className={`${small && "line-clamp-1"}`}>{link.description}</span>
          )}
        </div>
      </ExternalLink>
    </div>
  );
};

export default LinkPreview;
