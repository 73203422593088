import apiStatistics from "@src/api/users/statistics";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import "chartjs-plugin-datalabels";
import { useEffect, useState } from "react";

import { User } from "@src/utils/user";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface StatisticsData {
  ageCount: Record<number, number>;
  agePercentage: Record<number, number>;
}
type Props = {
  user: User;
};

const Age = ({ user }: Props) => {
  const { t } = useTranslation();
  const [statistics, setStatistics] = useState<StatisticsData | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await apiStatistics({ id: user.id });

        setStatistics(data);
      } catch (error) {
        console.error("Error fetching user statistics", error);
      }
    };

    fetchData();
  }, [user]);
  const ageRanges = {
    "18-24": 0,
    "25-34": 0,
    "35-44": 0,
    "45-54": 0,
    "55+": 0,
  };
  Object.entries(statistics?.ageCount || {}).forEach(([age, count]) => {
    const ageNumber = Number(age);
    if (ageNumber >= 18 && ageNumber <= 24) {
      ageRanges["18-24"] += count;
    } else if (ageNumber >= 25 && ageNumber <= 34) {
      ageRanges["25-34"] += count;
    } else if (ageNumber >= 35 && ageNumber <= 44) {
      ageRanges["35-44"] += count;
    } else if (ageNumber >= 45 && ageNumber <= 54) {
      ageRanges["45-54"] += count;
    } else if (ageNumber >= 55) {
      ageRanges["55+"] += count;
    }
  });
  const totalAgeCount = Object.values(ageRanges).reduce((sum, count) => sum + count, 0);

  const agePercentages = Object.values(ageRanges).map(count => (count / totalAgeCount) * 100);

  const barData = {
    labels: Object.keys(ageRanges),
    datasets: [
      {
        label: "Alter",
        data: agePercentages,
        backgroundColor: "#10A2C5",
        borderColor: "#10A2C5",
        barPercentage: 0.5,
        categoryPercentage: 1,
      },
    ],
  };
  if (!statistics) {
    return <h1>Loading.....</h1>;
  }
  return (
    <div>
      {Object.keys(statistics.ageCount).length > 0 && (
        <div>
          <h1 className="mb-2 text-xl font-bold">
            {t("main.profileViewTabs.editProfile.form.age")}
          </h1>
          <div className="h-72">
            <Bar
              data={barData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  datalabels: {
                    display: false,
                  },
                  legend: {
                    display: false,
                  },
                },
                scales: {
                  y: {
                    beginAtZero: true,
                    ticks: {
                      callback: function (value) {
                        return value + "%";
                      },
                    },
                  },
                  x: {
                    grid: {
                      display: false,
                    },
                  },
                },
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Age;
