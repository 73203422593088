import { PropsWithChildren } from "react";

type Props = {
  subTab?: boolean;
  scrollable?: boolean;
};

const TabMenu = ({ subTab, children, scrollable = true }: PropsWithChildren<Props>) => (
  <div
    className={`mb-3 pb-0.5 ${scrollable && "overflow-x-scroll scrollbar-none"} ${subTab ? "flex justify-start gap-4 text-xs" : "flex justify-between gap-4 text-sm"}`}
  >
    {children}
  </div>
);

export default TabMenu;
