import Input from "@src/components/elements/input/Input";
import TabBodies from "@src/components/elements/shared/tab/TabBodies";
import TabBody from "@src/components/elements/shared/tab/TabBody";
import TabController from "@src/components/elements/shared/tab/TabController";
import TabHeader from "@src/components/elements/shared/tab/TabHeader";
import TabHeaders from "@src/components/elements/shared/tab/TabHeaders";
import MarketplaceCreateContainer from "@src/pages/marketplace/MarketplaceCreateContainer";
import FullPageContainer from "@src/pages/marketplace/fullpage/FullPageContainer";
import BillsContainer from "@src/pages/marketplace/shop/BillsContainer";
import { FollowerContainer } from "@src/pages/marketplace/shop/FollowerContainer";
import LastSeenContainer from "@src/pages/marketplace/shop/LastSeenContainer";
import MyShopContainer from "@src/pages/marketplace/shop/MyShopContainer";
import OrdersContainer from "@src/pages/marketplace/shop/OrdersContainer";
import PurchasesOverviewContainer from "@src/pages/marketplace/shop/PurchasesOverviewContainer";
import RebuyContainer from "@src/pages/marketplace/shop/RebuyContainer";
import ShoppingCartContainer from "@src/pages/marketplace/shop/ShoppingCartContainer";
import StatisticsContainer from "@src/pages/marketplace/shop/StatisticsContainer";
import { TrendingContainer } from "@src/pages/marketplace/shop/TrendingContainer";
import WishlistContainer from "@src/pages/marketplace/shop/WishlistContainer";
import MessengerContainer from "@src/pages/messenger/MessengerContainer";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  mainFilter?: string;
  topTab?: string;
  subTab?: string;
};

const MarketplaceContainer = ({
  mainFilter = "main_marketplace",
  topTab = "shop",
  subTab = "all",
}: Props) => {
  const { t } = useTranslation();
  const [, setSearchQuery] = useState("");

  const getTabLabel = (key: string) => {
    switch (key) {
      case "bills":
        return t("main.marketplace.tabs.bills");

      case "purchases":
        return t("main.marketplace.tabs.purchases");

      case "buyAgain":
        return t("main.marketplace.tabs.buyAgain");

      case "orders":
        return t("main.marketplace.tabs.orders");

      case "lastSeen":
        return t("main.marketplace.tabs.lastSeen");

      case "cart":
        return t("main.marketplace.tabs.cart");

      case "wishList":
        return t("main.marketplace.tabs.wishList");

      case "statistics":
        return t("main.marketplace.tabs.statistics");

      case "trending":
        return t("main.marketplace.tabs.trending");

      case "follower":
        return t("main.marketplace.tabs.follower");

      default:
      case "all":
        return t("main.marketplace.tabs.forYou");
    }
  };

  return (
    <Fragment>
      {mainFilter === "main_my_shop" && (
        <TabController defaultTab={topTab}>
          <TabHeaders>
            <TabHeader id="my_shop" url="/verwaltung/mein_shop/übersicht">
              {t("main.marketplace.tabs.myShop")}
            </TabHeader>
            <TabHeader id="create" url="/verwaltung/mein_shop/verkaufen" hideMobile>
              {t("main.marketplace.tabs.create")}
            </TabHeader>
            <TabHeader id="sells" url="/verwaltung/mein_shop/verkäufe">
              {t("main.marketplace.tabs.sells")}
            </TabHeader>
            <TabHeader id="orders" url="/verwaltung/mein_shop/bestellungen">
              {t("main.marketplace.tabs.orders")}
            </TabHeader>
            <TabHeader id="statistics" url="/verwaltung/mein_shop/statistik">
              {t("main.marketplace.tabs.statistics")}
            </TabHeader>
          </TabHeaders>
          {topTab !== "create" ? (
            <Input
              name="ads-keywords"
              placeholder={t("components.shared.search", {
                tab: getTabLabel(subTab || topTab),
              })}
              onChange={e => setSearchQuery(e)}
              clearable
            />
          ) : (
            <></>
          )}
          <TabBodies>
            <TabBody id="my_shop">
              <TabController defaultTab={subTab} isSubTab>
                <TabHeaders>
                  <TabHeader id="myShop" url="/verwaltung/mein_shop/übersicht">
                    {t("main.marketplace.tabs.overview")}
                  </TabHeader>
                  <TabHeader id="bills" url="/verwaltung/mein_shop/rechnungen">
                    {t("main.marketplace.tabs.bills")}
                  </TabHeader>
                  <TabHeader id="messenger" url="/verwaltung/mein_shop/messenger">
                    {t("main.marketplace.tabs.messages")}
                  </TabHeader>
                  <TabHeader id="templates" url="/verwaltung/mein_shop/vorlagen">
                    {t("main.marketplace.tabs.templates")}
                  </TabHeader>
                  <TabHeader id="paymentMethod" url="/verwaltung/mein_shop/zahlungsmethode">
                    {t("main.marketplace.tabs.newPaymentMethod")}
                  </TabHeader>
                </TabHeaders>
                <TabBodies>
                  <TabBody id="myShop">
                    <MyShopContainer />
                  </TabBody>
                  <TabBody id="bills">
                    <BillsContainer />
                  </TabBody>
                  <TabBody id="messenger">
                    <MessengerContainer />
                  </TabBody>
                  <TabBody id="templates"></TabBody>
                  <TabBody id="paymentMethod">
                    <></>
                  </TabBody>
                </TabBodies>
              </TabController>
            </TabBody>
            <TabBody id="create">
              <MarketplaceCreateContainer />
            </TabBody>
            <TabBody id="sells">
              <></>
            </TabBody>
            <TabBody id="orders">
              <OrdersContainer />
            </TabBody>
            <TabBody id="statistics">
              <StatisticsContainer />
            </TabBody>
          </TabBodies>
        </TabController>
      )}

      {mainFilter === "main_marketplace" && (
        <TabController defaultTab={topTab}>
          <TabHeaders>
            <TabHeader url="/marketplace/für_dich" id="shop">
              {t("main.marketplace.tabs.forYou")}
            </TabHeader>
            <TabHeader url="/marketplace/warenkorb" id="cart">
              {t("main.marketplace.tabs.cart")}
            </TabHeader>
            <TabHeader url="/marketplace/wunschliste" id="wishList">
              {t("main.marketplace.tabs.wishList")}
            </TabHeader>
            <TabHeader url="/marketplace/follower" id="follower">
              {t("main.marketplace.tabs.follower")}
            </TabHeader>
            <TabHeader url="/marketplace/trending" id="trending">
              {t("main.marketplace.tabs.trending")}
            </TabHeader>
          </TabHeaders>
          <Input
            name="ads-keywords"
            placeholder={t("components.shared.search", {
              tab: getTabLabel(subTab || topTab),
            })}
            onChange={e => setSearchQuery(e)}
            clearable
          />
          <TabBodies>
            <TabBody id="shop">
              <TabController isSubTab defaultTab={subTab}>
                <TabHeaders>
                  <TabHeader url="/marketplace/für_dich" id="all">
                    {t("main.marketplace.tabs.overview")}
                  </TabHeader>
                  <TabHeader url="/marketplace/rechnungen" id="bills">
                    {t("main.marketplace.tabs.bills")}
                  </TabHeader>
                  <TabHeader url="/marketplace/kaufübersicht" id="purchases">
                    {t("main.marketplace.tabs.purchases")}
                  </TabHeader>
                  <TabHeader url="/marketplace/wieder_kaufen" id="buyAgain">
                    {t("main.marketplace.tabs.buyAgain")}
                  </TabHeader>
                  <TabHeader url="/marketplace/bestellungen" id="orders">
                    {t("main.marketplace.tabs.orders")}
                  </TabHeader>
                  <TabHeader url="/marketplace/zuletzt_gesehen" id="lastSeen">
                    {t("main.marketplace.tabs.lastSeen")}
                  </TabHeader>
                </TabHeaders>
                <TabBodies>
                  <TabBody id="all">
                    <FullPageContainer />
                  </TabBody>
                  <TabBody id="bills">
                    <BillsContainer />
                  </TabBody>
                  <TabBody id="purchases">
                    <PurchasesOverviewContainer />
                  </TabBody>
                  <TabBody id="buyAgain">
                    <RebuyContainer />
                  </TabBody>
                  <TabBody id="orders">
                    <OrdersContainer />
                  </TabBody>
                  <TabBody id="lastSeen">
                    <LastSeenContainer />
                  </TabBody>
                </TabBodies>
              </TabController>
            </TabBody>
            <TabBody id="cart">
              <ShoppingCartContainer />
            </TabBody>
            <TabBody id="wishList">
              <WishlistContainer />
            </TabBody>
            <TabBody id="follower">
              <FollowerContainer />
            </TabBody>
            <TabBody id="trending">
              <TrendingContainer />
            </TabBody>
          </TabBodies>
        </TabController>
      )}
    </Fragment>
  );
};

export default MarketplaceContainer;
