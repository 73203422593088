import { TabHeaderProps } from "@src/components/elements/shared/tab/TabHeader";
import React, { ReactElement } from "react";
import { NavLink } from "react-router-dom";

export interface TabHeadersProps {
  children: ReactElement<TabHeaderProps>[] | ReactElement<TabHeaderProps>;
  isSubTab?: boolean;
  center?: boolean;
  headerCSS?: string;
  tabCSS?: string;
  hideOnTabs?: string[];
  activeTab?: string;
  setActiveTab?: (id: string) => void;
}

/**
 * Component that contains tab buttons
 *
 * @param activeTab Current active tab
 * @param setActiveTab Setter for tabs
 * @param defaultTab Selected tab by default
 * @param isSubTab Tab menu is using sub tab styles
 * @param center Centers the tab buttons
 * @param headerCSS TW Styles for the tabs container
 * @param tabCSS TW Styles for each individual tab
 * @param hideOnTabs Hide tab bar when a specific tab is selected
 */
const TabHeaders: React.FC<TabHeadersProps> = ({
  children,
  activeTab = "",
  setActiveTab,
  isSubTab,
  center,
  headerCSS,
  tabCSS,
  hideOnTabs,
}: TabHeadersProps) => {
  return (
    <div
      className={`flex gap-4 overflow-x-scroll scrollbar-none ${isSubTab ? "justify-start text-xs" : "justify-between text-sm"} ${center && "mx-auto"} ${headerCSS} ${hideOnTabs && hideOnTabs.includes(activeTab) && "max-xl:hidden"}`}
    >
      {React.Children.map(children, child => {
        if (child.props.isHidden) return <></>;
        return (
          <NavLink
            to={child.props.url ?? ""}
            state={child.props.state}
            className={`relative flex h-fit items-center gap-1 whitespace-nowrap border-b-2 border-darkblue ${child.props.id === activeTab ? "text-gray-900" : "border-transparent text-gray-500"} ${child.props.hideDesktop && "xl:hidden"} ${child.props.hideMobile && "max-xl:hidden"} ${tabCSS}`}
            onClick={e => {
              if (setActiveTab) setActiveTab(child.props.id ?? "");
              e.currentTarget.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "center",
              });
            }}
          >
            {child.props.children}
          </NavLink>
        );
      })}
    </div>
  );
};

export default TabHeaders;
