import feed from "@src/api/feed/feed";
import PostWidget from "@src/components/elements/PostWidget";
import ShowMoreButton from "@src/components/elements/input/ShowMoreButton";
import { useAppSelector } from "@src/state/hooks";
import { nextPost, prevPost } from "@src/utils/post";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useState } from "react";

type Props = {
  searchQuery?: string;
};

const MyFeedContainer = ({ searchQuery }: Props) => {
  const { user } = useAppSelector(state => state.user);
  const [lightboxSelection, setLightboxSelection] = useState("");
  const queryParams = {
    q: searchQuery ?? null,
    featured_post_id: null,
    user_id: user?.id ?? null,
    liked_by: null,
  };

  const feedResult = useInfiniteQuery({
    queryKey: ["posts", "feed", queryParams],
    queryFn: ({ pageParam }) =>
      feed({
        params: { ...queryParams, page: pageParam },
      }),
    initialPageParam: 0,
    getNextPageParam: lastPage => {
      const nextPage = lastPage.current_page + 1;
      return nextPage <= lastPage.total_pages ? nextPage : null;
    },
  });

  if (!feedResult.isSuccess) return <></>;

  return (
    <>
      {feedResult.data.pages.map((page, pageIndex) =>
        page.data.map((post, index: number) => (
          <div
            className="max-lg:odd:border-y max-lg:odd:border-y-gray-300 max-lg:odd:py-2"
            key={post.id}
          >
            <PostWidget
              key={post.id}
              postId={post.id}
              post={post}
              text={post.text}
              author={post.author}
              repost={post.reposted}
              created_at={post.created_at}
              onSwitch={(id: string) => {
                setLightboxSelection(id);
              }}
              next={nextPost({ index, pageIndex, postsResult: feedResult })}
              lightboxSelection={lightboxSelection}
              prev={prevPost({ index, pageIndex, postsResult: feedResult })}
            />
          </div>
        )),
      )}

      {(feedResult.hasNextPage || feedResult.isFetchingNextPage) && (
        <ShowMoreButton onClick={() => feedResult.fetchNextPage()} />
      )}
    </>
  );
};

export default MyFeedContainer;
