import DemoProducts from "@src/mocks/MarketplaceProduct.json";
import { useTranslation } from "react-i18next";

const BigTileWithFourImg = () => {
  const demoProducts = DemoProducts.demoProducts;
  const { t } = useTranslation();

  return (
    <div className="round-lg w-full rounded-lg border border-gray-300 bg-white px-4 py-3">
      <span className="truncate text-base font-semibold">
        {t("main.marketplace.tabs.topOffer")}
      </span>
      <div className="grid grid-cols-2 gap-2">
        {demoProducts.slice(0, 4).map(product => (
          <a key={product._id} className="flex flex-col items-center">
            <div className="w-full">
              <img alt="" className="size-full max-h-28 object-cover p-2" src={product.image} />
            </div>
            <span className="bg-red-300 text-xs">
              {t("main.marketplace.tabs.discount", { count: 16 })}
            </span>
          </a>
        ))}
      </div>
      <a href="">
        <span className="text-xs">{t("main.marketplace.tabs.discoverMore")}</span>
      </a>
    </div>
  );
};

export default BigTileWithFourImg;
