import React from "react";

export interface TabBodyProps {
  children?: React.ReactNode;
  id: string;
}

const TabBody: React.FC<TabBodyProps> = props => {
  return <>{props.children}</>;
};

export default TabBody;
