import { HeartIcon } from "@heroicons/react/24/outline";
import { HeartIcon as HeartIconSolid } from "@heroicons/react/24/solid";
import PostsActionBarItem from "@src/pages/news/posts/PostsActionBarItem";
import { useState } from "react";

type Props = {
  liked: boolean;
  likeCount: number;
  like: () => void;
  unlike: () => void;
  label?: string;
};

const LikeButton = ({ liked, likeCount, label = "", like, unlike }: Props) => {
  const [likeEffect, setLikeEffect] = useState(false);

  return (
    <PostsActionBarItem
      onClick={() => (liked ? unlike && unlike() : like && like())}
      label={label}
      textColor={
        liked
          ? "text-red-600"
          : "text-gray-600 transition-all ease-out duration-200 group-hover:text-red-600"
      }
      color={liked ? "bg-pink-100" : "group-hover:bg-pink-100"}
      count={likeCount}
    >
      {liked ? (
        <HeartIconSolid className={`${likeEffect && "animate-ping-once"}`} />
      ) : (
        <HeartIcon
          onClick={() => {
            setLikeEffect(true);
          }}
          onAnimationEnd={() => {
            setLikeEffect(false);
          }}
        />
      )}
    </PostsActionBarItem>
  );
};

export default LikeButton;
