import BackButton from "@src/components/nav/BackButton";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

type PlaceType = {
  title: string;
  subTitle: string;
  dates: string;
  link: string;
  thumbnail: string;
  logo: string;
  demographic: string;
  city: string;
  place: string;
  placeLink: string;
  country: string;
};

const EventCalendar = () => {
  const { t } = useTranslation();
  const localEvents: Array<PlaceType> = [
    {
      link: "https://www.messeninfo.de/QUARTERfashion-M4827/Schkeuditz.html",
      place: "MMC Mitteldeutsches Mode Center, Schkeuditz",
      placeLink: "https://www.messeninfo.de/MMC+Mitteldeutsches+Mode+Center-ZS518.html",
      country: "Deutschland",
      title: "QUARTERfashion",
      subTitle: "Fachmesse und Ordertage für Bekleidung und Accessoires",
      city: "Leipzig",
      thumbnail: "/images/info/events/small_344.webp",
      demographic: "für Fachbesucher",
      logo: "/images/info/events/midora_logo_224.webp",
      dates: "05. - 07. August 2023",
    },
    {
      demographic: "für Fachbesucher",
      city: "Zwickau",
      dates: "11. - 13. August 2023",
      link: "https://www.messeninfo.de/QUARTERshoestart-M1615/Schkeuditz.html",
      title: "QUARTERshoestart",
      country: "Deutschland",
      placeLink: "https://www.messeninfo.de/MMC+Mitteldeutsches+Mode+Center-ZS518.html",
      subTitle: "Mitteldeutsche Schuhmesse und Ordertage",
      thumbnail: "/images/info/events/small_307.webp",
      place: "MMC Mitteldeutsches Mode Center, Schkeuditz",
      logo: "/images/info/events/quartershoestart_logo_1615.webp",
    },
    {
      place: "MMC Mitteldeutsches Mode Center, Schkeuditz",
      demographic: "für Fachbesucher",
      subTitle: "Fachmesse für Unterwäsche und Dessous",
      city: "Leipzig",
      placeLink: "https://www.messeninfo.de/MMC+Mitteldeutsches+Mode+Center-ZS518.html",
      dates: "11. - 13. August 2023",
      logo: "/images/info/events/quarterdessous_logo_11799.webp",
      link: "https://www.messeninfo.de/QUARTERdessous-M11799/Schkeuditz.html",
      country: "Deutschland",
      thumbnail: "/images/info/events/small_86.webp",
      title: "QUARTERdessous",
    },
    {
      city: "Leipzig",
      title: "handgemacht Kreativmarkt & StoWoMa",
      dates: "27. August 2023",
      placeLink: "https://www.messeninfo.de/Galopprennbahn+Dresden-Seidnitz-ZS10419.html",
      subTitle: "Die große Verkaufsschau für Individualisten, Designer und Selbermacher",
      country: "Deutschland",
      demographic: "Publikumsveranstaltung",
      thumbnail: "/images/info/events/small_206.webp",
      place: "Galopprennbahn Dresden-Seidnitz, Dresden",
      link: "https://www.messeninfo.de/handgemacht+Kreativmarkt+%26+StoWoMa+Open+Air-M12458/Dresden.html",
      logo: "/images/info/events/handgemacht_kreativ_markt_stowoma_logo_12458.webp",
    },
    {
      logo: "/images/info/events/midora_logo_224.png",
      link: "https://www.messeninfo.de/Midora-M224/Leipzig.html",
      place: "Messe Leipzig",
      title: "Midora",
      demographic: "für Fachbesucher",
      dates: "02. - 04. September 2023",
      country: "Deutschland",
      placeLink: "https://www.messeninfo.de/Messe+Leipzig-ZS18.html",
      thumbnail: "/images/info/events/small_344.webp",
      subTitle: "Order- und Informationsmesse der Uhren- und Schmuckbranche",
      city: "Leipzig",
    },
    {
      placeLink: "https://www.messeninfo.de/Stadthalle-ZS2656.html",
      link: "https://www.messeninfo.de/Modellbahnb%C3%B6rse-M11979/Zwickau.html",
      country: "Deutschland",
      city: "Zwickau",
      logo: "/images/info/events/modellbahnboerse_logo_11979.webp",
      place: "Stadthalle",
      title: "Modellbahnbörse",
      thumbnail: "/images/info/events/small_249.webp",
      dates: "02. September 2023",
      demographic: "Publikumsmesse",
      subTitle: "Messe für Modellbahnen, Autos, und Ü-Eier-Figuren in der Stadthalle in Zwickau",
    },
    {
      dates: "02. - 04. September 2023",
      country: "Deutschland",
      placeLink: "https://www.messeninfo.de/Messe+Leipzig-ZS18.html",
      subTitle: "Fachmesse für Geschenk- und Wohntrends",
      thumbnail: "/images/info/events/small_249.webp",
      city: "Leipzig",
      demographic: "für Fachbesucher",
      title: "Cadeaux",
      place: "Messe Leipzig",
      logo: "/images/info/events/cadeaux_logo_221.webp",
      link: "https://www.messeninfo.de/Cadeaux-M221/Leipzig.html",
    },
    {
      thumbnail: "/images/info/events/small_366.webp",
      link: "https://www.messeninfo.de/unique+4%2B1-M5165/Leipzig.html",
      title: "unique 4+1",
      place: "Messe Leipzig",
      dates: "02. - 04. September 2023",
      logo: "/images/info/events/unique_logo_5165.webp",
      city: "Leipzig",
      placeLink: "https://www.messeninfo.de/Messe+Leipzig-ZS18.html",
      country: "Deutschland",
      demographic: "für Fachbesucher und Privatbesucher",
      subTitle: "Internationale Fachmesse für Trophies, Gravier- und Werbetechnik",
    },
    {
      subTitle: "Die Reptilien-Fachmesse für jung und alt",
      country: "Deutschland",
      link: "https://www.messeninfo.de/Terra-Rep-M13588/Oelsnitz%2C+Vogtland.html",
      thumbnail: "/images/info/events/small_297.webp",
      title: "Terra-Rep",
      logo: "/images/info/events/terrarep_logo_13588.webp",
      city: "Oelsnitz, Vogtland",
      dates: "03. September 2023",
      demographic: "für Fachbesucher und Privatbesucher",
      placeLink: "https://www.messeninfo.de/Vogtlandsporthalle-ZS10071.html",
      place: "Vogtlandsporthalle",
    },
    {
      link: "https://www.messeninfo.de/Modellbahnb%C3%B6rse-M1022/Dresden.html",
      country: "Deutschland",
      place: "Alte Mensa",
      city: "Dresden",
      placeLink: "https://www.messeninfo.de/Alte+Mensa-ZS861.html",
      logo: "/images/info/events/modellbahnboerse_logo_11979.webp",
      title: "Modellbahnbörse",
      subTitle: "Messe für Modellbahnen, Autos, und Ü-Eier-Figuren in der Stadthalle in Zwickau",
      thumbnail: "/images/info/events/small_249.webp",
      demographic: "Publikumsmesse",
      dates: "03. September 2023",
    },
    {
      title: "LebensArt",
      thumbnail: "/images/info/events/small_135.webp",
      demographic: "Publikumsmesse",
      place: "Wernesgrüner Brauerei-Gutshof, Steinberg",
      logo: "/images/info/events/lebensart_logo_neu_12582.webp",
      dates: "08. - 10. September 2023",
      subTitle: "Messe für Garten, Wohnen und Lifestyle auf dem Wernesgrüner Brauerei-Gutshof",
      city: "Steinberg",
      link: "https://www.messeninfo.de/LebensArt-M12582/Steinberg.html",
      country: "Deutschland",
      placeLink: "https://www.messeninfo.de/Wernesgr%C3%BCner+Brauerei-Gutshof-ZS9247.html",
    },
    {
      demographic: "für Fachbesucher",
      city: "Schkeuditz",
      subTitle: "Mitteldeutsche Messe für Schuhe und Lederwaren",
      logo: "/images/info/events/quartershoetime_logo_650.webp",
      place: "MMC Mitteldeutsches Mode Center, Schkeuditz",
      country: "Deutschland",
      placeLink: "https://www.messeninfo.de/MMC+Mitteldeutsches+Mode+Center-ZS518.html",
      thumbnail: "/images/info/events/small_307.webp",
      link: "https://www.messeninfo.de/QUARTERshoetime+%26+bags+Orderdays-M650/Schkeuditz.html",
      title: "QUARTERshoetime & bags Orderdays",
      dates: "08. - 10. September 2023",
    },
    {
      place: "Messe Dresden",
      title: "Bauen Kaufen Wohnen",
      thumbnail: "/images/info/events/small_157.webp",
      logo: "/images/info/events/bauen_kaufen_wohnen_logo_6450.webp",
      placeLink: "https://www.messeninfo.de/Messe+Dresden-ZS227.html",
      country: "Deutschland",
      demographic: "für Fachbesucher und Privatbesucher",
      dates: "09. - 10. September 2023",
      subTitle: "Dresdner Bau- und Immobilienmesse",
      link: "https://www.messeninfo.de/Bauen+Kaufen+Wohnen-M6450/Dresden.html",
      city: "Dresden",
    },
    {
      city: "Schkeuditz",
      dates: "09. September 2023",
      subTitle: "Das Event für Orientierung nach dem Abi",
      place: "Globana Trade Center Leipzig Halle, Schkeuditz",
      title: "HORIZON Mitteldeutschland",
      logo: "/images/info/events/horizon_logo_neu_6897.webp",
      link: "https://www.messeninfo.de/HORIZON+Mitteldeutschland-M6897/Schkeuditz.html",
      country: "Deutschland",
      thumbnail: "/images/info/events/small_364.webp",
      demographic: "Publikumsmesse",
      placeLink: "https://www.messeninfo.de/Globana+Trade+Center+Leipzig+Halle-ZS4431.html",
    },
    {
      title: "Jobmesse",
      subTitle: "Karrieremesse",
      link: "https://www.messeninfo.de/Jobmesse-M8087/Leipzig.html",
      city: "Leipzig",
      place: "Congress Center",
      country: "Deutschland",
      demographic: "Publikumsmesse",
      placeLink: "https://www.messeninfo.de/Congress+Center-ZS1049.html",
      dates: "09. September 2023",
      thumbnail: "/images/info/events/small_399.webp",
      logo: "/images/info/events/jobmesse_leipzig_logo_neu_8087.webp",
    },
    {
      placeLink: "https://www.messeninfo.de/Festhalle-ZS865.html",
      link: "https://www.messeninfo.de/Modellbahnb%C3%B6rse-M1026/Plauen.html",
      country: "Deutschland",
      city: "Plauen",
      place: "Festhalle",
      demographic: "Publikumsmesse",
      subTitle: "Messe für Modellbahnen, Autos, und Ü-Eier-Figuren in der Festhalle in Plauen",
      logo: "/images/info/events/modellbahnboerse_logo_1026.webp",
      thumbnail: "/images/info/events/small_249.webp",
      title: "Modellbahnbörse",
      dates: "10. September 2023",
    },
    {
      placeLink: "https://www.messeninfo.de/Messe+Leipzig-ZS18.html",
      demographic: "für Fachbesucher",
      title: "efa",
      country: "Deutschland",
      subTitle: "Fachmesse für Elektro-, Gebäude-, Licht- und Energietechnik",
      logo: "/images/info/events/efa_leipzig_logo_3897.webp",
      link: "https://www.messeninfo.de/efa-M3897/Leipzig.html",
      city: "Leipzig",
      dates: "12. - 14. September 2023",
      thumbnail: "/images/info/events/small_196.webp",
      place: "Messe Leipzig",
    },
    {
      subTitle:
        "Publikumsmesse mit vielfältigen Angeboten zu den Themen Job, Studium sowie Aus- und Weiterbildung",
      place: "Stadion an der Gellertstrasse, Chemnitz",
      dates: "14. September 2023",
      link: "https://www.messeninfo.de/Jobmesse-M7507/Chemnitz.html",
      demographic: "Publikumsmesse",
      placeLink: "https://www.messeninfo.de/Stadion+an+der+Gellertstrasse-ZS10431.html",
      title: "Jobmesse",

      logo: "/images/info/events/jobmesse_leipzig_logo_neu_8087.webp",
      thumbnail: "/images/info/events/small_399.webp",
      country: "Deutschland",
      city: "Chemnitz",
    },
    {
      title: "AZUBI Spot",
      place: "CinemaxX",
      dates: "14. September 2023",
      logo: "/images/info/events/azubisport_logo_neu_4802.webp",
      country: "Deutschland",
      city: "Dresden",
      link: "https://www.messeninfo.de/AZUBI+Spot-M4802/Dresden.html",
      subTitle: "Die Azubi-, Studi- und Karrieremesse im CinemaxX in Dresden",
      thumbnail: "/images/info/events/small_25.webp",
      demographic: "für Fachbesucher und Privatbesucher",
      placeLink: "https://www.messeninfo.de/CinemaxX-ZS10642.html",
    },
    {
      placeLink: "https://www.messeninfo.de/Schloss+Brandis-ZS9856.html",
      subTitle: " Messe für Garten, Wohnen und Lifestyle auf Schloss Brandis",
      demographic: "Publikumsmesse",
      dates: "15. - 17. September 2023",
      place: "Schloss Brandis",
      title: "LebensArt",
      thumbnail: "/images/info/events/small_135.webp",
      city: "Brandis",
      logo: "/images/info/events/lebensart_logo_neu_5155.webp",
      country: "Deutschland",
      link: "https://www.messeninfo.de/LebensArt-M5155/Brandis.html",
    },
  ];

  const largeEvents: Array<PlaceType> = [
    {
      link: "https://www.messeninfo.de/LebensArt-M8205/L%C3%BCbben.html",
      place: "Schloss",
      placeLink: "https://www.messeninfo.de/Schloss-ZS6470.html",
      logo: "/images/info/events/lebensart_logo_neu_8205.webp",
      subTitle: "Messe für Garten, Wohnen und Lifestyle auf der Schlossinsel Lübben",
      title: "LebensArt",
      city: "Lübben",
      country: "Deutschland",
      thumbnail: "/images/info/events/small_135.webp",
      dates: "28. - 30. Juli 2023",
      demographic: "Publikumsmesse",
    },
    {
      subTitle: "Messe für moderenes Gartenambiente auf Burg Ohrdruf",
      title: "Haus- & Garten Träume",
      demographic: "Publikumsmesse",
      place: "Burg Ohrdruf",
      placeLink: "https://www.messeninfo.de/Burg+Ohrdruf-ZS1475.html",
      thumbnail: "/images/info/events/small_135.webp",
      link: "https://www.messeninfo.de/Haus-+%26+Garten+Tr%C3%A4ume-M1764/Ohrdruf.html",
      dates: "28. - 30. Juli 2023",
      logo: "/images/info/events/haus_und_gartentraeume_ohrdruf_logo_1764.webp",
      country: "Deutschland",
      city: "Ohrdruf",
    },
    {
      place: "Schokoladenmuseum",
      dates: "28. - 30. Juli 2023",
      thumbnail: "/images/info/events/small_82.webp",
      link: "https://www.messeninfo.de/Gourmet+Festival-M3441/K%C3%B6ln.html",
      demographic: "für Fachbesucher und Privatbesucher",
      country: "Deutschland",
      placeLink: "https://www.messeninfo.de/Schokoladenmuseum-ZS6249.html",
      city: "Köln",
      title: "Messen bundesweit",
      subTitle: "Kölns größtes kulinarisches Open-Air-Festival",
      logo: "/images/info/events/gourmet_festival_logo_neu_3441.webp",
    },
    {
      country: "Deutschland",
      city: "Oldenburg",
      title: "EinsSein-Messe",
      subTitle:
        "Die Messe für Gesundheit, Spiritualität und Begegnung in den Festsälen der Weser Ems Hallen in Oldenburg",
      placeLink: "https://www.messeninfo.de/Weser-Ems-Hallen-ZS515.html",
      logo: "/images/info/events/einssein_messe_logo_4181.webp",
      demographic: "für Fachbesucher und Privatbesucher",
      dates: "29. - 30. Juli 2023",
      thumbnail: "/images/info/events/small_102.webp",
      place: "Weser-Ems-Hallen",
      link: "https://www.messeninfo.de/EinsSein-Messe-M4181/Oldenburg.html",
    },
    {
      link: "https://www.messeninfo.de/INNATEX-M27/Hofheim+am+Taunus.html",
      placeLink: "https://www.messeninfo.de/Messecenter+Rhein-Main-ZS857.html",
      demographic: "für Fachbesucher",
      thumbnail: "/images/info/events/small_335.webp",
      place: "Messecenter Rhein-Main, Hofheim am Taunus",
      dates: "29. - 31. Juli 2023",
      logo: "/images/info/events/innatex_logo_27.webp",
      subTitle: "Internationale Fachmesse für nachhaltige Textilien",
      title: "INNATEX",
      city: "Hofheim am Taunus",
      country: "Deutschland",
    },
    {
      demographic: "Publikumsmesse",
      link: "https://www.messeninfo.de/Kunst+%26+HandwerkerMarkt-M14128/Leer.html",
      place: "Rathausstraße",
      title: "Kunst & HandwerkerMarkt",
      subTitle: "Ausstellung für Kunsthandwerk aller Arten und Materialien in der Altstadt",
      logo: "/images/info/events/kunst_und_handwerkermarkt_logo_14128.webp",
      placeLink: "https://www.messeninfo.de/Rathausstra%C3%9Fe-ZS10694.html",
      country: "Deutschland",
      thumbnail: "/images/info/events/small_206.webp",
      city: "Leer",
      dates: "30. Juli 2023",
    },
    {
      subTitle: "Volksfest und Gewerbeausstellung",
      logo: "/images/info/events/arolser_kram_und_viehmarkt_logo_9445.webp",
      title: "Arolser Kram- und Viehmarkt",
      placeLink: "https://www.messeninfo.de/Festplatz+K%C3%B6nigsberg-ZS7145.html",
      demographic: "Publikumsmesse",
      dates: "03. - 06. August 2023",
      country: "Deutschland",
      thumbnail: "/images/info/events/small_147.webp",
      place: "Festplatz Königsberg",
      city: "Bad Arolsen",
      link: "https://www.messeninfo.de/Arolser+Kram-+und+Viehmarkt-M9445/Bad+Arolsen.html",
    },
    {
      subTitle: "Gartenausstellung auf Schloss Corvey",
      thumbnail: "/images/info/events/small_135.webp",
      place: "Schloss Corvey",
      placeLink: "https://www.messeninfo.de/Schloss+Corvey-ZS7913.html",
      dates: "04. - 06. August 2023",
      logo: "/images/info/events/das_gartenfest_corvey_logo_10875.webp",
      link: "https://www.messeninfo.de/Das+Gartenfest+Corvey-M10875/H%C3%B6xter.html",
      demographic: "Publikumsmesse",
      city: "Höxter",
      title: "Das Gartenfest Corvey",
      country: "Deutschland",
    },
    {
      placeLink:
        "https://www.messeninfo.de/Golf+%26+Country+Club+an+der+Elfrather+M%C3%BChle-ZS10539.html",
      title: "Schöner Golfen",
      logo: "/images/info/events/schoener_golfen_logo_13954.webp",
      place: "Golf & Country Club an der Elfrather Mühle, Krefeld",
      link: "https://www.messeninfo.de/Sch%C3%B6ner+Golfen-M13954/Krefeld.html",
      country: "Deutschland",
      subTitle:
        "Open Air Ausstellung für Golf, Country und Elektromobilität im Golf & Country Club an der Elfrather Mühle",
      city: "Krefeld",
      dates: "04. - 06. August 2023",
      thumbnail: "/images/info/events/small_151.webp",
      demographic: "für Fachbesucher und Privatbesucher",
    },
    {
      thumbnail: "/images/info/events/small_280.webp",
      title: "DIGA",
      dates: "04. - 08. August 2023",
      demographic: "Publikumsmesse",
      placeLink: "https://www.messeninfo.de/Rennbahn+und+Parkgel%C3%A4nde-ZS5808.html",
      place: "Rennbahn und Parkgelände, Iffezheim",
      city: "Iffezheim",
      logo: "/images/info/events/diga_logo_6800.webp",
      subTitle: "Die Gartenmesse",
      link: "https://www.messeninfo.de/DIGA-M6800/Iffezheim.html",
      country: "Deutschland",
    },
    {
      city: "Hamburg",
      link: "https://www.messeninfo.de/Kindermoden+Nord-M11120/Hamburg.html",
      placeLink: "https://www.messeninfo.de/Messehalle+Hamburg-Schnelsen-ZS19.html",
      title: "Kindermoden Nord",
      logo: "/images/info/events/kindermoden_nord_logo_11120.webp",
      place: "Messehalle Hamburg-Schnelsen, Hamburg",
      dates: "05. - 07. August 2023",
      country: "Deutschland",
      demographic: "für Fachbesucher",
      subTitle: "Fachmesse für Kindermode",
      thumbnail: "/images/info/events/small_191.webp",
    },
    {
      placeLink: "https://www.messeninfo.de/MTC+M%C3%BCnchner+Textil+Center-ZS5749.html",
      city: "München",
      subTitle: "Ordermesse für hochwertige Damen- und Herrenmode",
      logo: "/images/info/events/supreme_women_men_logo_11960.webp",
      thumbnail: "/images/info/events/small_248.webp",
      link: "https://www.messeninfo.de/Supreme+Women%26Men-M11960/M%C3%BCnchen.html",
      country: "Deutschland",
      title: "Supreme Women&Men",
      demographic: "für Fachbesucher",
      place: "MTC Münchner Textil Center, München",
      dates: "05. - 08. August 2023",
    },
    {
      link: "https://www.messeninfo.de/Solnhofener+Fossilientage-M11911/Solnhofen.html",
      title: "Solnhofener Fossilientage",
      placeLink: "https://www.messeninfo.de/B%C3%BCrgermeister-M%C3%BCller-Museum-ZS8351.html",
      demographic: "für Fachbesucher und Privatbesucher",
      dates: "05. - 06. August 2023",
      logo: "/images/info/events/solnhofener_fossilientage_logo_11911.webp",
      country: "Deutschland",
      city: " Solnhofen ",
      place: "Bürgermeister-Müller-Museum, Solnhofen",
      thumbnail: "/images/info/events/small_127.webp",
      subTitle: "Mineralien- und Fossilienmesse",
    },
    {
      link: "https://www.messeninfo.de/QUARTERfashion-M4827/Schkeuditz.html",
      placeLink: "https://www.messeninfo.de/MMC+Mitteldeutsches+Mode+Center-ZS518.html",
      title: "QUARTERfashion",
      country: "Deutschland",
      thumbnail: "/images/info/events/small_46.webp",
      place: "MMC Mitteldeutsches Mode Center, Schkeuditz",
      subTitle: "Fachmesse und Ordertage für Bekleidung und Accessoires",
      demographic: "für Fachbesucher",
      logo: "/images/info/events/quarterfashion_logo_4827.webp",
      city: "Schkeuditz",
      dates: "05. - 07. August 2023",
    },
    {
      dates: "06. - 07. August 2023",
      placeLink: "https://www.messeninfo.de/Brandboxx+Hannover-ZS2394.html",
      subTitle: "Ordermesse für den Schuhfachhandel",
      thumbnail: "/images/info/events/small_307.webp",
      demographic: "für Fachbesucher",
      country: "Deutschland",
      city: "Langenhagen",
      link: "https://www.messeninfo.de/SOC+Saisoner%C3%B6ffnung+Hannover-M8635/Langenhagen.html",
      title: "SOC Saisoneröffnung Hannover",
      place: "Brandboxx Hannover",
      logo: "/images/info/events/soc_saisoneroeffnung_logo_8635.webp",
    },
    {
      dates: "06. August 2023",
      placeLink: "https://www.messeninfo.de/Messe+Giessen-ZS748.html",
      country: "Deutschland",
      demographic: "Publikumsmesse",
      logo: "/images/info/events/reptilienmesse_logo_neu_5857.webp",
      subTitle: "Messe für Terraristik in der Hessenhalle der Messe Giessen",
      place: "Messe Giessen",
      link: "https://www.messeninfo.de/Reptilienb%C3%B6rse-M5857/Gie%C3%9Fen.html",
      city: "Gießen",
      title: "Reptilienbörse",
      thumbnail: "/images/info/events/small_334.webp",
    },
    {
      country: "Deutschland",
      subTitle: "Hundemesse mit Informationen, Angeboten, Schnäppchen und Shows rund um den Hund",
      demographic: "für Fachbesucher und Privatbesucher",
      city: "Duisburg",
      thumbnail: "/images/info/events/small_389.webp",
      placeLink: "https://www.messeninfo.de/Kultur-+und+B%C3%BCrgerzentrum+Steinhof-ZS5852.html",
      link: "https://www.messeninfo.de/Ruhrpott-Hundemesse-M13001/Duisburg.html",
      title: "Ruhrpott-Hundemesse",
      place: "Kultur- und Bürgerzentrum Steinhof, Duisburg",
      logo: "/images/info/events/ruhrpott_hundemesse_logo_13001.webp",
      dates: "06. August 2023",
    },
    {
      demographic: "Publikumsmesse",
      link: "https://www.messeninfo.de/Antik+%26+Tr%C3%B6del-M897/Gie%C3%9Fen.html",
      logo: "/images/info/events/antik-und-troedel_logo_897.webp",
      title: "Antik & Trödel",
      city: "Gießen",
      thumbnail: "/images/info/events/small_15.webp",
      subTitle: "Monatlicher Antik- und Trödelmarkt",
      place: "Messe Giessen",
      dates: "06. August 2023 ",
      country: "Deutschland",
      placeLink: "https://www.messeninfo.de/Messe+Giessen-ZS748.html",
    },
    {
      thumbnail: "/images/info/events/small_112.webp",
      demographic: "für Fachbesucher",
      placeLink: "https://www.messeninfo.de/Cinedom-ZS9199.html",
      link: "https://www.messeninfo.de/Film-Messe-M5518/K%C3%B6ln.html",
      dates: "",
      title: "Film-Messe",
      logo: "/images/info/events/film_messe_koeln_logo_5518.webp",
      place: "Cinedom",
      country: "Deutschland",
      city: "Köln",
      subTitle: "Fachmesse für Film-Verleiher, Kinobetreiber und kinoaffine Unternehmen",
    },
    {
      dates: "08. - 09. August 2023 ",
      country: "Deutschland",
      thumbnail: "/images/info/events/small_307.webp",
      place: "Brandboxx Hannover",
      link: "https://www.messeninfo.de/Kids+%26+more+Schuhtage+Hannover-M6262/Langenhagen.html",
      placeLink: "https://www.messeninfo.de/Brandboxx+Hannover-ZS2394.html",
      city: "Langenhagen",
      demographic: "für Fachbesucher",
      subTitle: "Fachmesse für Kinderschuhe",
      logo: "/images/info/events/kids_and_more_schuhtage_logo_6262.webp",
      title: "Kids & more Schuhtage Hannover",
    },
  ];

  return (
    <>
      <div className="pl-4 pt-4">
        <BackButton stepback desktopVisible />
      </div>
      <div className="grid grid-flow-row gap-6 pb-4 text-gray-700">
        <section className="relative">
          <img
            alt=""
            src="/images/info/projekt-auftragsvergabe-header-2.jpg"
            className="h-[300px] w-full object-cover"
          />
          <div className="absolute left-6 top-1/2 flex -translate-y-1/2 flex-col font-semibold italic">
            <div className="w-fit bg-white/80 px-1 text-2xl text-darkcyan">
              {t("pages.events.text1")}
            </div>
            <div className="ml-4 w-fit bg-darkblue/80 px-1 text-white">
              {t("pages.events.text2")}
            </div>
          </div>
        </section>

        <div className="grid grid-flow-row gap-3 text-xs max-xl:px-4">
          <h1 className="text-left text-smbase font-bold">{t("pages.events.text3")}</h1>
          <div className="grid grid-cols-2 gap-2 lg:grid-cols-3">
            {localEvents.map((event: PlaceType, index) => {
              return (
                <div className="grid h-[260px] grid-flow-row text-sm" key={event.title}>
                  <NavLink to={event.link} target="_blank">
                    <div className="group relative h-[100px] overflow-hidden shadow-lg">
                      <img alt="" src={event.thumbnail} className="h-[100px] w-full object-cover" />
                      <img alt="" src={event.logo} className="absolute left-5 top-0 w-[50px]" />
                      <div className="absolute left-0 top-0 size-full bg-green-400/15 transition-all group-hover:bg-green-400/0"></div>
                    </div>
                    <h2 className="flex h-[32px] items-center bg-[#add1a0] pl-2">
                      {event.dates.length > 0 ? event.dates : "Kein Termin"}
                    </h2>
                  </NavLink>
                  <div
                    className={`mb-1 truncate text-base font-semibold ${index % 2 ? "max-lg:pr-4" : "max-lg:pl-4"}`}
                  >
                    {event.title}
                  </div>
                  <div
                    className={`line-clamp-3 h-[50px] text-xs ${index % 2 ? "max-lg:pr-4" : "max-lg:pl-4"}`}
                  >
                    {event.subTitle}
                  </div>
                  <div className={`truncate text-xs ${index % 2 ? "max-lg:pr-4" : "max-lg:pl-4"}`}>
                    {event.demographic}
                  </div>
                  <div
                    className={`mt-0.5 grid grid-cols-[1fr,50px] gap-2 ${index % 2 ? "max-lg:pr-4" : "max-lg:pl-4"}`}
                  >
                    <div className="grid grid-flow-row truncate leading-4">
                      <NavLink to={event.placeLink} target="_blank">
                        <div className="font-semibold text-darkcyan">{event.place}</div>
                      </NavLink>
                      <div>{event.city}</div>
                    </div>
                    {event.country === "Deutschland" && (
                      <img
                        src="/images/countries/de.webp"
                        className="h-[27px] w-[45px]"
                        alt={event.country}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>

          <h1 className="text-base font-semibold">{t("pages.events.text4")}</h1>
          <div className="grid grid-cols-2 gap-2 lg:grid-cols-3">
            {largeEvents.map((event: PlaceType) => {
              return (
                <div className="grid h-[260px] grid-flow-row text-sm" key={event.title}>
                  <NavLink to={event.link} target="_blank">
                    <div className="group relative h-[100px] overflow-hidden shadow-lg">
                      <img alt="" src={event.thumbnail} className="h-[100px] w-full object-cover" />
                      <img alt="" src={event.logo} className="absolute left-5 top-0 w-[50px]" />
                      <div className="absolute left-0 top-0 size-full bg-green-400/15 transition-all group-hover:bg-green-400/0"></div>
                    </div>
                    <h2 className="flex h-[32px] items-center bg-[#add1a0] pl-2">
                      {event.dates.length > 0 ? event.dates : "Kein Termin"}
                    </h2>
                  </NavLink>
                  <div className="mb-1 truncate text-base font-semibold">{event.title}</div>
                  <div className="line-clamp-3 h-[50px] text-xs">{event.subTitle}</div>
                  <div className="truncate text-xs">{event.demographic}</div>
                  <div className="mt-0.5 grid grid-cols-[1fr,50px] gap-2">
                    <div className="grid grid-flow-row truncate leading-4">
                      <NavLink to={event.placeLink} target="_blank">
                        <div className="font-semibold text-darkcyan">{event.place}</div>
                      </NavLink>
                      <div>{event.city}</div>
                    </div>
                    {event.country === "Deutschland" && (
                      <img
                        src="/images/countries/de.webp"
                        className="h-[27px] w-[45px]"
                        alt={event.country}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default EventCalendar;
