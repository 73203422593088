import { User } from "@src/utils/user";
import { TFunction } from "i18next";

type CountryOptions = {
  zipCode?: boolean;
  street?: boolean;
  website?: boolean;
  phone_number?: boolean;
  email?: boolean;
  phoneNumber?: boolean;
  country?: boolean;
};

const getUserInformation = (user: User, t: TFunction, options?: CountryOptions) => {
  const {
    zipCode = false,
    street = false,
    website = false,
    phone_number = false,
    email = false,
    phoneNumber = false,
    country = false,
  } = options ?? {};

  let infoString = "";

  if (country) {
    const countryValue = user.person?.country ?? user.company?.country ?? user.club?.country;
    if (countryValue)
      infoString += `<span style="font-weight: bold; font-size: 0.9em; line-height: 0.9;">${t("main.profileViewTabs.editProfile.form.country")}:</span> ${countryValue}<br>`;
  }

  if (zipCode) {
    const zip = user.person?.zip_code ?? user.company?.zip_code ?? user.club?.zip_code;
    if (zip)
      infoString += `<span style="font-weight: bold; font-size: 0.9em; line-height: 0.2;">${t("main.profileViewTabs.editProfile.form.zipCode")}:</span> ${zip}<br>`;
  }

  if (street) {
    const streetValue = user.person?.street ?? user.company?.street ?? user.club?.street;
    if (streetValue)
      infoString += `<span style="font-weight: bold; font-size: 0.9em; line-height: 0.2;">${t("main.profileViewTabs.editProfile.form.street")}:</span> ${streetValue}<br>`;
  }

  if (website) {
    if (user.website)
      infoString += `<span style="font-weight: bold; font-size: 0.9em; line-height: 0.2;">${t("main.profileViewTabs.editProfile.form.website")}:</span> ${user.website}<br>`;
  }

  if (email) {
    if (user.email)
      infoString += `<span style="font-weight: bold; font-size: 0.9em; line-height: 0.2;">${t("main.profileViewTabs.editProfile.form.email")}:</span> ${user.email}<br>`;
  }

  if (phone_number ?? phoneNumber) {
    if (user.phone_number)
      infoString += `<span style="font-weight: bold; font-size: 0.9em; line-height: 0.2;">${t("main.profileViewTabs.editProfile.form.phone")}:</span> ${user.phone_number}<br>`;
  }

  return { __html: infoString.trimEnd() }; // Wrap in an object with __html key
};

export default getUserInformation;
