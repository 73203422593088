import mediaUpload from "@src/api/media/upload";
import videoUpload from "@src/api/video/upload/direct";
import { MediaType } from "@src/utils/types/MediaType";
import { useMutation } from "@tanstack/react-query";
import { Upload } from "tus-js-client";
import { SetUpload } from "use-tus/dist/types";

type Props = {
  setUploadingCount: (uploadingCount: number) => void;
  uploadingCount: number;
  onChange: (file: MediaType) => void;
  upload?: Upload;
  setUpload: SetUpload;
};

const useUploadMutation = ({
  setUploadingCount,
  uploadingCount,
  onChange,
  upload,
  setUpload,
}: Props) => {
  return useMutation({
    mutationKey: ["upload"],
    mutationFn: async (file: File) => {
      if (!file) {
        return;
      }
      let endpoint = "";
      let id = "";
      let data_url = "";
      let thumbnail = "";
      let tus_headers = {};
      let key = "";
      if (file.type.startsWith("video")) {
        if (upload) {
          throw new Error("Already uploading");
        }
        const { data } = await videoUpload({ params: { videoNames: file.name } });
        thumbnail = data.thumbnail;
        id = data.id;
        tus_headers = data.tus_headers;
        key = data.key;
        endpoint = data.endpoint;
        setUpload(file, {
          metadata: {
            filetype: file.type,
            filename: file.name,
          },
          headers: tus_headers,
          endpoint: endpoint,
        });
      } else {
        const { data } = await mediaUpload({ file });
        data_url = data.data_url;
        id = data.id;
        key = data.key;
      }

      if (
        !key ||
        !id ||
        !file.name ||
        (!file.type.startsWith("video") && !data_url) ||
        (file.type.startsWith("video") && !thumbnail) ||
        !file.type
      ) {
        throw new Error("Invalid response");
      }
      return {
        data_url,
        thumbnail,
        id,
        key,
        type: file.type.startsWith("video") ? "video" : "image",
        file_name: file.name,
      };
    },
    onSuccess: data => {
      setUploadingCount(uploadingCount - 1);
      if (!data) {
        return;
      }
      onChange(data);
    },
    onError: () => {
      setUploadingCount(uploadingCount - 1);
      alert("Failed");
    },
  });
};

export default useUploadMutation;
