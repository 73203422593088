import { useTranslation } from "react-i18next";

type Props = {
  rating?: number;
};

const ScoreBars = ({ rating = 0 }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-3">
      {Array(5)
        .fill(0)
        .reverse()
        .map(_ => (
          <div className="grid grid-cols-[65px,100px,55px]" key={_}>
            <div className="mr-3 w-max">{t("main.shop.details.starsCount", { count: rating })}</div>
            <div className="inline-flex bg-gray-300">
              <div className="bg-yellow-500" />
            </div>
            <div className="text-right">100 %</div>
          </div>
        ))}
    </div>
  );
};

export default ScoreBars;
