import { EyeIcon } from "@heroicons/react/24/outline";
import { ReactNode } from "react";
import { NavLink } from "react-router-dom";

type Props = {
  name: string;
  link: string;
  picture: string;
  key?: string;
  imageStyle?: string;
  nameStyle?: string;
  views?: {
    icon?: ReactNode;
    count?: number;
    color?: string;
  };
};

const SuggestionsCenterItem = (props: Props) => (
  <div className="flex w-fit gap-4">
    <NavLink to={props.link} className="relative">
      <div className="relative grid cursor-pointer grid-flow-row">
        <div
          className={`relative mx-auto size-[50px] overflow-hidden rounded-full border border-gray-300 ${props.imageStyle}`}
        >
          <img src={props.picture} alt="" className="size-full object-cover" />
        </div>
        <div
          className={`truncate whitespace-nowrap text-center text-sm leading-[50px] text-gray-600 ${props.nameStyle}`}
        >
          {props.name}
        </div>

        {props.views && (
          <div className={`absolute bottom-1 right-1 flex items-center gap-1 ${props.views.color}`}>
            {props.views.count}
            {props.views.icon ?? <EyeIcon className="size-4" />}
          </div>
        )}
      </div>
    </NavLink>
  </div>
);

export default SuggestionsCenterItem;
