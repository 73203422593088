import watch from "@src/api/watch/watch";
import CompactPost from "@src/components/elements/CompactPost";
import { useInfiniteQueryOnScroll } from "@src/hooks/useInfiniteQueryOnScroll";
import useDebounce from "@src/utils/useDebounce";

type Props = {
  userId: string;
  searchQuery: string;
};

const WatchLikes = ({ userId, searchQuery }: Props) => {
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const { result: watchResult } = useInfiniteQueryOnScroll({
    queryKey: ["posts", "watch", debouncedSearchQuery, userId],
    queryFn: ({ pageParam }) =>
      watch({ params: { page: pageParam, q: debouncedSearchQuery, liked_by: userId } }),
    initialPageParam: 0,
    getNextPageParam: lastPage => {
      const nextPage = lastPage.current_page + 1;
      return nextPage <= lastPage.total_pages ? nextPage : null;
    },
  });

  if (!watchResult.isSuccess) return <></>;

  return (
    <div className="mt-2 grid grid-cols-2 gap-4 xl:grid-cols-5">
      {watchResult.data.pages
        .flatMap(page => page.data)
        .filter(watch => watch !== null)
        .map(watch => (
          <CompactPost
            key={`watch:${watch.media_id}`}
            link={`/watch/für_dich?featured=${watch.media_id}`}
            type="video"
            url={watch.media_url}
            viewCount={watch.view_count}
            likeCount={watch.like_count}
          />
        ))}
    </div>
  );
};

export default WatchLikes;
