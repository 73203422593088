import { XMarkIcon } from "@heroicons/react/24/outline";
import { ReactNode } from "react";

type Props = {
  title?: string;
  children: ReactNode;
  onClose?: () => void;
  padding?: boolean;
  showBorder?: boolean;
};

const ButtonPanel = ({ title, children, padding = false, onClose, showBorder }: Props) => (
  <div
    className={`relative bg-white ${showBorder && "rounded-2xl border-gray-300 lg:border"} ${padding && "p-12"}`}
  >
    {title && (
      <div className="mb-4 flex w-full flex-row items-center gap-2 border-b px-3 pb-1 text-base font-semibold">
        {title}
      </div>
    )}
    <button onClick={onClose} className="absolute right-2 top-2">
      <XMarkIcon className="size-5 text-gray-700" />
    </button>
    <div className={`${padding && "p-3"}`}>{children}</div>
  </div>
);

export default ButtonPanel;
