import { XMarkIcon } from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  className?: string;
  bgColor?: string;
  title?: string;
  stepback?: boolean;
  desktopVisible?: boolean;
};

function BackButton({
  className,
  bgColor,
  title,
  stepback = false,
  desktopVisible = false,
}: Props) {
  const location = useLocation();
  const navigate = useNavigate();

  if (location.state?.backlink || stepback)
    return (
      <button
        className={`flex w-fit items-center gap-4 rounded-full ${!desktopVisible && "lg:hidden"} ${className}`}
        onClick={() => {
          if (stepback) {
            if (location.state?.backlink) {
              navigate(location.state.backlink);
            } else {
              navigate(-1);
            }
          } else {
            navigate(location.state?.backlink);
          }
        }}
      >
        <div className={`rounded-full ${bgColor}`}>
          <XMarkIcon className="size-5 text-gray-700" />
          {title}
        </div>
      </button>
    );

  return <></>;
}

export default BackButton;
