import create, { CreateRequest } from "@src/api/report/create";
import ModalWrapper from "@src/components/elements/shared/ModalWrapper";
import { hideReportModal } from "@src/state/reportModal/actions";
import { RootState } from "@src/state/store";
import wait from "@src/utils/wait";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";

const ReportModal = () => {
  const dispatch = useDispatch();
  const reportPopupState = useSelector((state: RootState) => state.reportModal);
  const [text, setText] = React.useState("");

  const createMutation = useMutation({
    mutationKey: ["report"],
    mutationFn: async (request: CreateRequest) => {
      create(request);
      await wait(2000);
    },
    onSuccess: () => {
      setTimeout(() => {
        handleClose();
      }, 3000);
    },
    onError: () => {
      setTimeout(() => {
        handleClose();
      }, 3000);
    },
  });

  const handleClose = () => {
    setText("");
    dispatch(hideReportModal());
    setTimeout(createMutation.reset, 500);
  };

  return (
    <ModalWrapper
      open={reportPopupState.isVisible}
      onCancel={handleClose}
      onConfirm={() =>
        createMutation.mutate({
          data: {
            item_id: reportPopupState.itemId,
            title: reportPopupState.title,
            type: reportPopupState.type,
            description: text,
          },
        })
      }
      title={reportPopupState.title ?? "Beitrag Melden"}
      loading={createMutation.isPending}
      success={createMutation.isSuccess}
      failed={createMutation.isError}
    >
      <TextareaAutosize
        value={text}
        minRows={3}
        onChange={e => setText(e.target.value)}
        className="block w-full rounded-xl border-0 bg-gray-200 px-4 py-3"
        placeholder=""
        maxRows={10}
      />
    </ModalWrapper>
  );
};

export default ReportModal;
