import AllProducts from "@src/mocks/AllProducts.json";
import CartProductsOther from "@src/pages/marketplace/components/CartProductsOther";
import ProductsCarousel from "@src/pages/marketplace/components/ProductsCarousel";
import { t } from "i18next";
import { SwiperSlide } from "swiper/react";

const MarketplaceCarousels = () => {
  const allProductsFromFile = AllProducts.allProducts;

  return (
    <>
      <div className="mt-3 xl:block">
        <span className="text-xl font-semibold">{t("main.shop.details.similarProducts")}</span>
        <ProductsCarousel spaceBetween={20} slidesPerView={1}>
          {allProductsFromFile.map(product => (
            <div key={product.ID}>
              <SwiperSlide>
                <CartProductsOther product={product} />
              </SwiperSlide>
            </div>
          ))}
        </ProductsCarousel>
      </div>
      <div className="mb-5 mt-3 xl:block">
        <span className="text-xl font-semibold">{t("main.contacts.tabs.trending")}</span>
        <ProductsCarousel spaceBetween={20} slidesPerView={1}>
          {allProductsFromFile.map(product => (
            <div key={product.ID}>
              <SwiperSlide>
                <CartProductsOther product={product} />
              </SwiperSlide>
            </div>
          ))}
        </ProductsCarousel>
      </div>
    </>
  );
};

export default MarketplaceCarousels;
