import { PlusCircleIcon } from "@heroicons/react/24/solid";
import follow from "@src/api/users/follow";
import { User } from "@src/utils/user";
import { useMutation, useQueryClient } from "@tanstack/react-query";

type Props = {
  user: User;
  me: User;
  size?: number;
};

const UserWidget = ({ user, me, ...props }: Props) => {
  const size = (props.size ?? 50) * 0.7 * 0.7;
  const queryClient = useQueryClient();

  const followMutation = useMutation({
    mutationKey: [`follow-${me.id}-${user.id}`],
    mutationFn: follow,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["contacts"] });
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  return (
    <PlusCircleIcon
      className="absolute inset-x-0 mx-auto translate-y-1 cursor-pointer rounded-full bg-white font-bold text-red-600 shadow-xl"
      onClick={e => {
        e.preventDefault();
        followMutation.mutate({ id: user.id });
      }}
      style={{
        width: `${size.toString()}px`,
        height: `${size.toString()}px`,
        bottom: `-${size * 0.35}px`,
      }}
    />
  );
};

export default UserWidget;
