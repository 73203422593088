import ProgressBar from "@ramonak/react-progress-bar";
import apiStatistics from "@src/api/users/statistics";
import { User } from "@src/utils/user";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import "chartjs-plugin-datalabels";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface StatisticsData {
  cityCount: Record<string, number>;
  cityPercentage: Record<string, number>;
  countryCount: Record<string, number>;
  countryPercentage: Record<string, number>;
}
type Props = {
  user: User;
};

const CountryCity = ({ user }: Props) => {
  const { t } = useTranslation();
  const [statistics, setStatistics] = useState<StatisticsData | null>(null);
  const countryPercentages = statistics?.countryPercentage || {};
  const cityPercentages = statistics?.cityPercentage || {};

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await apiStatistics({ id: user.id });

        setStatistics(data);
      } catch (error) {
        console.error("Error fetching user statistics", error);
      }
    };

    fetchData();
  }, [user]);

  if (!statistics) {
    return <h1>Loading.....</h1>;
  }
  return (
    <>
      {Object.keys(statistics.countryPercentage).length > 0 && (
        <div className="w-full rounded-lg border bg-white p-4 lg:border lg:shadow-md">
          <h1 className="mb-2 text-xl font-bold">
            {t("main.profileViewTabs.editProfile.form.country/city")}
          </h1>
          {Object.entries(countryPercentages).map(([country, percentage]) => (
            <div key={country} tw="mb-4">
              <h4>{country}</h4>
              <ProgressBar
                completed={parseFloat(percentage.toFixed(1))}
                bgColor="#10A2C5"
                height="18px"
                baseBgColor="#e0e0de"
                borderRadius="5px"
                labelColor="#fff"
              />
            </div>
          ))}
        </div>
      )}
      {Object.keys(statistics.cityPercentage).length > 0 && (
        <div className="w-full rounded-lg border bg-white p-4 lg:border lg:shadow-md">
          <h1 className="mb-2 text-xl font-bold">
            {t("main.profileViewTabs.editProfile.form.city")}
          </h1>
          {Object.entries(cityPercentages).map(([city, percentage]) => {
            city =
              city === "not_known"
                ? t("main.profileViewTabs.aboutMe.content.sidebarButtons.unknown")
                : city;

            return (
              <div key={city} tw="mb-4">
                <h4>{city}</h4>
                <ProgressBar
                  completed={parseFloat(percentage.toFixed(1))}
                  bgColor="#10A2C5"
                  height="18px"
                  baseBgColor="#e0e0de"
                  borderRadius="5px"
                  labelColor="#fff"
                />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default CountryCity;
