import forYou from "@src/api/contacts/forYou";
import UserWidget from "@src/components/elements/UserWidget";
import Button from "@src/components/elements/input/Button";
import Input from "@src/components/elements/input/Input";
import getName from "@src/utils/getName";
import useDebounce from "@src/utils/useDebounce";
import { User } from "@src/utils/user";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  onSave?: () => void;
  placeholder?: string;
  setSelectedUsers: (user: Array<User>) => void;
  selectedUsers: Array<User>;
};

const GroupComboBox = ({ placeholder, onSave, setSelectedUsers, selectedUsers }: Props) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState("");
  const debouncedSearchTerm = useDebounce(query, 100);

  const addSelectedUsers = (contact: User) => {
    const isUserAdded = !!selectedUsers.find(user => user.id === contact.id);
    if (!isUserAdded) {
      setQuery("");
      setSelectedUsers([...selectedUsers, contact]);
    }
  };

  const forYouResult = useQuery({
    queryKey: ["contacts", "for-you", debouncedSearchTerm],
    queryFn: () => forYou({ params: { q: debouncedSearchTerm } }),
  });

  return (
    <div className="mt-1 w-full rounded-2xl bg-white text-base focus:outline-none sm:text-sm">
      <div className="no-scrollbar flex flex-row gap-2 overflow-x-auto px-3 pb-0.5">
        {selectedUsers.map(user => (
          <div key={user.id}>
            <UserWidget user={user} follow={false} online={false} size={45} />
            <span className="line-clamp-1 w-12 text-center text-xs">{getName(user)}</span>
          </div>
        ))}
      </div>
      {onSave && (
        <div className="px-2">
          <Button variant={Button.Variants.Transparent} onClick={onSave}>
            {t("main.groupsInfoForm.buttons.further")}
          </Button>
        </div>
      )}
      <div className="mt-4 h-[2px] w-full bg-gray-300" />
      <div className="grid grid-cols-1 gap-2">
        <div className="px-2">
          <Input placeholder={placeholder} name="" value={query} onChange={setQuery} />
        </div>
        <div className="no-scrollbar grid grid-cols-1 gap-2 overflow-y-auto">
          {forYouResult.data?.data.slice(0, 10).map(contact => (
            <div
              aria-hidden
              onClick={() => {
                if (selectedUsers.find(user => user.id === contact.id)) {
                  setSelectedUsers(selectedUsers.filter(item => item.id !== contact.id));
                } else {
                  addSelectedUsers(contact);
                }
              }}
              key={contact.id}
              className="relative cursor-pointer select-none py-1.5 pl-1 text-gray-900 hover:bg-gray-200"
            >
              <div className="flex items-center">
                <UserWidget user={contact} follow={false} online={false} size={45} />
                <span className="ml-3 line-clamp-1 text-left">{getName(contact)}</span>
              </div>
              <span className="absolute inset-y-0 right-0 flex items-center pr-1 text-indigo-600">
                <input
                  type="radio"
                  className="accent-indigo-600 outline-none ring-0 "
                  checked={!!selectedUsers.find(user => user.id === contact.id)}
                />
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GroupComboBox;
