import Panel from "@src/components/box/Panel";
import { User } from "@src/utils/user";
import React from "react";
import { SocialIcon } from "react-social-icons";

type SocialsProps = { user: User };

const Socials: React.FC<SocialsProps> = ({ user }) => {
  /*   console.log("User:", user);
   */ const socials = user.socials || [];

  return (
    <div className="flex-start flex flex-wrap gap-2">
      {socials.map(socialItem => {
        if (socialItem.type.toLowerCase() === "xing") {
          return (
            <Panel key={socialItem.username}>
              <a href={socialItem.username}>
                <img src="../images/icons/xing.png" alt="Xing" style={{ height: 30, width: 30 }} />
              </a>
            </Panel>
          );
        } else {
          return (
            <Panel key={socialItem.username}>
              <SocialIcon url={socialItem.username} style={{ height: 30, width: 30 }} />
            </Panel>
          );
        }
      })}
    </div>
  );
};

export default Socials;
