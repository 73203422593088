import { BookmarkIcon } from "@heroicons/react/24/outline";
import { BookmarkIcon as BookmarkIconSolid } from "@heroicons/react/24/solid";
import bookmark from "@src/api/posts/bookmark";
import remove from "@src/api/posts/bookmark/remove";
import PostsActionBarItem from "@src/pages/news/posts/PostsActionBarItem";
import { useAppSelector } from "@src/state/hooks";
import useLoginModal from "@src/state/modal/useLoginModal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  postId: string;
  bookmarked: boolean;
  bookmarkCount: number;
  color?: string;
  textColor?: string;
};

const PostBookmarkButton = ({ postId, bookmarked, bookmarkCount, color, textColor }: Props) => {
  const [actionEffect, setActionEffect] = useState(false);
  const queryClient = useQueryClient();
  const loginModal = useLoginModal();
  const { user } = useAppSelector(state => state.user);
  const { t } = useTranslation();

  const bookmarkMutation = useMutation({
    mutationKey: ["bookmark"],
    mutationFn: bookmark,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const removeMutation = useMutation({
    mutationKey: ["bookmark"],
    mutationFn: remove,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
    onError: () => {
      alert("Failed");
    },
  });
  return (
    <PostsActionBarItem
      onClick={() => {
        if (user) {
          if (bookmarked) {
            removeMutation.mutate({ id: postId });
          } else {
            bookmarkMutation.mutate({ id: postId });
          }
        } else {
          loginModal.open();
        }
      }}
      label={t("pages.post.savePost")}
      color={color ?? (bookmarked ? "bg-slate-300" : "group-hover:bg-slate-300")}
      textColor={
        textColor ?? "text-gray-600 transition-all ease-out duration-200 group-hover:text-slate-700"
      }
      count={bookmarkCount}
    >
      {bookmarked ? (
        <BookmarkIconSolid className={`${actionEffect && "animate-ping-once"}`} />
      ) : (
        <BookmarkIcon
          onClick={() => {
            setActionEffect(true);
          }}
          onAnimationEnd={() => {
            setActionEffect(false);
          }}
        />
      )}
    </PostsActionBarItem>
  );
};

export default PostBookmarkButton;
